import { useQuery } from '@tanstack/react-query';
import { useAxios } from './use-axios';

export const USER_QUERY_KEY = ['ensure-user-exists'];

type EnsureUserResponse = {
  status: 'success' | 'error';
  data?: {
    user_id: string;
  };
  message?: string;
};

export function useEnsureUser() {
  const axios = useAxios();

  return useQuery({
    queryKey: USER_QUERY_KEY,
    queryFn: async () => {
      try {
        const { data } = await axios.post<EnsureUserResponse>('/user/ensure-user-exists');
        if (data.status === 'error' || !data.data) {
          throw new Error(data.message || 'Failed to ensure user exists');
        }
        return data.data;
      } catch (error) {
        if (error instanceof Error) {
          throw error;
        }
        throw new Error('Failed to verify user status');
      }
    },
    // Only run once when the app loads
    staleTime: Infinity,
    retry: 3,
  });
}
