import * as React from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { Button } from '@/components/ui/button';

type ThreadPaginationProps = {
  onNextPage?: () => void;
  onPrevPage?: () => void;
  hasNextPage?: boolean;
  hasPrevPage?: boolean;
  isFetchingNextPage?: boolean;
};

export function ThreadPagination({
  onNextPage,
  onPrevPage,
  hasNextPage,
  hasPrevPage,
  isFetchingNextPage,
}: ThreadPaginationProps) {
  return (
    <div className="flex items-center justify-between ">
      <Button
        aria-label="Go to previous page"
        variant="outline"
        size="sm"
        onClick={() => onPrevPage?.()}
        disabled={!hasPrevPage || isFetchingNextPage || !onPrevPage}
      >
        <ChevronLeft className="mr-2 size-4" />
        Previous
      </Button>
      <Button
        aria-label="Go to next page"
        variant="outline"
        size="sm"
        onClick={() => onNextPage?.()}
        disabled={!hasNextPage || isFetchingNextPage || !onNextPage}
      >
        Next
        <ChevronRight className="ml-2 size-4" />
      </Button>
    </div>
  );
}
