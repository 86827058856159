import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { format, set } from 'date-fns';
import { Calendar as CalendarIcon } from 'lucide-react';
import { z } from 'zod';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { Switch } from '@/components/ui/switch';
import { Textarea } from '@/components/ui/textarea';
import { useUpdateDomain } from '@/hooks/use-domains';
import { cn } from '@/lib/utils';
import { TimeSelect } from '@/pages/app/deals/components/time-select';
import type { Domain } from '@/types/domain';

const formSchema = z.object({
  last_verified: z.date().nullable(),
  last_verified_time: z.object({
    hours: z.string(),
    minutes: z.string(),
    period: z.enum(['AM', 'PM']),
  }).nullable(),
  noavsend: z.boolean(),
  use_restriction: z.string().nullable(),
  use_restriction_note: z.string().nullable(),
});

type DomainFormProps = {
  domain: Domain;
  onSuccess?: () => void;
};

export function DomainForm({ domain, onSuccess }: DomainFormProps) {
  const updateDomain = useUpdateDomain();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      last_verified: domain.last_verified ? new Date(domain.last_verified) : null,
      last_verified_time: domain.last_verified
        ? (() => {
            const date = new Date(domain.last_verified);
            const hours = date.getHours();
            const period = hours >= 12 ? 'PM' : 'AM';
            const hour12 = hours % 12 || 12;
            return {
              hours: hour12.toString().padStart(2, '0'),
              minutes: date.getMinutes().toString().padStart(2, '0'),
              period,
            };
          })()
        : null,
      noavsend: domain.noavsend,
      use_restriction: domain.use_restriction,
      use_restriction_note: domain.use_restriction_note,
    },
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      let lastVerified = values.last_verified;
      if (lastVerified && values.last_verified_time) {
        // Convert 12-hour time to 24-hour time
        let hours = Number.parseInt(values.last_verified_time.hours);
        if (values.last_verified_time.period === 'PM' && hours !== 12) {
          hours += 12;
        } else if (values.last_verified_time.period === 'AM' && hours === 12) {
          hours = 0;
        }

        lastVerified = set(lastVerified, {
          hours,
          minutes: Number.parseInt(values.last_verified_time.minutes),
          seconds: 0,
          milliseconds: 0,
        });
      }

      await updateDomain.mutateAsync({
        name: domain.name,
        last_verified: lastVerified?.toISOString() || null,
        noavsend: values.noavsend,
        use_restriction: values.use_restriction,
        use_restriction_note: values.use_restriction_note,
      });
      onSuccess?.();
    } catch {
      // Error handling is done in the mutation
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <FormField
          control={form.control}
          name="noavsend"
          render={({ field }) => (
            <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
              <div className="space-y-0.5">
                <FormLabel className="text-base">Skip Annual Verification</FormLabel>
              </div>
              <FormControl>
                <Switch
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="use_restriction"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Use Restriction</FormLabel>
              <FormControl>
                <Input
                  placeholder="Enter use restriction"
                  {...field}
                  value={field.value || ''}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="use_restriction_note"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Use Restriction Note</FormLabel>
              <FormControl>
                <Textarea
                  placeholder="Add notes about use restrictions..."
                  className="resize-none"
                  {...field}
                  value={field.value || ''}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="last_verified"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Last Verified</FormLabel>
              <FormControl>
                <Popover>
                  <PopoverTrigger asChild>
                    <FormControl>
                      <Button
                        variant="outline"
                        className={cn(
                          'w-full pl-3 text-left font-normal',
                          !field.value && 'text-muted-foreground',
                        )}
                      >
                        {field.value && form.getValues('last_verified_time')
                          ? (
                              format(
                                set(field.value, {
                                  hours: form.getValues('last_verified_time')!.period === 'PM' && form.getValues('last_verified_time')!.hours !== '12'
                                    ? Number(form.getValues('last_verified_time')!.hours) + 12
                                    : (form.getValues('last_verified_time')!.period === 'AM' && form.getValues('last_verified_time')!.hours === '12'
                                        ? 0
                                        : Number(form.getValues('last_verified_time')!.hours)),
                                  minutes: Number(form.getValues('last_verified_time')!.minutes),
                                }),
                                'MMM d, yyyy h:mm a',
                              )
                            )
                          : (
                              <span>Pick a date</span>
                            )}
                        <CalendarIcon className="ml-auto size-4 opacity-50" />
                      </Button>
                    </FormControl>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                      mode="single"
                      selected={field.value || undefined}
                      onSelect={(date) => {
                        field.onChange(date);
                        if (date && !form.getValues('last_verified_time')) {
                          // Set default time to current time
                          const now = new Date();
                          const hours = now.getHours();
                          const period = hours >= 12 ? 'PM' : 'AM';
                          const hour12 = hours % 12 || 12;
                          form.setValue('last_verified_time', {
                            hours: hour12.toString().padStart(2, '0'),
                            minutes: now.getMinutes().toString().padStart(2, '0'),
                            period,
                          });
                        }
                      }}
                      initialFocus
                    />
                    {field.value && (
                      <div className="border-t p-3">
                        <FormField
                          control={form.control}
                          name="last_verified_time"
                          render={({ field: timeField }) => (
                            <div>
                              <FormLabel className="text-xs text-muted-foreground">
                                Time
                              </FormLabel>
                              <TimeSelect
                                field={{
                                  value: timeField.value || {
                                    hours: '12',
                                    minutes: '00',
                                    period: 'PM',
                                  },
                                  onChange: timeField.onChange,
                                }}
                              />
                            </div>
                          )}
                        />
                      </div>
                    )}
                  </PopoverContent>
                </Popover>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button
          type="submit"
          className="w-full"
          disabled={updateDomain.isPending}
        >
          Save Changes
        </Button>
      </form>
    </Form>
  );
}
