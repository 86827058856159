import { useUser } from '@clerk/clerk-react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import type { Grant, URLForAuthenticationConfig } from 'nylas';
import useAxios from './use-axios';

// Response type definitions for our Nylas API endpoints
interface NylasGrantsResponse {
  grants: Grant[];
  requestId: string;
}

interface CreateGrantResponse {
  authUrl: string;
  message: string;
}

interface DeleteGrantResponse {
  success: boolean;
  requestId: string;
}

interface GetGrantByEmailResponse {
  grantId: string;
}

// Query keys for React Query cache management
// We structure these hierarchically starting with 'nylasGrants' for easy cache invalidation
export const NYLAS_QUERY_KEYS = {
  grants: {
    all: ['nylasGrants'] as const,
    list: ['nylasGrants', 'list'] as const,
    byEmail: (email?: string) => ['nylasGrants', 'byEmail', email] as const,
  },
} as const;

export function useNylasGrants(email?: string) {
  const axios = useAxios();
  const queryClient = useQueryClient();

  // get the user's primary email address
  const { user } = useUser();
  // Use the provided email address or the logged-in user's primary email address
  const grantEmail = email || user?.primaryEmailAddress?.emailAddress;

  // Query to fetch all grants
  const query = useQuery({
    queryKey: NYLAS_QUERY_KEYS.grants.list,
    queryFn: async () => {
      const { data } = await axios.get<NylasGrantsResponse>('/nylas/grants');
      return data.grants;
    },
  });

  // Mutation to create a new grant and handle OAuth flow
  const createGrantMutation = useMutation({
    mutationFn: async (config: Partial<URLForAuthenticationConfig>) => {
      const { data } = await axios.post<CreateGrantResponse>('/nylas/grants', config);
      return data;
    },
    onSuccess: (data) => {
      // Redirect user to Nylas auth URL to complete OAuth flow
      console.log('Redirecting to:', data.authUrl);
      globalThis.location.href = data.authUrl;
    },
  });

  // Mutation to delete a grant and invalidate the grants list cache
  const deleteGrantMutation = useMutation({
    mutationFn: async (grantId: string) => {
      const { data } = await axios.delete<DeleteGrantResponse>(`/nylas/grants/${grantId}`);
      return data;
    },
    onSuccess: async () => {
      // Invalidate and refetch the grants list
      await queryClient.invalidateQueries({
        queryKey: NYLAS_QUERY_KEYS.grants.list,
      });
    },
  });

  // Query to fetch a grant by email address
  const grantByEmailQuery = useQuery({
    queryKey: NYLAS_QUERY_KEYS.grants.byEmail(grantEmail),
    queryFn: async () => {
      const { data } = await axios.get<GetGrantByEmailResponse>('/nylas/grants', {
        params: { email: grantEmail },
      });
      return data.grantId;
    },
    // Only enable the query if a grant email is provided and it's not the 'deal' inbox
    enabled: !!grantEmail && grantEmail !== 'deal',
  });

  // Return a consolidated interface combining all queries and mutations
  return {
    // Spread the main grants query
    ...query,

    // Create grant mutation handlers
    createGrant: createGrantMutation.mutate,
    isCreatingGrant: createGrantMutation.isPending,
    createGrantError: createGrantMutation.error,

    // Delete grant mutation handlers
    deleteGrant: deleteGrantMutation.mutate,
    isDeletingGrant: deleteGrantMutation.isPending,
    deleteGrantError: deleteGrantMutation.error,

    // Grant by email query handlers
    grantId: grantByEmailQuery.data,
    isLoadingGrantId: grantByEmailQuery.isLoading,
    grantIdError: grantByEmailQuery.error,

    // Current user's grant email
    grantEmail,
  };
}
