import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import useAxios from '@/hooks/use-axios';
import type { MessageDB, SendMessageRequest } from '@/types/message';

/** React Query cache keys for messages */
const MESSAGES_QUERY_KEYS = {
  /** Base key for all message queries */
  all: ['messages'] as const,
  /** Key builder for deal-specific message queries */
  byDeal: (dealId: string) => [...MESSAGES_QUERY_KEYS.all, 'deal', dealId] as const,
  /** Key builder for getting messages by IDs */
  byIds: (messageIds: string[]) => [...MESSAGES_QUERY_KEYS.all, 'byIds', messageIds] as const,
} as const;

/**
 * Hook for getting messages by their IDs
 *
 * @param messageIds - Array of message IDs to fetch
 * @returns Object containing:
 *  - messages: Array of messages if found
 *  - isLoading: Boolean indicating if messages are being fetched
 *  - error: Any error that occurred during fetching
 */
export function useMessagesByIds(messageIds?: string[]) {
  const axios = useAxios();

  const {
    data: messages,
    isLoading,
    error,
  } = useQuery({
    queryKey: MESSAGES_QUERY_KEYS.byIds(messageIds ?? []),
    queryFn: async () => {
      if (!messageIds?.length)
        return [];
      const { data } = await axios.post<{ messages: MessageDB[] }>('/messages/get', {
        message_ids: messageIds,
      });
      return data.messages;
    },
    enabled: !!messageIds?.length,
  });

  return {
    messages,
    isLoading,
    error,
  };
}

/**
 * Hook for managing messages associated with a deal
 *
 * Provides functionality to:
 * - Fetch all messages for a specific deal
 * - Send new messages (both email and internal)
 * - Track loading and error states
 * - Auto-refresh messages after sending
 *
 * @param dealId - The ID of the deal to fetch/send messages for
 * @returns Object containing:
 *  - messages: Array of messages associated with the deal
 *  - isLoading: Boolean indicating if messages are being fetched
 *  - error: Any error that occurred during fetching
 *  - sendMessage: Function to send a new message
 *  - isSending: Boolean indicating if a message is being sent
 */
export function useMessages(dealId: string) {
  const axios = useAxios();
  const queryClient = useQueryClient();

  // Fetch messages for a deal
  const {
    data: messages,
    isLoading,
    error,
  } = useQuery({
    queryKey: MESSAGES_QUERY_KEYS.byDeal(dealId),
    queryFn: async () => {
      const { data } = await axios.get<MessageDB[]>(`/messages/${dealId}`);
      return data;
    },
    refetchInterval: 10_000, // Refetch every 10 seconds
  });

  // Send a message (email or internal)
  const { mutateAsync: sendMessage, isPending: isSending } = useMutation({
    mutationFn: async (message: SendMessageRequest) => {
      const { data } = await axios.post('/messages/send', message);
      return data;
    },
    onSuccess: async () => {
      // Invalidate the messages query to refetch after sending
      await queryClient.invalidateQueries({
        queryKey: MESSAGES_QUERY_KEYS.byDeal(dealId),
      });
    },
  });

  return {
    messages,
    isLoading,
    error,
    sendMessage,
    isSending,
  };
}
