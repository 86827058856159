import { useRef, useState } from 'react';
import type { GridApi, RowDoubleClickedEvent } from '@ag-grid-community/core';
import { RotateCcw } from 'lucide-react';
import { toast } from 'sonner';
import AGDataGrid from '@/components/ag-data-grid';
import Container from '@/components/container';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { useGrid } from '@/contexts/grid-context';
import {
  usePartners,
  useCreatePartner,
  useUpdatePartner,
  useDeletePartners,
} from '@/hooks/use-partners';
import type { Partner, CreatePartnerInput, UpdatePartnerInput } from '@/types/partner';
import { partnerColumnDefs } from './components/partner-grid-columns';
import PartnerSheet from './components/partner-sheet';

function PartnersPage() {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRows, setSelectedRows] = useState<Partner[]>([]);
  const [editingPartner, setEditingPartner] = useState<Partner | null>(null);
  const [isSheetOpen, setIsSheetOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const gridApiRef = useRef<GridApi<Partner> | null>(null);
  const { registerGridApi } = useGrid();

  const { data: partners, isLoading } = usePartners();
  const createMutation = useCreatePartner();
  const updateMutation = useUpdatePartner();
  const deleteMutation = useDeletePartners();

  const handleCreateSubmit = async (data: CreatePartnerInput) => {
    try {
      await createMutation.mutateAsync(data);
      toast.success('Partner created successfully');
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      }
      throw error;
    }
  };

  const handleUpdateSubmit = async (data: UpdatePartnerInput) => {
    if (!editingPartner)
      return;

    try {
      await updateMutation.mutateAsync({ id: editingPartner.id, ...data });
      toast.success('Partner updated successfully');
      setEditingPartner(null);
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      }
      throw error;
    }
  };

  const handleDelete = async () => {
    if (selectedRows.length === 0)
      return;

    try {
      await deleteMutation.mutateAsync(selectedRows.map((row) => row.id));
      toast.success('Selected partners deleted successfully');
      setSelectedRows([]);
      setIsDeleteDialogOpen(false);
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      }
    }
  };

  const handleRowDoubleClick = (event: RowDoubleClickedEvent<Partner>) => {
    if (event.data) {
      setEditingPartner(event.data);
      setIsSheetOpen(true);
    }
  };

  const handleSelectionChanged = (event: { api: { getSelectedRows: () => Partner[] } }) => {
    setSelectedRows(event.api.getSelectedRows());
  };

  const handleSheetOpenChange = (open: boolean) => {
    setIsSheetOpen(open);
    if (!open) {
      setEditingPartner(null);
      // Deselect rows when closing the sheet
      if (gridApiRef.current) {
        gridApiRef.current.deselectAll();
      }
    }
  };

  const handleGridReady = (params: { api: GridApi<Partner> }) => {
    gridApiRef.current = params.api;
    registerGridApi(params.api);
  };

  const clearFilters = () => {
    if (gridApiRef.current) {
      gridApiRef.current.setFilterModel(null);
      setSearchQuery('');
    }
  };

  const handleDeleteClick = (e: React.MouseEvent) => {
    // Stop the click from bubbling up to the app layout
    e.stopPropagation();
    setIsDeleteDialogOpen(true);
  };

  return (
    <Container maxWidth={false} className="pb-12">
      <div className="mb-3 flex justify-between">
        <div>
          <h1 className="mt-2 text-xl font-bold">Partners</h1>
        </div>
        <div className="flex items-center gap-4">
          {selectedRows.length > 0 && (
            <Button
              variant="destructive"
              onClick={handleDeleteClick}
              disabled={deleteMutation.isPending}
            >
              Delete Selected
            </Button>
          )}
          <Button onClick={() => setIsSheetOpen(true)}>
            Create Partner
          </Button>
        </div>
      </div>
      <div className="mb-6 flex gap-4">
        <div className="flex-1">
          <Input
            type="search"
            placeholder="Search partners..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <Button
          variant="outline"
          onClick={clearFilters}
          className="gap-2"
        >
          <RotateCcw className="size-4" />
          Clear Filters
        </Button>
      </div>
      <div className={isLoading ? 'opacity-50' : ''}>
        <AGDataGrid
          columnDefs={partnerColumnDefs}
          rowData={partners || []}
          quickFilterText={searchQuery}
          rowSelection={{ mode: 'multiRow' }}
          onRowDoubleClicked={handleRowDoubleClick}
          onSelectionChanged={handleSelectionChanged}
          onGridReady={handleGridReady}
          overlayLoadingTemplate={
            isLoading ? '<span class="ag-overlay-loading-center">Loading...</span>' : undefined
          }
          overlayNoRowsTemplate={
            isLoading ? '' : '<span class="ag-overlay-no-rows-center">No partners found</span>'
          }
        />
      </div>

      <PartnerSheet
        open={isSheetOpen}
        onOpenChange={handleSheetOpenChange}
        initialData={editingPartner || undefined}
        onSubmit={editingPartner ? handleUpdateSubmit : handleCreateSubmit}
      />

      <AlertDialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This will permanently delete
              {' '}
              {selectedRows.length}
              {' '}
              selected
              {' '}
              {selectedRows.length === 1 ? 'partner' : 'partners'}
              .
              This action cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={handleDelete}
              className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
            >
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Container>
  );
}

export default PartnersPage;
