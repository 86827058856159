import juice from 'juice';
import isEmail from 'validator/lib/isEmail';
import styles from '@/components/tiptap/styles/index.css?raw';

// Import all partial CSS files
import codeStyles from '@/components/tiptap/styles/partials/code.css?raw';
import listsStyles from '@/components/tiptap/styles/partials/lists.css?raw';
import placeholderStyles from '@/components/tiptap/styles/partials/placeholder.css?raw';
import tasksStyles from '@/components/tiptap/styles/partials/tasks.css?raw';
import typographyStyles from '@/components/tiptap/styles/partials/typography.css?raw';
import type { EmailName } from 'nylas';

type CssVariables = Record<string, string>;
// Combine all CSS
const allStyles: string = [
  styles,
  codeStyles,
  listsStyles,
  placeholderStyles,
  tasksStyles,
  typographyStyles,
].join('\n');

/**
 * Extracts CSS variables from root styles
 */
function extractCssVars(css: string): CssVariables {
  const cssVars: CssVariables = {};
  const rootContent = css.match(/:root[ \t\n]*\{([^}]*)\}/)?.[1] || '';
  // eslint-disable-next-line regexp/no-super-linear-backtracking
  const matches = [...rootContent.matchAll(/--([^:]+):\s*([^;]+);/g)];

  for (const match of matches) {
    const [, name, value] = match;
    cssVars[`var(--${name.trim()})`] = value.trim();
  }

  return cssVars;
}

/**
 * Converts an rgb() color to hex format
 * @param rgb - RGB color string (e.g. 'rgb(201,55,44)' or 'rgba(201,55,44,1)')
 * @returns Hex color string (e.g. '#C9372C')
 */
function rgbToHex(rgb: string): string {
  const values = rgb.match(/\d+/g);
  if (!values || values.length < 3)
    return rgb;

  const r = Number.parseInt(values[0], 10);
  const g = Number.parseInt(values[1], 10);
  const b = Number.parseInt(values[2], 10);

  return `#${[r, g, b].map((x: number): string => {
    const hex = x.toString(16);
    return hex.length === 1 ? `0${hex}` : hex;
  }).join('').toUpperCase()}`;
}

/**
 * Converts style attributes with rgb() colors to hex colors
 * @param styleAttr - Style attribute content
 * @returns Style attribute with rgb() converted to hex
 */
function convertStyleColors(styleAttr: string): string {
  return styleAttr.replaceAll(
    /rgb\(\s*\d+\s*,\s*\d+\s*,\s*\d+\s*\)/g,
    (match: string): string => rgbToHex(match),
  );
}

/**
 * Converts HTML with CSS variables and external styles to email-client compatible HTML
 * @param html - The HTML content to convert
 * @returns Email-client compatible HTML with inline styles
 */
export function convertToEmailHtml(html: string): string {
  // Extract CSS variables
  const cssVars = extractCssVars(allStyles);

  // First inline any regular CSS using juice
  let inlined = juice(html, {
    removeStyleTags: true,
    preserveMediaQueries: false,
    preserveFontFaces: false,
    preserveKeyFrames: false,
    preservePseudos: false,
    applyWidthAttributes: true,
    applyHeightAttributes: true,
    applyAttributesTableElements: true,
  });

  // Replace any remaining CSS variable references with their actual values
  for (const [variable, value] of Object.entries(cssVars)) {
    const safeVariable = variable.replaceAll(/[.*+?^${}()|[\]\\]/g, String.raw`\$&`);
    // Convert rgb() colors to hex for better email client compatibility
    const hexValue = value.startsWith('rgb') ? rgbToHex(value) : value;
    inlined = inlined.replaceAll(new RegExp(safeVariable, 'g'), hexValue);
  }

  // Convert any rgb colors in style attributes to hex
  inlined = inlined.replaceAll(
    /style="([^"]*)"/g,
    (_match: string, styles: string): string => `style="${convertStyleColors(styles)}"`,
  );

  // Clean up the HTML for email clients
  inlined = inlined
    // Remove all classes as they're not needed in email
    .replaceAll(/\sclass="[^"]*"/g, '')
    // Remove any empty style attributes
    .replaceAll(/\sstyle=""/g, '')
    // Remove any data attributes
    .replaceAll(/\sdata-[^=]*="[^"]*"/g, '');

  return inlined.trim();
}

/**
 * Converts an array of strings to EmailName objects
 * @param emails - Array of email addresses
 * @returns Array of EmailName objects
 */
export function stringArrayToEmailName(emails: string[]): EmailName[] {
  const emailNames = emails.map((email: string) => {
    // Check if the string is a valid email
    return isEmail(email) ? { email } : undefined;
  })
    // Filter out any undefined values
    .filter((emailName): emailName is EmailName => !!emailName);

  return emailNames;
}
