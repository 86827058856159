// src/pages/app/deals/components/deal-grid-columns.tsx
import { useNavigate } from 'react-router-dom';
import type { ICellRendererParams, ColDef } from '@ag-grid-community/core';
import type { Deal } from '@/hooks/use-deals';
import { formatDateEDT } from '@/lib/date';

function DomainsCell({ value, data }: ICellRendererParams<Deal, string[]>) {
  const navigate = useNavigate();
  if (!Array.isArray(value) || value.length === 0 || !data)
    return null;

  return (
    <div
      className="cursor-pointer hover:underline"
      onClick={() => navigate(`/app/deals/${data.id}`)}
    >
      {value.join(', ')}
    </div>
  );
}

function DateCell({ value }: ICellRendererParams<Deal, string>) {
  if (!value)
    return null;
  return formatDateEDT(new Date(value));
}

function StageCell({ value, data }: ICellRendererParams<Deal>) {
  const navigate = useNavigate();
  if (!data)
    return null;

  return (
    <div
      className="cursor-pointer hover:underline"
      onClick={() => navigate(`/app/deals/${data.id}`)}
    >
      {value}
    </div>
  );
}

export const dealColumnDefs: ColDef<Deal>[] = [
  {
    field: 'deal_type',
    headerName: 'Type',
    flex: 1,
    minWidth: 150,
    sortable: true,
    filter: true,
    cellRenderer: StageCell,
  },
  {
    field: 'deal_stage',
    headerName: 'Stage',
    flex: 1,
    minWidth: 200,
    sortable: true,
    filter: true,
    cellRenderer: StageCell,
  },
  {
    field: 'domains',
    headerName: 'Domains',
    flex: 1,
    minWidth: 200,
    sortable: true,
    filter: true,
    cellRenderer: DomainsCell,
  },
  {
    field: 'outcome',
    headerName: 'Outcome',
    flex: 1,
    minWidth: 150,
    sortable: true,
    filter: true,
    cellRenderer: StageCell,
  },
  {
    field: 'created_at',
    headerName: 'Created',
    flex: 1,
    minWidth: 150,
    sortable: true,
    filter: true,
    cellRenderer: DateCell,
  },
  {
    field: 'updated_at',
    headerName: 'Updated',
    flex: 1,
    minWidth: 150,
    sortable: true,
    filter: true,
    cellRenderer: DateCell,
  },
];
