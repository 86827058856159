import moment from 'moment-timezone';
import type { BatchCriteria, BatchFilters } from '@/hooks/use-annual-verification-batches';

export interface SchedulerEventMetadata {
  // Batch information
  batchCriteria: BatchCriteria;
  criteriaValue: string;
  batchSize: number;
  oldestVerification: string;

  // Filter criteria used
  filters: BatchFilters;
}

export interface SchedulerTimeValue {
  date: Date;
  hours: string;
  minutes: string;
  period: 'AM' | 'PM';
}

export function convertToEST(value: SchedulerTimeValue): Date {
  const { date, hours, minutes, period } = value;

  // Convert hours to 24-hour format
  let hour = Number.parseInt(hours, 10);
  if (period === 'PM' && hour !== 12)
    hour += 12;
  if (period === 'AM' && hour === 12)
    hour = 0;

  // Create a moment in EST with the specified date and time
  const m = moment.tz([
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    hour,
    Number.parseInt(minutes, 10),
    0,
  ], 'America/New_York');

  // Convert to UTC and return as Date
  return m.toDate();
}

export function formatInEST(value: SchedulerTimeValue): string {
  const date = convertToEST(value);
  return moment(date).tz('America/New_York').format('MMMM D, YYYY h:mm A z');
}

export function getCurrentEST(): SchedulerTimeValue {
  const now = moment().tz('America/New_York');
  const hour = now.hour();
  const period = hour >= 12 ? 'PM' : 'AM';
  const hour12 = hour % 12 || 12; // Convert 24h to 12h format

  return {
    date: now.toDate(),
    hours: String(hour12).padStart(2, '0'),
    minutes: String(now.minute()).padStart(2, '0'),
    period,
  };
}
