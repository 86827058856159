import { format } from 'date-fns';
import { AlertCircle, X, RotateCcw, PenTool } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import type { TradeSearchResult } from '@/hooks/use-trade';
import type { OFACCheck, VerificationItem } from '../types';

interface OFACCheckProps {
  item: OFACCheck;
  onVerify: (id: string) => void;
  onSign: (id: string, role: 'creator' | 'reviewer') => void;
  isSearching: boolean;
  items: VerificationItem[];
  setItems: (items: VerificationItem[]) => void;
  userId: string | undefined;
}

export function OFACCheckComponent({
  item,
  onVerify,
  isSearching,
  items,
  setItems,
  onSign,
  userId,
}: OFACCheckProps) {
  return (
    <div className="space-y-4">
      <div className="flex items-center gap-4">
        <div className="flex-1">
          <Input
            value={item.entityName}
            onChange={(e) =>
              setItems(
                items.map((i) =>
                  i.id === item.id
                    ? { ...i, entityName: e.target.value }
                    : i,
                ),
              )}
            placeholder="Enter entity name"
          />
        </div>
        <Button
          variant={item.verified ? 'outline' : 'default'}
          className="gap-2"
          onClick={() => onVerify(item.id)}
          disabled={!item.entityName || isSearching}
        >
          {item.verified
            ? (
                <>
                  <RotateCcw className="size-4" />
                  Re-verify
                </>
              )
            : (
                <>
                  <AlertCircle className="size-4" />
                  Verify
                </>
              )}
        </Button>
      </div>
      {item.searchResults && (
        <div className="space-y-4">
          {typeof item.searchResults.results === 'string' ? (
            <div className="rounded-lg border bg-muted p-4 text-sm">
              {item.searchResults.results}
            </div>
          ) : (
            <>
              <div className="flex items-center gap-2 text-sm text-muted-foreground">
                <div>
                  Found
                  {' '}
                  {item.searchResults.results.total}
                  {' '}
                  results
                </div>
              </div>
              {item.searchResults.results.results.map((result: TradeSearchResult, index: number) => (
                <div key={index} className="space-y-3 rounded-lg border p-4">
                  {/* Entity Info */}
                  <div className="space-y-1">
                    <div className="font-medium">{result.name}</div>
                    {result.alt_names && result.alt_names.length > 0 && (
                      <div className="text-sm text-muted-foreground">
                        Also known as:
                        {' '}
                        {result.alt_names.join(', ')}
                      </div>
                    )}
                    {result.type && (
                      <div className="text-sm">
                        Type:
                        {' '}
                        {result.type}
                      </div>
                    )}
                  </div>

                  {/* Programs & Details */}
                  {result.programs && result.programs.length > 0 && (
                    <div className="space-y-1">
                      <div className="text-sm font-medium">Programs:</div>
                      <div className="flex flex-wrap gap-2">
                        {result.programs.map((program: string, idx: number) => (
                          <div
                            key={idx}
                            className="rounded-md bg-muted px-2 py-1 text-xs"
                          >
                            {program}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {/* Addresses */}
                  {result.addresses && result.addresses.length > 0 && (
                    <div className="space-y-1">
                      <div className="text-sm font-medium">Addresses:</div>
                      <div className="space-y-2">
                        {result.addresses.map((address: { address: string; city: string; state: string; postal_code: string; country: string }, idx: number) => (
                          <div key={idx} className="text-sm">
                            {address.address}
                            {[
                              address.city,
                              address.state,
                              address.postal_code,
                              address.country,
                            ]
                              .filter(Boolean)
                              .join(', ')}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {/* Additional Details */}
                  <div className="grid gap-2 text-sm">
                    {result.nationalities && result.nationalities.length > 0 && (
                      <div>
                        <span className="font-medium">Nationalities: </span>
                        {result.nationalities.join(', ')}
                      </div>
                    )}
                    {result.citizenships && result.citizenships.length > 0 && (
                      <div>
                        <span className="font-medium">Citizenships: </span>
                        {result.citizenships.join(', ')}
                      </div>
                    )}
                    {result.dates_of_birth && result.dates_of_birth.length > 0 && (
                      <div>
                        <span className="font-medium">Dates of Birth: </span>
                        {result.dates_of_birth.join(', ')}
                      </div>
                    )}
                    {result.places_of_birth && result.places_of_birth.length > 0 && (
                      <div>
                        <span className="font-medium">Places of Birth: </span>
                        {result.places_of_birth.join(', ')}
                      </div>
                    )}
                  </div>

                  {/* Remarks */}
                  {result.remarks && (
                    <div className="space-y-1">
                      <div className="text-sm font-medium">Remarks:</div>
                      <div className="text-sm text-muted-foreground">
                        {result.remarks}
                      </div>
                    </div>
                  )}

                  {/* Source Information */}
                  <div className="space-y-1 border-t pt-2">
                    <div className="flex flex-wrap gap-x-4 gap-y-1 text-sm text-muted-foreground">
                      {result.source && (
                        <div>
                          Source:
                          {' '}
                          {result.source}
                        </div>
                      )}
                      {result.start_date && (
                        <div>
                          Start Date:
                          {' '}
                          {result.start_date}
                        </div>
                      )}
                      {result.end_date && (
                        <div>
                          End Date:
                          {' '}
                          {result.end_date}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      )}
      {item.verified && (
        <div className="flex items-center gap-4">
          <Button
            variant="outline"
            className="gap-2"
            onClick={() => onSign(item.id, 'creator')}
          >
            {item.signatures.some(
              (s) => s.userId === userId && s.role === 'creator',
            )
              ? (
                  <>
                    <X className="size-4" />
                    Remove Creator Signature
                  </>
                )
              : (
                  <>
                    <PenTool className="size-4" />
                    Sign as Creator
                  </>
                )}
          </Button>
          <Button
            variant="outline"
            className="gap-2"
            onClick={() => onSign(item.id, 'reviewer')}
          >
            {item.signatures.some(
              (s) => s.userId === userId && s.role === 'reviewer',
            )
              ? (
                  <>
                    <X className="size-4" />
                    Remove Reviewer Signature
                  </>
                )
              : (
                  <>
                    <PenTool className="size-4" />
                    Sign as Reviewer
                  </>
                )}
          </Button>
        </div>
      )}
      <div>
        {item.verifiedBy && (
          <div className="text-sm text-muted-foreground">
            Verified by
            {' '}
            {item.verifiedBy.name}
            {' '}
            on
            {' '}
            {format(new Date(item.verifiedBy.timestamp), 'MMM d, yyyy h:mm a')}
          </div>
        )}
        {item.signatures.length > 0 && (
          <div className="space-y-2">
            <div className="text-sm font-medium">Signatures:</div>
            {item.signatures.map((signature, index) => (
              <div
                key={index}
                className="text-sm text-muted-foreground"
              >
                {signature.name}
                {' '}
                (
                {signature.role}
                ) -
                {' '}
                {format(new Date(signature.timestamp), 'MMM d, yyyy h:mm a')}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
