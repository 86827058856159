import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from 'react';
import type { Control } from 'react-hook-form';
import { useController } from 'react-hook-form';
import isEmail from 'validator/lib/isEmail';
import { cn } from '@/lib/utils';
import type { chipVariants } from './email-chip';
import type { VariantProps } from 'class-variance-authority';
import EmailChip from './email-chip';

type EmailChipsInputProps = React.ComponentPropsWithoutRef<'div'> &
  VariantProps<typeof chipVariants> & {
    /** Form control from react-hook-form (optional) */
    control?: Control<any>;
    /** Field name for react-hook-form (optional) */
    name?: string;
    /** Initial emails to populate the input */
    defaultEmails?: string[];
    /** Callback when the list of emails changes */
    onEmailsChange?: (emails: string[]) => void;
    /** Callback when a new email is added */
    onEmailAdd?: (email: string) => void;
    /** Callback when an email is removed */
    onEmailRemove?: (email: string, index: number) => void;
    /** Callback when an email chip is selected/deselected */
    onEmailSelect?: (email: string | null, index: number | null) => void;
    /** Whether the input is disabled */
    disabled?: boolean;
    /** Character(s) or regex that trigger email creation */
    delimiter?: string | RegExp;
    /** Placeholder text when no emails are entered */
    placeholder?: string;
  };

/** Methods exposed via the forwarded ref */
export type EmailChipsInputHandle = {
  /** Focus the input field */
  focus: () => void;
  /** Clear all emails and input */
  clear: () => void;
  /** Add a new email */
  addEmail: (email: string) => boolean;
  /** Remove an email by index */
  removeEmail: (index: number) => void;
  /** Get current list of emails */
  getEmails: () => string[];
};

const DEFAULT_EMAILS: string[] = [];
const DEFAULT_DELIMITER = /[,;\s]/;

const EmailRecipientInput = forwardRef<EmailChipsInputHandle, EmailChipsInputProps>(({
  defaultEmails = DEFAULT_EMAILS,
  onEmailsChange,
  onEmailAdd,
  onEmailRemove,
  onEmailSelect,
  disabled = false,
  delimiter = DEFAULT_DELIMITER,
  placeholder = 'Enter recipients',
  control,
  name,
  className,
  ...props
}, ref) => {
  const [emails, setEmails] = useState<string[]>(defaultEmails);
  const [inputValue, setInputValue] = useState('');
  const [selectedChipIndex, setSelectedChipIndex] = useState<number | null>(null);

  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  // Optional react-hook-form integration
  const fieldConfig = name && control
    // eslint-disable-next-line react-hooks/rules-of-hooks
    ? useController({
      name,
      control,
    })
    : null;

  const field = fieldConfig?.field;
  const error = fieldConfig?.fieldState?.error;

  /**
   * Removes an email chip at the specified index and updates state
   */
  const removeEmail = useCallback((indexToRemove: number) => {
    const emailToRemove = emails[indexToRemove];
    const newEmails = emails.filter((_, idx) => idx !== indexToRemove);

    setEmails(newEmails);
    onEmailsChange?.(newEmails);
    onEmailRemove?.(emailToRemove, indexToRemove);

    // If using RHF, call field.onChange
    if (field)
      field.onChange(newEmails);

    setSelectedChipIndex(null);
    onEmailSelect?.(null, null);
    inputRef.current?.focus();
  }, [emails, onEmailsChange, onEmailRemove, onEmailSelect, field]);

  /**
   * Attempts to add a new email chip. Returns true if successful.
   */
  const addEmail = useCallback((text: string): boolean => {
    const email = text.trim();
    if (!email || !isEmail(email))
      return false;

    setEmails((prev) => {
      const newEmails = [...prev, email];
      onEmailsChange?.(newEmails);
      onEmailAdd?.(email);

      // If using RHF, call field.onChange
      if (field)
        field.onChange(newEmails);

      return newEmails;
    });
    setInputValue('');
    return true;
  }, [onEmailsChange, onEmailAdd, field]);

  // Expose methods via ref
  useImperativeHandle(ref, () => ({
    focus: () => {
      inputRef.current?.focus();
    },
    clear: () => {
      setEmails([]);
      setInputValue('');
      setSelectedChipIndex(null);
      onEmailsChange?.([]);
      // If using RHF, reset the field
      if (field)
        field.onChange([]);
    },
    addEmail,
    removeEmail,
    getEmails: () => emails,
  }), [emails, addEmail, removeEmail, onEmailsChange, field]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled)
      return;

    const { value } = e.target;
    setInputValue(value);
    setSelectedChipIndex(null);

    const lastChar = value.at(-1);
    if (lastChar && (delimiter instanceof RegExp ? delimiter.test(lastChar) : lastChar === delimiter)) {
      const emailToTry = value.slice(0, -1).trim();
      if (emailToTry) {
        if (addEmail(emailToTry)) {
          // Successfully added the email, clear the input
          setInputValue('');
        } else {
          // Invalid email: keep the delimiter and let the user continue typing
          // No changes, user can still edit
        }
      } else {
        // If just a delimiter was entered with no preceding content, just ignore
        // and leave it in the input so user can continue typing.
      }
    }
  };

  /**
   * Handles blur events by adding the email if valid
   */
  const handleBlur = useCallback(() => {
    if (disabled)
      return;
    const trimmed = inputValue.trim();
    if (trimmed && isEmail(trimmed)) {
      addEmail(trimmed);
    }
    // If using RHF, call field.onBlur
    if (field)
      field.onBlur();
  }, [disabled, inputValue, addEmail, field]);

  /**
   * Handles paste events by splitting text on common delimiters
   */
  const handlePaste = useCallback((e: React.ClipboardEvent) => {
    if (disabled)
      return;

    e.preventDefault();
    const pastedText = e.clipboardData.getData('text');
    const potentialEmails = pastedText.split(/[,;\s]+/).filter(Boolean);

    const invalidEmails: string[] = [];
    for (const email of potentialEmails) {
      if (!addEmail(email)) {
        invalidEmails.push(email);
      }
    }

    if (invalidEmails.length > 0) {
      setInputValue((prev) => {
        const combined = [prev, ...invalidEmails].filter(Boolean).join(' ');
        return combined;
      });
    }
  }, [disabled, addEmail]);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (disabled)
      return;

    // If the user presses Enter, attempt to add the email
    if (e.key === 'Enter') {
      e.preventDefault();
      const trimmed = inputValue.trim();
      if (trimmed) {
        const success = addEmail(trimmed);
        if (!success) {
          // If invalid, leave the trimmed value in input to allow editing
          setInputValue(trimmed);
        }
      }
      return;
    }

    // Handle backspace for chip removal or selection
    if (e.key === 'Backspace' && emails.length > 0) {
      const isCursorAtStart = inputRef.current?.selectionStart === 0;
      const hasSelection = inputRef.current?.selectionStart !== inputRef.current?.selectionEnd;

      // Only handle chip selection/removal if there's no text selection
      if (!hasSelection && ((inputValue && isCursorAtStart) || !inputValue)) {
        e.preventDefault();
        if (selectedChipIndex === null) {
          const lastIndex = emails.length - 1;
          setSelectedChipIndex(lastIndex);
          onEmailSelect?.(emails[lastIndex], lastIndex);
        } else {
          removeEmail(selectedChipIndex);
          setSelectedChipIndex(null);
        }
      }
      return;
    }

    // Delete key should remove the currently selected chip, if any.
    if (e.key === 'Delete' && selectedChipIndex !== null) {
      e.preventDefault();
      removeEmail(selectedChipIndex);
      setSelectedChipIndex(null);
      return;
    }

    // Arrow keys to navigate chips
    if (e.key === 'ArrowLeft') {
      if (selectedChipIndex === null && inputRef.current?.selectionStart === 0 && emails.length > 0) {
        e.preventDefault();
        const newIndex = emails.length - 1;
        setSelectedChipIndex(newIndex);
        onEmailSelect?.(emails[newIndex], newIndex);
      } else if (selectedChipIndex !== null && selectedChipIndex > 0) {
        e.preventDefault();
        const newIndex = selectedChipIndex - 1;
        setSelectedChipIndex(newIndex);
        onEmailSelect?.(emails[newIndex], newIndex);
      }
      return;
    }

    if (e.key === 'ArrowRight' && selectedChipIndex !== null) {
      e.preventDefault();
      if (selectedChipIndex < emails.length - 1) {
        const newIndex = selectedChipIndex + 1;
        setSelectedChipIndex(newIndex);
        onEmailSelect?.(emails[newIndex], newIndex);
      } else {
        setSelectedChipIndex(null);
        onEmailSelect?.(null, null);
        inputRef.current?.focus();
      }
    }
  };

  /**
   * Handles clicks on the container, focusing the input
   */
  const handleContainerClick = () => {
    if (!disabled) {
      inputRef.current?.focus();
      if (selectedChipIndex !== null) {
        setSelectedChipIndex(null);
        onEmailSelect?.(null, null);
      }
    }
  };

  /**
   * Handles clicks on email chips for selection
   */
  const handleChipClick = (index: number, e: React.MouseEvent) => {
    if (disabled)
      return;
    e.stopPropagation();
    const newIndex = selectedChipIndex === index ? null : index;
    setSelectedChipIndex(newIndex);
    onEmailSelect?.(newIndex === null ? null : emails[newIndex], newIndex);

    // Ensure the input is focused so that key events (like Delete) will work
    inputRef.current?.focus();
  };

  return (
    <div
      ref={containerRef}
      className={cn(
        'w-full max-w-2xl mx-auto',
        disabled && 'opacity-50 cursor-not-allowed',
        className,
      )}
      {...props}
    >
      <div
        className={cn(
          'group/container flex flex-wrap items-center gap-1 rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background',
          'outline-none ring-0 transition-none',
          'has-[:focus]:ring-1 has-[:focus]:ring-ring has-[:focus]:ring-offset-0',
          'hover:border-input',
          !disabled && 'cursor-text',
          selectedChipIndex !== null && 'ring-1 ring-ring ring-offset-0',
          error && 'border-red-500',
        )}
        onClick={handleContainerClick}
      >
        {emails.map((email, index) => (
          <EmailChip
            key={index}
            email={email}
            isSelected={selectedChipIndex === index}
            onClick={(e) => handleChipClick(index, e)}
            onRemove={() => removeEmail(index)}
            disabled={disabled}
          />
        ))}
        <input
          ref={inputRef}
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onPaste={handlePaste}
          onBlur={handleBlur}
          disabled={disabled}
          className={cn(
            'flex-1 min-w-[100px] h-auto border-0 bg-transparent p-0',
            'outline-none focus:outline-none focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0',
            'placeholder:text-muted-foreground',
          )}
          placeholder={emails.length === 0 ? placeholder : ''}
        />
      </div>
      {error && (
        <p className="mt-1 text-xs text-red-500">{error.message}</p>
      )}
    </div>
  );
});

EmailRecipientInput.displayName = 'EmailRecipientInput';

export type { EmailChipsInputProps };
export default EmailRecipientInput;
