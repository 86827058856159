import { useQuery } from '@tanstack/react-query';
import { useAxios } from '@/hooks/use-axios';
import type { Folder as NylasFolder, ListFolderQueryParams } from 'nylas';

/**
 * Query keys for React Query cache management.
 * Structured hierarchically for consistent cache invalidation.
 */
export const NYLAS_FOLDERS_QUERY_KEYS = {
  all: ['nylasFolders'] as const,
  list: (grantId?: string) => ['nylasFolders', 'list', grantId] as const,
} as const;

/**
 * Response type for the Nylas folders API endpoint
 */
type NylasFoldersResponse = {
  data: NylasFolder[];
  requestId: string;
};

/**
 * Configuration options for the useNylasFolders hook
 */
type UseFoldersOptions = {
  /** Whether the query should automatically run. Defaults to true */
  enabled?: boolean;
  /** Optional query parameters for filtering and pagination */
  queryParams?: Partial<ListFolderQueryParams>;
};

/**
 * Hook to fetch folders for a given Nylas grant ID
 *
 * @param grantId - The Nylas grant ID to fetch folders for
 * @param options - Configuration options for the query
 *
 * @returns A query result object containing:
 * - data: Array of Nylas folders if the query was successful
 * - isLoading: Boolean indicating if the query is in progress
 * - error: Error object if the query failed
 * - Other standard react-query result properties
 *
 * @example
 * ```tsx
 * const { data: folders, isLoading } = useNylasFolders(grantId, {
 *   queryParams: { limit: 100 }
 * })
 * ```
 */
export function useNylasFolders(grantId: string | undefined, options: UseFoldersOptions = {}) {
  const axios = useAxios();
  const { enabled = true, queryParams } = options;

  return useQuery({
    queryKey: NYLAS_FOLDERS_QUERY_KEYS.list(grantId),
    queryFn: async () => {
      if (!grantId) {
        throw new Error('Grant ID is required');
      }

      const { data } = await axios.get<NylasFoldersResponse>(`/nylas/grants/${grantId}/folders`, {
        params: queryParams,
      });
      return data.data;
    },
    enabled: !!grantId && enabled,
  });
}
