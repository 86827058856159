import type { ColDef } from '@ag-grid-community/core';
import { format } from 'date-fns';
import { Clock, Eye } from 'lucide-react';
import { Button } from '@/components/ui/button';
import type { BatchResult } from '@/hooks/use-annual-verification-batches';

export const previewColumnDefs: ColDef<BatchResult>[] = [
  {
    field: 'criteria',
    headerName: 'Criteria Value',
    flex: 2,
  },
  {
    field: 'criteria_type',
    headerName: 'Criteria Type',
    width: 150,
    valueFormatter: (params: { value: string }) => {
      switch (params.value) {
        case 'registrant_email': {
          return 'Registrant Email';
        }
        case 'license': {
          return 'License';
        }
        case 'registrant_org_name': {
          return 'Organization Name';
        }
        default: {
          return params.value;
        }
      }
    },
  },
  {
    field: 'domains',
    headerName: 'Domains',
    width: 180,
    valueGetter: (params: { data: BatchResult | undefined }) => {
      return `${params.data?.domains.length || 0} domains`;
    },
    cellRenderer: (params: { data: BatchResult | undefined; context: { onViewDomains?: (domains: string[]) => void } }) => {
      const { data } = params;
      if (!data)
        return null;

      return (
        <div className="flex items-center gap-2">
          <span>
            {data.domains.length}
            {' '}
            domains
          </span>
          <Button
            variant="ghost"
            size="icon"
            onClick={() => params.context.onViewDomains?.(data.domains)}
            className="size-8"
          >
            <Eye className="size-4" />
          </Button>
        </div>
      );
    },
  },
  {
    field: 'oldest_verification',
    headerName: 'Oldest Verification',
    width: 180,
    valueFormatter: (params: { value: string }) => {
      if (!params.value)
        return '';
      return format(new Date(params.value), 'MMM d, yyyy');
    },
  },
  {
    field: 'domains',
    headerName: 'Actions',
    width: 100,
    cellRenderer: (params: { data: BatchResult | undefined; context: { onSchedule?: (batch: BatchResult) => void } }) => {
      const { data } = params;
      if (!data)
        return null;

      return (
        <Button
          variant="ghost"
          size="icon"
          onClick={() => params.context.onSchedule?.(data)}
          className="size-8"
        >
          <Clock className="size-4" />
        </Button>
      );
    },
  },
];
