import * as React from 'react';
import type { User } from '@clerk/backend';
import { hasExternalEmail } from '@/lib/messages';
import { getThreadTimestamp, parseParticipants } from '@/lib/threads';
import { truncateText } from '@/lib/utils';
import type { CommunicationDisplay } from '@/pages/app/deals/communications/types/communications';
import type { MessageDB } from '@/types/message';
import type { ThreadDB } from '@/types/thread';
import { useClerkUsers } from './use-clerk-user';

/** Helper to get user display info */
function getUserDisplayInfo(user: Partial<User> | undefined): { name: string; email: string } {
  if (!user)
    return { name: '', email: '' };

  return {
    name: `${user.firstName} ${user.lastName}`.trim(),
    email: user.emailAddresses?.[0]?.emailAddress || '',
  };
}

/**
 * Hook to parse and merge messages and threads with Clerk user data
 */
export function useMessageParser(messages: MessageDB[] = [], threads: ThreadDB[] = []): CommunicationDisplay[] {
  // Collect all unique user IDs and memoize the array
  const userIds = React.useMemo(() => {
    const ids = new Set<string>();

    // Add message sender IDs
    if (messages) {
      for (const msg of messages) {
        if (msg.sent_by) {
          ids.add(msg.sent_by);
        }
      }
    }

    // Add thread creator IDs
    if (threads) {
      for (const thread of threads) {
        if (thread.created_by) {
          ids.add(thread.created_by);
        }
      }
    }

    // Convert to array and sort for stable reference
    return [...ids].sort();
  }, [messages, threads]);

  // Fetch user data (React Query handles caching)
  const { data: users } = useClerkUsers(userIds);

  // Create a map of user data for quick lookup
  const userMap = React.useMemo(() => {
    if (!users)
      return {};

    const map: Record<string, Partial<User>> = {};
    for (const user of users) {
      if (user.id) {
        map[user.id] = user;
      }
    }
    return map;
  }, [users]);

  // Transform and merge communications
  return React.useMemo(() => {
    // Convert messages to display format
    const messageDisplays = messages.map(({ id, content, from, created_at, sent_by }) => {
      const { name } = getUserDisplayInfo(sent_by ? userMap[sent_by] : undefined);
      const sender_email = from?.[0]?.email;

      return {
        id,
        snippet: truncateText(content.body, 160),
        content,
        sender: {
          name: name || sender_email || 'Unknown',
          email: sender_email || '',
          avatar: userMap[sent_by]?.hasImage ? userMap[sent_by]?.imageUrl : undefined,
        },
        timestamp: created_at,
        isExternal: false,
        isEmail: false,
      };
    });

    // Convert threads to display format
    const threadDisplays = threads.map((thread) => {
      const participants = parseParticipants(thread.participants);

      // Use internal user info if available, otherwise first participant
      const sender = {
          name: participants[0]?.name || '',
          email: participants[0]?.email || '',
        };

      return {
        id: thread.id,
        snippet: truncateText(thread.snippet || '', 160),
        sender,
        timestamp: getThreadTimestamp(thread),
        isExternal: hasExternalEmail(participants),
        isEmail: true,
        participants,
        emailGrantId: thread.grant_id,
        emailThreadId: thread.thread_id,
        emailMessageIds: thread.message_ids ?? undefined,
        emailSubject: thread.subject || undefined,
      };
    });

    // Merge and sort by date (latest to earliest)
    return [...messageDisplays, ...threadDisplays].sort((a, b) =>
      new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime(),
    );
  }, [messages, threads, userMap]);
}
