import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { useBreadcrumb } from '@/components/breadcrumb-provider';
import Container from '@/components/container';
import { Card } from '@/components/ui/card';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useDomain, DOMAINS_QUERY_KEY } from '@/hooks/use-domains';
import { DomainContacts } from './components/domain-contacts';
import { DomainForm } from './components/domain-form';
import { MonitoringHistory, TransactionHistory, ContactHistory, RDDSHistory } from './components/domain-history';
import { DomainLicenses } from './components/domain-licenses';

function DomainDetailsPage() {
  const { name } = useParams();
  const { setLabel, removeLabel } = useBreadcrumb();
  const { data: domain, isLoading } = useDomain(name || '');
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!domain)
      return;
    const path = `/app/domains/${domain.name}`;
    setLabel(path, {
      name: domain.name,
      path,
    });
    return () => removeLabel(path);
  }, [domain, setLabel, removeLabel]);

  if (isLoading || !domain) {
    return (
      <Container className="pb-12">
        <div>Loading...</div>
      </Container>
    );
  }

  const registrantContact = domain.domain_contact_junctions.find((junction) => junction.type === 'registrant')?.contact;

  return (
    <Container className="pb-12">
      <Card className="overflow-hidden">
        <Tabs defaultValue="details" className="w-full">
          <div className="border-b">
            <TabsList className="h-auto w-full justify-start rounded-none bg-transparent p-0">
              <TabsTrigger
                value="details"
                className="relative rounded-none border-b-2 border-transparent px-4 py-3 data-[state=active]:border-primary data-[state=active]:bg-transparent"
              >
                Details
              </TabsTrigger>
              <TabsTrigger
                value="contacts"
                className="relative rounded-none border-b-2 border-transparent px-4 py-3 data-[state=active]:border-primary data-[state=active]:bg-transparent"
              >
                Contacts
              </TabsTrigger>
              {registrantContact && (
                <TabsTrigger
                  value="licenses"
                  className="relative rounded-none border-b-2 border-transparent px-4 py-3 data-[state=active]:border-primary data-[state=active]:bg-transparent"
                >
                  Licenses
                </TabsTrigger>
              )}
              <TabsTrigger
                value="monitoring"
                className="relative rounded-none border-b-2 border-transparent px-4 py-3 data-[state=active]:border-primary data-[state=active]:bg-transparent"
              >
                Monitoring History
              </TabsTrigger>
              <TabsTrigger
                value="transactions"
                className="relative rounded-none border-b-2 border-transparent px-4 py-3 data-[state=active]:border-primary data-[state=active]:bg-transparent"
              >
                Transactions
              </TabsTrigger>
              <TabsTrigger
                value="modifications"
                className="relative rounded-none border-b-2 border-transparent px-4 py-3 data-[state=active]:border-primary data-[state=active]:bg-transparent"
              >
                Contact Modifications
              </TabsTrigger>
              <TabsTrigger
                value="rdds"
                className="relative rounded-none border-b-2 border-transparent px-4 py-3 data-[state=active]:border-primary data-[state=active]:bg-transparent"
              >
                RDDS History
              </TabsTrigger>
            </TabsList>
          </div>
          <ScrollArea className="h-[calc(100vh-12rem)]">
            <TabsContent value="details" className="m-0 p-6 focus-visible:outline-none focus-visible:ring-0">
              <DomainForm domain={domain} />
            </TabsContent>
            <TabsContent value="contacts" className="m-0 p-6 focus-visible:outline-none focus-visible:ring-0">
              <DomainContacts
                contacts={domain.domain_contact_junctions}
                domain={domain.name}
                onAssociationChange={() => {
                  void queryClient.invalidateQueries({ queryKey: [...DOMAINS_QUERY_KEY, domain.name] });
                }}
              />
            </TabsContent>
            {registrantContact && (
              <TabsContent value="licenses" className="m-0 p-6 focus-visible:outline-none focus-visible:ring-0">
                <DomainLicenses registrantContact={registrantContact} />
              </TabsContent>
            )}
            <TabsContent value="monitoring" className="m-0 p-6 focus-visible:outline-none focus-visible:ring-0">
              {domain.monitor_data.length > 0
                ? (
                    <MonitoringHistory history={domain.monitor_data} />
                  )
                : (
                    <p className="text-center text-muted-foreground">No monitoring history available.</p>
                  )}
            </TabsContent>
            <TabsContent value="transactions" className="m-0 p-6 focus-visible:outline-none focus-visible:ring-0">
              {domain.godaddy_transactions.length > 0
                ? (
                    <TransactionHistory transactions={domain.godaddy_transactions} />
                  )
                : (
                    <p className="text-center text-muted-foreground">No transaction history available.</p>
                  )}
            </TabsContent>
            <TabsContent value="modifications" className="m-0 p-6 focus-visible:outline-none focus-visible:ring-0">
              {domain.godaddy_modifications.length > 0
                ? (
                    <ContactHistory modifications={domain.godaddy_modifications} />
                  )
                : (
                    <p className="text-center text-muted-foreground">No contact modifications available.</p>
                  )}
            </TabsContent>
            <TabsContent value="rdds" className="m-0 p-6 focus-visible:outline-none focus-visible:ring-0">
              {domain.rdds_history?.length > 0
                ? (
                    <RDDSHistory history={domain.rdds_history} />
                  )
                : (
                    <p className="text-center text-muted-foreground">No RDDS history available.</p>
                  )}
            </TabsContent>
          </ScrollArea>
        </Tabs>
      </Card>
    </Container>
  );
}

export default DomainDetailsPage;
