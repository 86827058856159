import type { FileSearchParams } from '@/types/drive';

export const {
  VITE_BASE_URL: BASE_URL,
  VITE_CLERK_PUBLISHABLE_KEY: CLERK_PUBLISHABLE_KEY,
  VITE_API_BASE_URL: API_BASE_URL,
  VITE_API_PUBLIC_BASE_URL: API_PUBLIC_BASE_URL,
  VITE_MONGO_URL: MONGO_URL,
  VITE_AG_GRID_LICENSE_KEY: AG_GRID_LICENSE_KEY,
  VITE_INTERNAL_DOMAINS: INTERNAL_DOMAINS,
} = import.meta.env;

// header defaults
export const DEFAULT_TITLE = 'fTLD v3';
export const DEFAULT_DESCRIPTION = 'fTLD v3';
export const DEFAULT_URL = 'https://v3.ftld.com';
export const DEFAULT_IMAGE = '/assets/ftld-logo.svg';

// DRY verify@ftld.com
export const VERIFY_EMAIL = 'verify@ftld.com';

// React Query keys
export const DRIVE_QUERY_KEYS = {
  files: {
    all: ['driveFiles'] as const,
    list: (params: FileSearchParams) => [...DRIVE_QUERY_KEYS.files.all, 'list', params] as const,
    detail: (fileId: string) => [...DRIVE_QUERY_KEYS.files.all, 'detail', fileId] as const,
  },
} as const;

export function getPublicAPIBaseUrl() {
  return API_PUBLIC_BASE_URL || API_BASE_URL;
}
