import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { useAxios } from './use-axios';

const API_BASE_URL = '/deals';

export const DEALS_QUERY_KEY = ['deals'];

interface ApiError {
  error?: {
    message?: string;
    status?: number;
  };
  details?: Array<{ message: string }>;
}

function getErrorMessage(error: unknown): string {
  if (isAxiosError<ApiError>(error)) {
    // Handle validation errors
    if (error.response?.data?.details) {
      return 'Please check your input and try again';
    }

    // Handle specific error codes
    if (error.response?.status === 500) {
      return 'An internal server error occurred. Please try again later';
    }

    if (error.response?.status === 401 || error.response?.status === 403) {
      return 'You do not have permission to perform this action';
    }

    if (error.response?.status === 404) {
      return 'The requested resource was not found';
    }

    // Handle network errors
    if (error.message === 'Network Error') {
      return 'Unable to connect to the server. Please check your internet connection';
    }
  }

  return 'An unexpected error occurred. Please try again';
}

export type DealType =
  | 'New Business'
  | 'Existing Business'
  | 'Annual Verification'
  | 'Forced Verification'
  | string; // Allow for future additions

export type DealOutcome =
  | 'Approved'
  | 'Combined'
  | 'Consolidation'
  | 'Did not Register'
  | 'Domain Deleted'
  | 'Duplicate'
  | 'fTLD Domain'
  | 'Incomplete'
  | 'Ineligible'
  | 'Junk'
  | 'Name Selection'
  | 'No Renew'
  | 'No Reply'
  | 'No Verification Needed'
  | 'Not Wanted'
  | 'Organization'
  | 'Price'
  | 'Test'
  | string; // Allow for future additions

export type DealStage =
  | 'Annual Verification'
  | 'Pending Verification'
  | 'Organization Verification in Progress'
  | 'Name Selection in Progress'
  | 'Employment Verification in Progress'
  | 'WHOIS Update in Progress'
  | 'Failed Verification'
  | 'Verification Completed'
  | 'Verified Data Sent'
  | 'Completed'
  | string; // Allow for future additions

interface Person {
  name: string;
  legal_name: string;
  alt_name: string | null;
  email: string;
  alt_email: string | null;
  phone_number: string;
  fax_number: string | null;
  address_line_1: string;
  address_line_2: string | null;
  address_line_3: string | null;
  city: string;
  state_province: string;
  postal_code: string;
  country: string;
  title: string;
  c_suite: boolean;
  sole_prop: boolean;
  new_legal_name: boolean;
  contact_ids: string[];
  associated_domains: string[];
}

interface VerificationContact extends Person {
  organization_name: string;
}

interface License {
  authority: string;
  value: string;
  contact_ids: string[];
  license_ids: string[];
}

interface Organization {
  name: string;
  email: string;
  phone_number: string;
  fax_number: string | null;
  address_line_1: string;
  address_line_2: string | null;
  address_line_3: string | null;
  city: string;
  state_province: string;
  postal_code: string;
  country: string;
  contact_ids: string[];
  associated_domains: string[];
  licenses: License[];
}

export interface Deal {
  id: string;
  created_at: string;
  updated_at: string;
  created_by: string;
  updated_by: string;
  verification_document: {
    body: string;
  } | null;
  deal_stage: DealStage;
  deal_type: DealType;
  outcome: DealOutcome | null;
  assigned_to: string;
  domains: string[];
  archived_at: string | null;
  registrants: {
    people: Person[];
    organizations: Organization[];
  };
  verification_contacts: {
    people: VerificationContact[];
  };
}

export interface CreateDealInput {
  verification_document?: {
    body: string;
  } | null;
  deal_stage: DealStage;
  deal_type?: DealType;
  outcome?: DealOutcome | null;
  assigned_to: string;
  domains: string[];
}

export interface UpdateDealInput {
  verification_document?: {
    body: string;
  } | null;
  deal_stage?: DealStage;
  deal_type?: DealType;
  outcome?: DealOutcome | null;
  assigned_to?: string;
  domains?: string[];
  archived_at?: string | null;
  registrants?: {
    people: Array<{
      name: string;
      legal_name: string;
      alt_name: string | null;
      email: string;
      alt_email: string | null;
      phone_number: string;
      fax_number: string | null;
      address_line_1: string;
      address_line_2: string | null;
      address_line_3: string | null;
      city: string;
      state_province: string;
      postal_code: string;
      country: string;
      title: string;
      c_suite: boolean;
      sole_prop: boolean;
      new_legal_name: boolean;
      contact_ids: string[];
    }>;
    organizations: Array<{
      name: string;
      email: string;
      phone_number: string;
      fax_number: string | null;
      address_line_1: string;
      address_line_2: string | null;
      address_line_3: string | null;
      city: string;
      state_province: string;
      postal_code: string;
      country: string;
      contact_ids: string[];
      licenses: Array<{
        authority: string;
        value: string;
        contact_ids: string[];
        license_ids: string[];
      }>;
    }>;
  };
  verification_contacts?: {
    people: Array<{
      name: string;
      legal_name: string;
      alt_name: string | null;
      email: string;
      alt_email: string | null;
      phone_number: string;
      fax_number: string | null;
      address_line_1: string;
      address_line_2: string | null;
      address_line_3: string | null;
      city: string;
      state_province: string;
      postal_code: string;
      country: string;
      title: string;
      c_suite: boolean;
      sole_prop: boolean;
      new_legal_name: boolean;
      organization_name: string;
      contact_ids: string[];
    }>;
  };
}

// List all deals
export function useDeals() {
  const axios = useAxios();

  return useQuery({
    queryKey: DEALS_QUERY_KEY,
    queryFn: async () => {
      try {
        const { data } = await axios.get<Deal[]>(API_BASE_URL);
        // Filter out archived deals (where archived_at is truthy)
        return data.filter((deal) => !deal.archived_at);
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
  });
}

// Get a single deal by ID
export function useDeal(id: string) {
  const axios = useAxios();

  return useQuery({
    queryKey: [...DEALS_QUERY_KEY, id],
    queryFn: async () => {
      try {
        const { data } = await axios.get<Deal>(`${API_BASE_URL}/${id}`);
        return data;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    enabled: !!id,
  });
}

// Create a new deal
export function useCreateDeal() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (input: CreateDealInput) => {
      try {
        const { data } = await axios.post<Deal>(API_BASE_URL, input);
        return data;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: DEALS_QUERY_KEY });
    },
  });
}

// Update a deal
export function useUpdateDeal() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, ...input }: { id: string } & UpdateDealInput) => {
      try {
        const { data } = await axios.put<Deal>(`${API_BASE_URL}/${id}`, input);
        return data;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    onSuccess: async (_, { id }) => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: [...DEALS_QUERY_KEY, id] }),
        queryClient.invalidateQueries({ queryKey: DEALS_QUERY_KEY }),
      ]);
    },
  });
}
