import { useQuery } from '@tanstack/react-query';
import type { Message as NylasMessage } from 'nylas';
import useAxios from './use-axios';

type MessagesResponse = {
  messages: NylasMessage[];
  requestId: string;
};

type UseNylasMessagesParams = {
  grantId: string;
  messageIds: string[];
};

export const NYLAS_QUERY_KEYS = {
  messages: {
    all: ['nylasMessages'] as const,
    detail: (params: UseNylasMessagesParams) => [...NYLAS_QUERY_KEYS.messages.all, params] as const,
  },
} as const;

/**
 * React Query hook to fetch Nylas email messages for given message IDs under a specific grant.
 *
 * @param params - Hook parameters
 * @param params.grantId - The Nylas grant ID to authenticate the request
 * @param params.messageIds - Array of Nylas message IDs to fetch
 *
 * @returns React Query result containing:
 * - `data`: Object with `messages` array of Nylas messages and `requestId`
 * - `isLoading`: Boolean indicating if request is in progress
 * - `error`: Any error that occurred during the request
 *
 * @remarks
 * - Automatically refetches data every 30 seconds when the tab is active
 * - Only enabled when both grantId is present and messageIds array is non-empty
 */
export function useNylasMessages(params: UseNylasMessagesParams) {
  const axios = useAxios();
  const { grantId, messageIds } = params;

  return useQuery({
    queryKey: NYLAS_QUERY_KEYS.messages.detail(params),
    queryFn: async () => {
      const { data } = await axios.post<MessagesResponse>('/nylas/messages', {
        grant_id: grantId,
        message_ids: messageIds,
      });
      return data;
    },
    enabled: !!grantId && messageIds.length > 0,
    refetchInterval: 30 * 1000, // Refetch every 30 seconds
    refetchIntervalInBackground: false, // Only refetch when tab is active
  });
}
