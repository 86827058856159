import { useState, useEffect, useRef, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import type { User } from '@clerk/backend';
import { useAuth } from '@clerk/clerk-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { format, set, isPast, isToday, addDays } from 'date-fns';
import {
  CalendarClock,
  Check,
  Plus,
  Calendar as CalendarIcon,
  User as UserIcon,
  MessageSquare,
  Pencil,
  AlertCircle,
  Trash2,
} from 'lucide-react';
import { toast } from 'sonner';
import * as z from 'zod';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from '@/components/ui/form';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { ScrollArea } from '@/components/ui/scroll-area';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Skeleton } from '@/components/ui/skeleton';
import { useAllClerkUsers } from '@/hooks/use-clerk-user';
import { useDeal } from '@/hooks/use-deals';
import { useCreateTask, useTasks, useUpdateTask, useAddTaskMessage, useUpdateTaskMessage, useDeleteTaskMessage, useDeleteTask, type Task } from '@/hooks/use-tasks';
import { cn } from '@/lib/utils';
import { TimeSelect } from './time-select';

const formSchema = z.object({
  task: z.string().min(1, 'Task description is required.'),
  due: z.date({
    required_error: 'A due date is required.',
  }),
  dueTime: z.object({
    hours: z.string(),
    minutes: z.string(),
    period: z.enum(['AM', 'PM']),
  }),
  assigned_to: z.array(z.string()).min(1, 'At least one assignee is required.'),
});

const defaultFormValues = {
  task: '',
  assigned_to: [],
  due: addDays(new Date(), 1),
  dueTime: {
    hours: '09',
    minutes: '00',
    period: 'AM' as const,
  },
};

export function TasksPanel() {
  const [isCreating, setIsCreating] = useState(false);
  const [editingTaskId, setEditingTaskId] = useState<string | null>(null);
  const [expandedTaskId, setExpandedTaskId] = useState<string | null>(null);
  const [chatMessage, setChatMessage] = useState('');
  const [editingMessageId, setEditingMessageId] = useState<string | null>(null);
  const [editedMessage, setEditedMessage] = useState('');
  const { dealId } = useParams();
  const { userId } = useAuth();
  const { data: tasks, isLoading } = useTasks();
  const { data: deal } = useDeal(dealId!);
  const { data: users } = useAllClerkUsers();
  const { mutate: createTask, isPending: isCreatingTask } = useCreateTask();
  const { mutate: updateTask } = useUpdateTask();
  const { mutate: addMessage } = useAddTaskMessage();
  const { mutate: updateMessage } = useUpdateTaskMessage();
  const { mutate: deleteMessage } = useDeleteTaskMessage();
  const { mutate: deleteTask } = useDeleteTask();

  const dealTasks = tasks?.filter((task) => task.deal_id === deal?.id) ?? [];

  const createForm = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: defaultFormValues,
  });

  const editForm = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: defaultFormValues,
  });

  // Reset create form when creating new task
  useEffect(() => {
    if (isCreating) {
      createForm.reset({
        ...defaultFormValues,
        assigned_to: userId ? [userId] : [],
      });
    }
  }, [isCreating, createForm, userId]);

  function onCreateSubmit(values: z.infer<typeof formSchema>) {
    if (!deal?.id)
      return;

    // Convert 12-hour time to 24-hour time
    let hours = Number.parseInt(values.dueTime.hours);
    if (values.dueTime.period === 'PM' && hours !== 12) {
      hours += 12;
    } else if (values.dueTime.period === 'AM' && hours === 12) {
      hours = 0;
    }

    const dueDate = set(values.due, {
      hours,
      minutes: Number.parseInt(values.dueTime.minutes),
      seconds: 0,
      milliseconds: 0,
    });

    createTask(
      {
        deal_id: deal.id,
        task: values.task,
        due: dueDate.toISOString(),
        assigned_to: values.assigned_to,
      },
      {
        onSuccess: () => {
          toast.success('Task created');
          createForm.reset(defaultFormValues);
          setIsCreating(false);
        },
        onError: (error) => {
          toast.error(error.message);
        },
      },
    );
  }

  if (isLoading) {
    return (
      <div className="space-y-4 p-4">
        <div className="flex items-center justify-between">
          <Skeleton className="h-8 w-[200px]" />
          <Skeleton className="h-9 w-[100px]" />
        </div>
        <div className="space-y-4">
          {Array.from({ length: 3 }).map((_, i) => (
            <Skeleton key={i} className="h-[100px] w-full" />
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="flex h-full max-h-[600px] flex-col overflow-hidden bg-background">
      <div className="flex items-center justify-between border-b p-4">
        <div>
          <h2 className="text-lg font-semibold">Tasks</h2>
          <p className="text-sm text-muted-foreground">
            {dealTasks.length}
            {' '}
            task
            {dealTasks.length === 1 ? '' : 's'}
          </p>
        </div>
        <Button
          variant="outline"
          onClick={() => setIsCreating(true)}
          className="gap-2"
        >
          <Plus className="size-4" />
          Add Task
        </Button>
      </div>
      <div className="flex-1 overflow-y-auto p-4">
        <div className="space-y-2">
          {isCreating && (
            <Form {...createForm}>
              <form onSubmit={createForm.handleSubmit(onCreateSubmit)} className="space-y-4 rounded-lg border bg-card p-4">
                <FormField
                  control={createForm.control}
                  name="task"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <textarea
                          {...field}
                          className="w-full bg-transparent text-sm placeholder:text-muted-foreground focus:outline-none"
                          placeholder="What needs to be done?"
                          rows={2}
                          autoFocus
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <div className="flex items-center gap-2">
                  <FormField
                    control={createForm.control}
                    name="due"
                    render={({ field }) => (
                      <FormItem className="flex-none">
                        <Popover>
                          <PopoverTrigger asChild>
                            <FormControl>
                              <Button
                                variant="outline"
                                size="sm"
                                className={cn(
                                  'gap-2',
                                  !field.value && 'text-muted-foreground',
                                )}
                              >
                                <CalendarIcon className="size-4" />
                                {field.value
                                  ? format(
                                      set(field.value, {
                                        hours: createForm.getValues('dueTime').period === 'PM' && createForm.getValues('dueTime').hours !== '12'
                                          ? Number(createForm.getValues('dueTime').hours) + 12
                                          : (createForm.getValues('dueTime').period === 'AM' && createForm.getValues('dueTime').hours === '12'
                                              ? 0
                                              : Number(createForm.getValues('dueTime').hours)),
                                        minutes: Number(createForm.getValues('dueTime').minutes),
                                      }),
                                      'MMM d, h:mm a',
                                    )
                                  : 'Due date'}
                              </Button>
                            </FormControl>
                          </PopoverTrigger>
                          <PopoverContent className="w-[280px] p-0" align="start">
                            <Calendar
                              mode="single"
                              selected={field.value}
                              onSelect={field.onChange}
                              disabled={(date) =>
                                date < new Date(new Date().setHours(0, 0, 0, 0))}
                              initialFocus
                            />
                            <div className="border-t">
                              <FormField
                                control={createForm.control}
                                name="dueTime"
                                render={({ field }) => (
                                  <div className="p-3">
                                    <FormLabel className="text-xs text-muted-foreground">Time</FormLabel>
                                    <TimeSelect field={field} />
                                  </div>
                                )}
                              />
                            </div>
                          </PopoverContent>
                        </Popover>
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={createForm.control}
                    name="assigned_to"
                    render={({ field }) => (
                      <FormItem className="flex-none">
                        <Popover>
                          <PopoverTrigger asChild>
                            <FormControl>
                              <Button
                                variant="outline"
                                size="sm"
                                className={cn(
                                  'gap-2',
                                  field.value.length === 0 && 'text-muted-foreground',
                                )}
                              >
                                <UserIcon className="size-4" />
                                {field.value.length === 0
                                  ? 'Assign to'
                                  : `${field.value.length} assigned`}
                              </Button>
                            </FormControl>
                          </PopoverTrigger>
                          <PopoverContent className="w-[350px] p-0" align="start">
                            <Command>
                              <CommandInput placeholder="Search users..." />
                              <CommandEmpty>No users found.</CommandEmpty>
                              <CommandGroup>
                                {users?.map((user) => (
                                  user && (
                                    <CommandItem
                                      key={user.id}
                                      onSelect={() => {
                                        const isSelected = field.value.includes(user.id ?? '');
                                        if (isSelected) {
                                          field.onChange(field.value.filter((id) => id !== user.id));
                                        } else {
                                          field.onChange([...field.value, user.id ?? '']);
                                        }
                                      }}
                                    >
                                      <div className="flex items-center gap-2">
                                        <div
                                          className={cn(
                                            'flex size-4 items-center justify-center rounded-sm border',
                                            field.value.includes(user.id ?? '')
                                              ? 'border-primary bg-primary text-primary-foreground'
                                              : 'border-muted',
                                          )}
                                        >
                                          {field.value.includes(user.id ?? '') && (
                                            <Check className="size-3" />
                                          )}
                                        </div>
                                        <div className="flex flex-col gap-0.5">
                                          <span className="text-sm">
                                            {user.firstName}
                                            {' '}
                                            {user.lastName}
                                          </span>
                                          <span className="text-xs text-muted-foreground">
                                            {user.primaryEmailAddress?.emailAddress}
                                          </span>
                                        </div>
                                      </div>
                                    </CommandItem>
                                  )
                                ))}
                              </CommandGroup>
                            </Command>
                          </PopoverContent>
                        </Popover>
                      </FormItem>
                    )}
                  />
                  <div className="flex-1" />
                  <Button
                    type="submit"
                    size="sm"
                    disabled={isCreatingTask}
                  >
                    Add Task
                  </Button>
                  <Button
                    type="button"
                    variant="ghost"
                    size="sm"
                    onClick={() => {
                      createForm.reset(defaultFormValues);
                      setIsCreating(false);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            </Form>
          )}
          {dealTasks.length === 0 && !isCreating
            ? (
                <div className="flex h-[200px] items-center justify-center rounded-lg border border-dashed">
                  <div className="text-center">
                    <CalendarClock className="mx-auto size-8 text-muted-foreground" />
                    <h3 className="mt-2 font-medium">No tasks yet</h3>
                    <p className="text-sm text-muted-foreground">
                      Create a task to get started
                    </p>
                  </div>
                </div>
              )
            : (
                dealTasks.map((task) => {
                  const dueDate = new Date(task.due);
                  const now = new Date();
                  const isOverdue = !task.done && dueDate < now;

                  return (
                    <div
                      key={task.id}
                      className={cn(
                        'group flex items-start gap-3 rounded-lg border bg-card p-4 transition-colors hover:bg-accent/50',
                        task.done && 'bg-muted hover:bg-muted/80',
                        isOverdue && 'border-destructive/50 bg-destructive/5 hover:border-destructive hover:bg-destructive/10',
                      )}
                    >
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          updateTask({
                            id: task.id,
                            done: task.done ? null : new Date().toISOString(),
                          });
                        }}
                        className={cn(
                          'mt-1 flex size-4 shrink-0 items-center justify-center rounded-sm border border-primary ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
                          task.done
                            ? 'bg-primary text-primary-foreground'
                            : 'hover:bg-primary/10',
                        )}
                      >
                        <Check className={cn('size-3', !task.done && 'invisible')} />
                      </button>
                      <div className="min-w-0 flex-1 space-y-3">
                        <div className="flex items-start justify-between gap-4">
                          <div className="min-w-0 flex-1">
                            <p className={cn('text-sm', task.done && 'text-muted-foreground line-through')}>
                              {task.task}
                            </p>
                            <div className="mt-1 flex items-center gap-3 text-xs text-muted-foreground">
                              <span className="flex items-center gap-1">
                                <CalendarIcon className="size-3" />
                                {format(dueDate, 'MMM d, h:mm a')}
                              </span>
                              {isOverdue && !task.done && (
                                <Badge variant="destructive" className="gap-1 rounded-sm px-1 py-0 text-[10px] font-medium">
                                  <AlertCircle className="size-3" />
                                  Overdue
                                </Badge>
                              )}
                            </div>
                          </div>
                          <div className="flex shrink-0 items-center gap-4">
                            <div className="flex items-center gap-2">
                              {task.assigned_to_users?.map((user, index) => (
                                user && (
                                  <div
                                    key={user.id}
                                    className="group relative"
                                    onMouseEnter={(e) => {
                                      const content = e.currentTarget.querySelector('[data-hover-content]');
                                      if (content)
                                        content.classList.remove('opacity-0', 'pointer-events-none');
                                    }}
                                    onMouseLeave={(e) => {
                                      const content = e.currentTarget.querySelector('[data-hover-content]');
                                      if (content)
                                        content.classList.add('opacity-0', 'pointer-events-none');
                                    }}
                                  >
                                    <Avatar
                                      className={cn(
                                        'size-5 shrink-0 cursor-pointer rounded-full border-2 border-background',
                                        task.done && 'opacity-50',
                                      )}
                                    >
                                      <AvatarImage
                                        src={user.imageUrl}
                                        alt={`${user.firstName} ${user.lastName}`}
                                      />
                                      <AvatarFallback className="text-xs">
                                        {user.firstName?.[0]}
                                        {user.lastName?.[0]}
                                      </AvatarFallback>
                                    </Avatar>
                                    <div
                                      data-hover-content
                                      className="pointer-events-none absolute -top-16 left-1/2 z-50 min-w-max -translate-x-1/2 rounded-md bg-popover px-3 py-2 text-popover-foreground opacity-0 shadow-md transition-opacity"
                                    >
                                      <div className="flex flex-col gap-0.5">
                                        <span className="font-medium">
                                          {user.firstName}
                                          {' '}
                                          {user.lastName}
                                        </span>
                                        <span className="text-xs text-muted-foreground">
                                          {user.primaryEmailAddress?.emailAddress}
                                        </span>
                                      </div>
                                      <div className="absolute -bottom-1 left-1/2 size-2 -translate-x-1/2 rotate-45 bg-popover" />
                                    </div>
                                  </div>
                                )
                              ))}
                            </div>
                            <div className="flex items-center gap-1">
                              <Button
                                variant="ghost"
                                size="icon"
                                className="size-7"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setEditingTaskId(editingTaskId === task.id ? null : task.id);
                                  setExpandedTaskId(null);
                                  if (editingTaskId !== task.id) {
                                    const dueDate = new Date(task.due);
                                    const hours = dueDate.getHours();
                                    const minutes = dueDate.getMinutes();
                                    const period = hours >= 12 ? 'PM' : 'AM';
                                    const hour12 = hours % 12 || 12;

                                    editForm.reset({
                                      task: task.task,
                                      due: dueDate,
                                      dueTime: {
                                        hours: hour12.toString().padStart(2, '0'),
                                        minutes: minutes.toString().padStart(2, '0'),
                                        period,
                                      },
                                      assigned_to: task.assigned_to,
                                    });
                                  }
                                }}
                              >
                                <Pencil className="size-3" />
                              </Button>
                              <Button
                                variant="ghost"
                                size="icon"
                                className={cn(
                                  'size-7 relative',
                                  expandedTaskId === task.id && 'bg-accent',
                                )}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setExpandedTaskId(expandedTaskId === task.id ? null : task.id);
                                  setEditingTaskId(null);
                                }}
                              >
                                <MessageSquare className="size-3" />
                                {task.chat_history.length > 0 && (
                                  <div className="absolute -right-1 -top-1 flex size-3 items-center justify-center rounded-full bg-primary text-[10px] text-primary-foreground">
                                    {task.chat_history.length}
                                  </div>
                                )}
                              </Button>
                              <Button
                                variant="ghost"
                                size="icon"
                                className="size-7 text-destructive hover:text-destructive"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  const confirm = async () => new Promise<boolean>((resolve) => {
                                    toast.custom(() => (
                                      <div className="flex flex-col gap-2 rounded-lg border bg-card p-4 shadow-lg">
                                        <p className="font-medium">Delete Task</p>
                                        <p className="text-sm text-muted-foreground">Are you sure you want to delete this task? This will also delete all chat messages.</p>
                                        <div className="flex justify-end gap-2">
                                          <Button
                                            variant="ghost"
                                            size="sm"
                                            onClick={() => {
                                              toast.dismiss();
                                              resolve(false);
                                            }}
                                          >
                                            Cancel
                                          </Button>
                                          <Button
                                            variant="destructive"
                                            size="sm"
                                            onClick={() => {
                                              toast.dismiss();
                                              resolve(true);
                                            }}
                                          >
                                            Delete
                                          </Button>
                                        </div>
                                      </div>
                                    ), { duration: Infinity });
                                  });

                                  void confirm().then((confirmed) => {
                                    if (confirmed) {
                                      deleteTask(task.id, {
                                        onSuccess: () => {
                                          toast.success('Task deleted');
                                          setEditingTaskId(null);
                                          setExpandedTaskId(null);
                                        },
                                        onError: (error: Error) => {
                                          toast.error(error.message);
                                        },
                                      });
                                    }
                                  });
                                }}
                              >
                                <Trash2 className="size-3" />
                              </Button>
                            </div>
                          </div>
                        </div>
                        {editingTaskId === task.id && (
                          <Form {...editForm}>
                            <form
                              onSubmit={(e) => {
                                e.preventDefault();
                                const values = editForm.getValues();

                                // Convert 12-hour time to 24-hour time
                                let hours = Number.parseInt(values.dueTime.hours);
                                if (values.dueTime.period === 'PM' && hours !== 12) {
                                  hours += 12;
                                } else if (values.dueTime.period === 'AM' && hours === 12) {
                                  hours = 0;
                                }

                                const dueDate = set(values.due, {
                                  hours,
                                  minutes: Number.parseInt(values.dueTime.minutes),
                                  seconds: 0,
                                  milliseconds: 0,
                                });

                                updateTask(
                                  {
                                    id: task.id,
                                    task: values.task,
                                    due: dueDate.toISOString(),
                                    assigned_to: values.assigned_to,
                                  },
                                  {
                                    onSuccess: () => {
                                      toast.success('Task updated');
                                      setEditingTaskId(null);
                                    },
                                    onError: (error) => {
                                      toast.error(error.message);
                                    },
                                  },
                                );
                              }}
                              className="space-y-4"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <FormField
                                control={editForm.control}
                                name="task"
                                render={({ field }) => (
                                  <FormItem>
                                    <FormControl>
                                      <textarea
                                        {...field}
                                        className="w-full rounded-md border bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2"
                                        placeholder="What needs to be done?"
                                        rows={2}
                                      />
                                    </FormControl>
                                  </FormItem>
                                )}
                              />
                              <div className="flex items-center gap-2">
                                <FormField
                                  control={editForm.control}
                                  name="due"
                                  render={({ field }) => (
                                    <FormItem className="flex-none">
                                      <Popover>
                                        <PopoverTrigger asChild>
                                          <FormControl>
                                            <Button
                                              variant="outline"
                                              size="sm"
                                              className={cn(
                                                'gap-2',
                                                !field.value && 'text-muted-foreground',
                                              )}
                                            >
                                              <CalendarIcon className="size-4" />
                                              {field.value
                                                ? format(
                                                    set(field.value, {
                                                      hours: editForm.getValues('dueTime').period === 'PM' && editForm.getValues('dueTime').hours !== '12'
                                                        ? Number(editForm.getValues('dueTime').hours) + 12
                                                        : (editForm.getValues('dueTime').period === 'AM' && editForm.getValues('dueTime').hours === '12'
                                                            ? 0
                                                            : Number(editForm.getValues('dueTime').hours)),
                                                      minutes: Number(editForm.getValues('dueTime').minutes),
                                                    }),
                                                    'MMM d, h:mm a',
                                                  )
                                                : 'Due date'}
                                            </Button>
                                          </FormControl>
                                        </PopoverTrigger>
                                        <PopoverContent className="w-[280px] p-0" align="start">
                                          <Calendar
                                            mode="single"
                                            selected={field.value}
                                            onSelect={field.onChange}
                                            initialFocus
                                          />
                                          <div className="border-t">
                                            <FormField
                                              control={editForm.control}
                                              name="dueTime"
                                              render={({ field }) => (
                                                <div className="p-3">
                                                  <FormLabel className="text-xs text-muted-foreground">Time</FormLabel>
                                                  <TimeSelect field={field} />
                                                </div>
                                              )}
                                            />
                                          </div>
                                        </PopoverContent>
                                      </Popover>
                                    </FormItem>
                                  )}
                                />
                                <FormField
                                  control={editForm.control}
                                  name="assigned_to"
                                  render={({ field }) => (
                                    <FormItem className="flex-none">
                                      <Popover>
                                        <PopoverTrigger asChild>
                                          <FormControl>
                                            <Button
                                              variant="outline"
                                              size="sm"
                                              className={cn(
                                                'gap-2',
                                                field.value.length === 0 && 'text-muted-foreground',
                                              )}
                                            >
                                              <UserIcon className="size-4" />
                                              {field.value.length === 0
                                                ? 'Assign to'
                                                : `${field.value.length} assigned`}
                                            </Button>
                                          </FormControl>
                                        </PopoverTrigger>
                                        <PopoverContent className="w-[350px] p-0" align="start">
                                          <Command>
                                            <CommandInput placeholder="Search users..." />
                                            <CommandEmpty>No users found.</CommandEmpty>
                                            <CommandGroup>
                                              {users?.map((user) => (
                                                user && (
                                                  <CommandItem
                                                    key={user.id}
                                                    onSelect={() => {
                                                      const isSelected = field.value.includes(user.id ?? '');
                                                      if (isSelected) {
                                                        field.onChange(field.value.filter((id) => id !== user.id));
                                                      } else {
                                                        field.onChange([...field.value, user.id ?? '']);
                                                      }
                                                    }}
                                                  >
                                                    <div className="flex items-center gap-2">
                                                      <div
                                                        className={cn(
                                                          'flex size-4 items-center justify-center rounded-sm border',
                                                          field.value.includes(user.id ?? '')
                                                            ? 'border-primary bg-primary text-primary-foreground'
                                                            : 'border-muted',
                                                        )}
                                                      >
                                                        {field.value.includes(user.id ?? '') && (
                                                          <Check className="size-3" />
                                                        )}
                                                      </div>
                                                      <div className="flex flex-col gap-0.5">
                                                        <span className="text-sm">
                                                          {user.firstName}
                                                          {' '}
                                                          {user.lastName}
                                                        </span>
                                                        <span className="text-xs text-muted-foreground">
                                                          {user.primaryEmailAddress?.emailAddress}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </CommandItem>
                                                )
                                              ))}
                                            </CommandGroup>
                                          </Command>
                                        </PopoverContent>
                                      </Popover>
                                    </FormItem>
                                  )}
                                />
                                <div className="flex-1" />
                                <Button type="submit" size="sm">
                                  Save
                                </Button>
                                <Button
                                  type="button"
                                  variant="ghost"
                                  size="sm"
                                  onClick={() => setEditingTaskId(null)}
                                >
                                  Cancel
                                </Button>
                              </div>
                            </form>
                          </Form>
                        )}
                        {expandedTaskId === task.id && (
                          <div className="space-y-3 rounded-md bg-muted/50 p-3 pb-4">
                            <div className="space-y-3">
                              {task.chat_history.map((message) => (
                                <div key={message.id} className="flex items-start gap-2">
                                  <Avatar className="size-6 shrink-0 rounded-full">
                                    <AvatarImage
                                      src={message.user?.imageUrl}
                                      alt={`${message.user?.firstName} ${message.user?.lastName}`}
                                    />
                                    <AvatarFallback className="text-xs">
                                      {message.user?.firstName?.[0]}
                                      {message.user?.lastName?.[0]}
                                    </AvatarFallback>
                                  </Avatar>
                                  <div className="flex-1">
                                    <div className="flex items-center justify-between gap-2">
                                      <div className="flex items-center gap-2">
                                        <span className="text-sm font-medium">
                                          {message.user?.firstName}
                                          {' '}
                                          {message.user?.lastName}
                                        </span>
                                        <span className="text-xs text-muted-foreground">
                                          {format(new Date(message.timestamp), 'MMM d, h:mm a')}
                                        </span>
                                      </div>
                                      <div className="flex items-center gap-1 opacity-0 group-hover:opacity-100">
                                        <Button
                                          variant="ghost"
                                          size="icon"
                                          className="size-6"
                                          onClick={() => {
                                            setEditingMessageId(message.id);
                                            setEditedMessage(message.message);
                                          }}
                                        >
                                          <Pencil className="size-3" />
                                        </Button>
                                        <Button
                                          variant="ghost"
                                          size="icon"
                                          className="size-6 text-destructive hover:text-destructive"
                                          onClick={() => {
                                            const confirm = async () => new Promise<boolean>((resolve) => {
                                              toast.custom(() => (
                                                <div className="flex flex-col gap-2 rounded-lg border bg-card p-4 shadow-lg">
                                                  <p className="font-medium">Delete Message</p>
                                                  <p className="text-sm text-muted-foreground">Are you sure you want to delete this message?</p>
                                                  <div className="flex justify-end gap-2">
                                                    <Button
                                                      variant="ghost"
                                                      size="sm"
                                                      onClick={() => {
                                                        toast.dismiss();
                                                        resolve(false);
                                                      }}
                                                    >
                                                      Cancel
                                                    </Button>
                                                    <Button
                                                      variant="destructive"
                                                      size="sm"
                                                      onClick={() => {
                                                        toast.dismiss();
                                                        resolve(true);
                                                      }}
                                                    >
                                                      Delete
                                                    </Button>
                                                  </div>
                                                </div>
                                              ), { duration: Infinity });
                                            });

                                            void confirm().then((confirmed) => {
                                              if (confirmed) {
                                                deleteMessage(
                                                  {
                                                    taskId: task.id,
                                                    messageId: message.id,
                                                  },
                                                  {
                                                    onSuccess: () => {
                                                      toast.success('Message deleted');
                                                    },
                                                    onError: (error: Error) => {
                                                      toast.error(error.message);
                                                    },
                                                  },
                                                );
                                              }
                                            });
                                          }}
                                        >
                                          <Trash2 className="size-3" />
                                        </Button>
                                      </div>
                                    </div>
                                    {editingMessageId === message.id
                                      ? (
                                          <form
                                            onSubmit={(e) => {
                                              e.preventDefault();
                                              if (!editedMessage.trim())
                                                return;
                                              updateMessage(
                                                {
                                                  taskId: task.id,
                                                  messageId: message.id,
                                                  message: editedMessage.trim(),
                                                },
                                                {
                                                  onSuccess: () => {
                                                    setEditingMessageId(null);
                                                    setEditedMessage('');
                                                  },
                                                  onError: (error: Error) => {
                                                    toast.error(error.message);
                                                  },
                                                },
                                              );
                                            }}
                                            className="mt-0.5 flex items-center gap-2"
                                          >
                                            <input
                                              type="text"
                                              className="flex-1 rounded-md border bg-background px-3 py-1.5 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2"
                                              value={editedMessage}
                                              onChange={(e) => setEditedMessage(e.target.value)}
                                              autoFocus
                                            />
                                            <Button type="submit" size="sm">
                                              Save
                                            </Button>
                                            <Button
                                              type="button"
                                              variant="ghost"
                                              size="sm"
                                              onClick={() => {
                                                setEditingMessageId(null);
                                                setEditedMessage('');
                                              }}
                                            >
                                              Cancel
                                            </Button>
                                          </form>
                                        )
                                      : (
                                          <p className="mt-0.5 text-sm">{message.message}</p>
                                        )}
                                  </div>
                                </div>
                              ))}
                            </div>
                            <form
                              onSubmit={(e) => {
                                e.preventDefault();
                                if (!chatMessage.trim())
                                  return;

                                addMessage(
                                  {
                                    taskId: task.id,
                                    message: chatMessage.trim(),
                                  },
                                  {
                                    onSuccess: () => {
                                      setChatMessage('');
                                    },
                                    onError: (error: Error) => {
                                      toast.error(error.message);
                                    },
                                  },
                                );
                              }}
                              className="flex items-center gap-2 pt-2"
                            >
                              <input
                                type="text"
                                className="flex-1 rounded-md border bg-background px-3 py-1.5 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2"
                                placeholder="Type a message..."
                                value={chatMessage}
                                onChange={(e) => setChatMessage(e.target.value)}
                              />
                              <Button
                                type="submit"
                                size="sm"
                                disabled={!chatMessage.trim()}
                              >
                                Send
                              </Button>
                            </form>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })
              )}
        </div>
      </div>
    </div>
  );
}
