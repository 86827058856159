import isEmail from 'validator/lib/isEmail';
import { INTERNAL_DOMAINS } from '@/lib/globals';
import type { EmailName } from 'nylas';

/**
 * Extracts the domain from an email address
 * @param email - The email address to extract the domain from
 * @returns The domain part of the email address, or null if invalid
 */
function getEmailDomain(email: string): string | null {
  try {
    return isEmail(email) ? email.split('@')[1] : null;
  } catch {
    return null;
  }
}

/**
 * Checks if any participant has an external email domain
 * @param participants - Array of EmailName objects to check
 * @returns True if any participant has an external email domain
 */
export function hasExternalEmail(participants: EmailName[]): boolean {
  const internalDomains = INTERNAL_DOMAINS
    ? INTERNAL_DOMAINS.split(',').map((origin) => origin.trim())
    : [];

  return participants.some(({ email }) => {
    const domain = getEmailDomain(email);
    return domain && !internalDomains.includes(domain);
  });
}

/**
 * Type guard to validate if an unknown object is a valid EmailName
 */
export function isEmailName(obj: unknown): obj is EmailName {
  if (!obj || typeof obj !== 'object')
    return false;

  const candidate = obj as Partial<EmailName>;
  return typeof candidate.email === 'string'
    && (candidate.name === undefined || typeof candidate.name === 'string');
}
