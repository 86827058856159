import * as React from 'react';
import { IoIosStar, IoIosStarOutline } from 'react-icons/io';
import { MdOutlineReplyAll } from 'react-icons/md';
import { toast } from 'sonner';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { useDeal } from '@/contexts/deal-context';
import { useHub } from '@/contexts/hub-context';
import { useNylasGrants } from '@/hooks/use-nylas-grants';
import { useThreads } from '@/hooks/use-threads';
import { cn, truncateText } from '@/lib/utils';
import type { CommunicationDisplay } from '../types/communications';

type ThreadIconProps = {
  threadDisplay: CommunicationDisplay;
  isDealInbox: boolean;
  isTrackedThread: boolean;
};

function ThreadIcon({ threadDisplay, isDealInbox, isTrackedThread }: ThreadIconProps) {
  const { dealId } = useDeal();
  const { selectedInbox } = useHub();
  const { grantId } = useNylasGrants(selectedInbox);
  const { trackThread, untrackThread } = useThreads(dealId as string);

  const { sender, id: threadId } = threadDisplay;

  if (isDealInbox) {
    return (
      <Avatar className="size-8">
        {sender.avatar
          ? (
            <AvatarImage src={sender.avatar} alt={sender.name} />
          )
          : null}
        <AvatarFallback>{sender.name.charAt(0)}</AvatarFallback>
      </Avatar>
    );
  }

  return (
    <a
      role="button"
      className="flex size-8 items-center justify-center rounded-full transition-colors hover:bg-accent/50"
      onClick={async (e) => {
        e.stopPropagation();
        if (!grantId)
          return;

        // Truncate subject for toast message
        const truncatedSubject = truncateText(threadDisplay.emailSubject, 30);

        // Track or untrack thread
        try {
          if (isTrackedThread) {
            await untrackThread(threadId);
            toast.success(`"${truncatedSubject}" removed from deal`);
          } else {
            await trackThread({ threadId, grantId });
            toast.success(`"${truncatedSubject}" added to deal`);
          }
        } catch {
          toast.error(
            isTrackedThread
              ? `Failed to remove thread "${truncatedSubject}" from deal`
              : `Failed to add thread "${truncatedSubject}" to deal`,
          );
        }
      }}
    >
      {isTrackedThread
        ? (
          <IoIosStar
            className="size-6 text-ftld-secondary"
          />
        )
        : (
          <IoIosStarOutline className="size-6 text-black/50 hover:text-black/80" />
        )}
    </a>
  );
}

type ThreadMessageProps = {
  message: CommunicationDisplay;
  isSelected?: boolean;
  onClick?: (messageId: string) => void;
};

export function ThreadMessage({ message, isSelected, onClick }: ThreadMessageProps) {
  const { isTrackedThread, isDealInbox, replyThread, setReplyThread } = useHub();
  const { myGrantId, verifyGrantId } = useHub();

  /**
   * Helper to check if a thread is replyable based on grantId
   * For fTLD, only threads with myGrantId or verifyGrantId are replyable
   * @param grantId
   * @returns true if the thread is replyable, false otherwise
   */
  function isReplyableThread(grantId: string | undefined): boolean {
    return grantId === myGrantId || grantId === verifyGrantId;
  }

  return (
    <a
      role="button"
      onClick={(e) => {
        e.preventDefault();
        onClick?.(message.id);
      }}
      className={cn('group relative block w-full text-left rounded-lg peer hover:bg-accent', isSelected ? 'bg-accent' : '')}
    >
      <div className="flex gap-3 rounded-lg p-3">
        <ThreadIcon
          threadDisplay={message}
          isDealInbox={isDealInbox}
          isTrackedThread={isTrackedThread(message.id)}
        />
        <div className="flex-1">
          <div className="flex items-baseline justify-between">
            <div className="flex items-center gap-2">
              <div className="flex">
                <span className="pr-1 text-sm font-medium">
                  {message.sender.name || message.sender.email}
                  {message.participants && message.participants.length > 1 && ','}
                </span>
                {message.participants && message.participants.length > 1 && (
                  <span className="text-sm">
                    {message.participants[1].name || message.participants[1].email}
                    {message.participants.length > 2 && (
                      <>
                        , +
                        {message.participants.length - 2}
                        {' '}
                        more
                      </>
                    )}
                  </span>
                )}
              </div>
              {message.isExternal && (
                <span className="rounded-full bg-blue-100 px-2 py-0.5 text-xs text-blue-800">
                  External
                </span>
              )}
              {message.isEmail && (
                <span className="rounded-full bg-purple-100 px-2 py-0.5 text-xs text-purple-800">
                  Email
                </span>
              )}
            </div>
            <span className="text-xs text-muted-foreground">
              {new Date(message.timestamp).toLocaleTimeString('en-US', {
                hour: 'numeric',
                minute: '2-digit',
                hour12: true,
              })}
            </span>
          </div>
          {message.emailSubject && (
            <div className="text-sm font-medium">{message.emailSubject}</div>
          )}
          <div className="line-clamp-2 text-sm text-muted-foreground">
            {message.snippet}
          </div>
          {message.attachments && message.attachments.length > 0 && (
            <div className="mt-2 flex items-center gap-2">
              <span className="text-xs text-muted-foreground">
                {message.attachments.length}
                {' '}
                attachment
                {message.attachments.length === 1 ? '' : 's'}
              </span>
            </div>
          )}
        </div>
      </div>
      {isDealInbox && isReplyableThread(message.emailGrantId) && (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <a
                role="button"
                className={cn('invisible absolute right-3 top-3/4 -translate-y-1/2 rounded-full border bg-white/80 p-2 text-muted-foreground/80 hover:bg-white hover:text-muted-foreground group-hover:visible', replyThread?.thread_id === message.emailThreadId ? 'visible bg-ftld-secondary-200 border-ftld-secondary-50 hover:bg-ftld-secondary-300 hover:border-ftld-secondary-100' : '')}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  // Toggle reply thread
                  message.emailThreadId === replyThread?.thread_id
                    ? setReplyThread(undefined)
                    : setReplyThread(message.emailThreadId);
                }}
              >
                <MdOutlineReplyAll className="size-5" />
              </a>
            </TooltipTrigger>
            <TooltipContent>
              {
                replyThread?.thread_id === message.emailThreadId
                  ? 'Cancel reply'
                  : 'Reply to thread'
              }
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )}
    </a>
  );
}
