import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { X, GripVertical } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import type { SortableItemProps } from '../types';
import { FdicCheckComponent } from './fdic-check';
import { OFACCheckComponent } from './ofac-check';
import { SignableNoteComponent } from './signable-note';

export function SortableItem({
  id,
  item,
  onVerifyOFAC,
  onVerifyFDIC,
  onUpdateNoteContent,
  onSignNote,
  onDelete,
  isSearching,
  userId,
  items,
  setItems,
  deal,
}: SortableItemProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  return (
    <div ref={setNodeRef} style={style}>
      <Card className="mb-4">
        <CardHeader className="flex flex-row items-center gap-4">
          <div {...attributes} {...listeners}>
            <GripVertical className="size-4 cursor-move text-muted-foreground" />
          </div>
          <div className="flex flex-1 items-center gap-2">
            <CardTitle className="text-base">
              {item.type === 'ofac'
                ? `OFAC Check - ${item.entityName || 'Unnamed Entity'}`
                : (item.type === 'fdic'
                    ? `FDIC Check - ${item.entityName || 'Unnamed Entity'}`
                    : item.title)}
            </CardTitle>
            <Button
              variant="ghost"
              size="sm"
              className="h-6 px-2 text-muted-foreground hover:text-destructive"
              onClick={() => onDelete(id)}
            >
              <X className="size-3" />
            </Button>
          </div>
        </CardHeader>

        <CardContent>
          {item.type === 'ofac'
            ? (
                <OFACCheckComponent
                  item={item}
                  onVerify={onVerifyOFAC}
                  onSign={onSignNote}
                  isSearching={isSearching}
                  items={items}
                  setItems={setItems}
                  userId={userId}
                />
              )
            : (item.type === 'fdic'
                ? (
                    <FdicCheckComponent
                      item={item}
                      onVerify={onVerifyFDIC}
                      onSign={onSignNote}
                      isSearching={isSearching}
                      items={items}
                      setItems={setItems}
                      userId={userId}
                    />
                  )
                : (
                    <SignableNoteComponent
                      item={item}
                      onUpdateContent={onUpdateNoteContent}
                      onSign={onSignNote}
                      userId={userId}
                      deal={deal}
                    />
                  ))}
        </CardContent>
      </Card>
    </div>
  );
}
