import { Separator } from '@/components/ui/separator';

type DateSeparatorProps = {
  timestamp: string | number | Date;
};

export function DateSeparator({ timestamp }: DateSeparatorProps) {
  return (
    <div className="mb-4 flex items-center gap-2">
      <Separator className="flex-1" />
      <span className="text-xs font-medium text-muted-foreground">
        {new Date(timestamp).toLocaleDateString()}
      </span>
      <Separator className="flex-1" />
    </div>
  );
}
