import type { User } from '@clerk/backend';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { useAxios } from './use-axios';
import { useAllClerkUsers } from './use-clerk-user';

const API_BASE_URL = '/tasks';

export const TASKS_QUERY_KEY = ['tasks'];
export const TASK_MESSAGES_QUERY_KEY = 'messages';

interface ApiError {
  error?: {
    message?: string;
    status?: number;
  };
  details?: Array<{ message: string }>;
}

export interface TaskMessage {
  id: string;
  timestamp: string;
  user_id: string;
  message: string;
  user?: Partial<User>; // Enriched with Clerk user data
}

export interface Task {
  id: string;
  created_at: string;
  updated_at: string;
  created_by: string;
  deal_id: string;
  task: string;
  due: string;
  done: string | null;
  assigned_to: string[];
  chat_history: TaskMessage[];
  // Enriched data
  created_by_user?: Partial<User>;
  assigned_to_users?: Partial<User>[];
}

export interface CreateTaskInput {
  deal_id: string;
  task: string;
  due: string;
  assigned_to: string[];
  chat_history?: TaskMessage[];
}

export interface UpdateTaskInput {
  task?: string;
  due?: string;
  done?: string | null;
  assigned_to?: string[];
}

function getErrorMessage(error: unknown): string {
  if (isAxiosError<ApiError>(error)) {
    // Handle validation errors
    if (error.response?.data?.details) {
      return 'Please check your input and try again';
    }

    // Handle specific error codes
    if (error.response?.status === 500) {
      return 'An internal server error occurred. Please try again later';
    }

    if (error.response?.status === 401 || error.response?.status === 403) {
      return 'You do not have permission to perform this action';
    }

    if (error.response?.status === 404) {
      return 'The requested resource was not found';
    }

    // Handle network errors
    if (error.message === 'Network Error') {
      return 'Unable to connect to the server. Please check your internet connection';
    }
  }

  return 'An unexpected error occurred. Please try again';
}

// Helper function to enrich task data with Clerk user information
function enrichTaskWithUsers(task: Task, users?: Partial<User>[]) {
  if (!users)
    return task;

  const userMap = new Map(users.map((user) => [user.id, user]));

  return {
    ...task,
    created_by_user: userMap.get(task.created_by),
    assigned_to_users: task.assigned_to.map((id) => userMap.get(id)).filter(Boolean),
    chat_history: task.chat_history.map((msg) => ({
      ...msg,
      user: userMap.get(msg.user_id),
    })),
  };
}

// List all tasks
export function useTasks() {
  const axios = useAxios();
  const { data: users } = useAllClerkUsers();

  return useQuery({
    queryKey: [...TASKS_QUERY_KEY, 'with-users', users?.length],
    queryFn: async () => {
      try {
        const { data } = await axios.get<Task[]>(API_BASE_URL);
        return data.map((task) => enrichTaskWithUsers(task, users));
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    enabled: !!users,
  });
}

// Get assigned tasks
export function useAssignedTasks() {
  const axios = useAxios();
  const { data: users } = useAllClerkUsers();

  return useQuery({
    queryKey: [...TASKS_QUERY_KEY, 'assigned', 'with-users', users?.length],
    queryFn: async () => {
      try {
        const { data } = await axios.get<Task[]>(`${API_BASE_URL}/assigned`);
        return data.map((task) => enrichTaskWithUsers(task, users));
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    enabled: !!users,
  });
}

// Get a single task by ID
export function useTask(id: string) {
  const axios = useAxios();
  const { data: users } = useAllClerkUsers();

  return useQuery({
    queryKey: [...TASKS_QUERY_KEY, id, 'with-users', users?.length],
    queryFn: async () => {
      try {
        const { data } = await axios.get<Task>(`${API_BASE_URL}/${id}`);
        return enrichTaskWithUsers(data, users);
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    enabled: !!id && !!users,
  });
}

// Create a new task
export function useCreateTask() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (input: CreateTaskInput) => {
      try {
        const { data } = await axios.post<Task>(API_BASE_URL, input);
        return data;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: TASKS_QUERY_KEY }),
        queryClient.invalidateQueries({ queryKey: [...TASKS_QUERY_KEY, 'assigned'] }),
      ]);
    },
  });
}

// Update a task
export function useUpdateTask() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, ...input }: { id: string } & UpdateTaskInput) => {
      try {
        const { data } = await axios.put<Task>(`${API_BASE_URL}/${id}`, input);
        return data;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    onSuccess: async (_, { id }) => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: [...TASKS_QUERY_KEY, id] }),
        queryClient.invalidateQueries({ queryKey: TASKS_QUERY_KEY }),
        queryClient.invalidateQueries({ queryKey: [...TASKS_QUERY_KEY, 'assigned'] }),
      ]);
    },
  });
}

// Add a chat message
export function useAddTaskMessage() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ taskId, message }: { taskId: string; message: string }) => {
      try {
        const { data } = await axios.post<TaskMessage>(
          `${API_BASE_URL}/${taskId}/messages`,
          { message },
        );
        return data;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    onSuccess: async (_, { taskId }) => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: [...TASKS_QUERY_KEY, taskId] }),
        queryClient.invalidateQueries({ queryKey: TASKS_QUERY_KEY }),
        queryClient.invalidateQueries({ queryKey: [...TASKS_QUERY_KEY, 'assigned'] }),
      ]);
    },
  });
}

// Update a chat message
export function useUpdateTaskMessage() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ taskId, messageId, message }: { taskId: string; messageId: string; message: string }) => {
      try {
        const { data } = await axios.put<TaskMessage>(
          `${API_BASE_URL}/${taskId}/messages/${messageId}`,
          { message },
        );
        return data;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    onSuccess: async (_, { taskId }) => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: [...TASKS_QUERY_KEY, taskId] }),
        queryClient.invalidateQueries({ queryKey: TASKS_QUERY_KEY }),
        queryClient.invalidateQueries({ queryKey: [...TASKS_QUERY_KEY, 'assigned'] }),
      ]);
    },
  });
}

// Delete a chat message
export function useDeleteTaskMessage() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ taskId, messageId }: { taskId: string; messageId: string }) => {
      try {
        await axios.delete(`${API_BASE_URL}/${taskId}/messages/${messageId}`);
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    onSuccess: async (_, { taskId }) => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: [...TASKS_QUERY_KEY, taskId] }),
        queryClient.invalidateQueries({ queryKey: TASKS_QUERY_KEY }),
        queryClient.invalidateQueries({ queryKey: [...TASKS_QUERY_KEY, 'assigned'] }),
      ]);
    },
  });
}

// Delete a task
export function useDeleteTask() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (taskId: string) => {
      try {
        await axios.delete(`${API_BASE_URL}/${taskId}`);
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: TASKS_QUERY_KEY }),
        queryClient.invalidateQueries({ queryKey: [...TASKS_QUERY_KEY, 'assigned'] }),
      ]);
    },
  });
}
