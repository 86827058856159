import { useEffect, useState } from 'react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { cn } from '@/lib/utils';

interface AccountSwitcherProps {
  isCollapsed: boolean;
  accounts: {
    label: string;
    email: string;
    grantId: string;
    icon: React.ReactNode;
  }[];
  onChange?: (data: { email: string; grantId: string }) => void;
  value?: string;
}

export function AccountSwitcher({
  isCollapsed,
  accounts,
  onChange,
  value,
}: AccountSwitcherProps) {
  const [selectedAccount, setSelectedAccount] = useState<string>(value || '');

  useEffect(() => {
    if (!selectedAccount && accounts.length > 0) {
      setSelectedAccount(accounts[0].grantId);
      onChange?.({ email: accounts[0].email, grantId: accounts[0].grantId });
    }
  }, [accounts, selectedAccount, onChange]);

  const handleChange = (grantId: string) => {
    setSelectedAccount(grantId);
    const selectedAccount = accounts.find((account) => account.grantId === grantId);
    if (selectedAccount) {
      onChange?.({ email: selectedAccount.email, grantId: selectedAccount.grantId });
    }
  };

  return (
    <Select value={selectedAccount} onValueChange={handleChange}>
      <SelectTrigger
        className={cn(
          'flex items-center gap-2 [&>span]:line-clamp-1 [&>span]:flex [&>span]:w-full [&>span]:items-center [&>span]:gap-1 [&>span]:truncate [&_svg]:h-4 [&_svg]:w-4 [&_svg]:shrink-0',
          isCollapsed
          && 'flex h-9 w-9 shrink-0 items-center justify-center p-0 [&>span]:w-auto [&>svg]:hidden',
        )}
        aria-label="Select account"
      >
        <SelectValue placeholder="Select an account">
          {accounts.find((account) => account.grantId === selectedAccount)?.icon}
          <span className={cn('ml-2', isCollapsed && 'hidden')}>
            {
              accounts.find((account) => account.grantId === selectedAccount)
                ?.label
            }
          </span>
        </SelectValue>
      </SelectTrigger>
      <SelectContent>
        {accounts.map((account) => (
          <SelectItem key={account.grantId} value={account.grantId}>
            <div className="flex items-center gap-3 [&_svg]:size-4 [&_svg]:shrink-0 [&_svg]:text-foreground">
              {account.icon}
              {account.email}
            </div>
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}
