import { createContext, useContext, useMemo, useState, type ReactNode } from 'react';
import type { ThreadDB } from '@/types/thread';

type HubContextType = {
  selectedInbox: string | undefined;
  setSelectedInbox: (inbox: string) => void;
  selectedFolderId: string | undefined;
  setSelectedFolderId: (folderId: string | undefined) => void;
  dealThreads: ThreadDB[];
  isDealInbox: boolean;
  myGrantId: string | undefined;
  myGrantEmail: string | undefined;
  verifyGrantId: string | undefined;
  verifyUserId: string | undefined;
  sendAsGrantId: string | undefined;
  setSendAsGrantId: (grantId: string | undefined) => void;
  sendAsEmailAddress: string | undefined;
  setSendAsEmailAddress: (email: string | undefined) => void;
  isTrackedThread: (threadId: string) => boolean;
  replyThread: ThreadDB | undefined;
  setReplyThread: (threadId: string | undefined) => void;
};

const HubContext = createContext<HubContextType>({
  selectedInbox: undefined,
  setSelectedInbox: () => { },
  selectedFolderId: undefined,
  setSelectedFolderId: () => { },
  dealThreads: [],
  isDealInbox: false,
  myGrantId: undefined,
  myGrantEmail: undefined,
  verifyGrantId: undefined,
  verifyUserId: undefined,
  sendAsGrantId: undefined,
  setSendAsGrantId: () => { },
  sendAsEmailAddress: undefined,
  setSendAsEmailAddress: () => { },
  isTrackedThread: () => false,
  replyThread: undefined,
  setReplyThread: () => { },
});

type HubProviderProps = {
  children: ReactNode;
  selectedInbox: string | undefined;
  setSelectedInbox: (inbox: string) => void;
  dealThreads: ThreadDB[];
  myGrantId: string | undefined;
  myGrantEmail: string | undefined;
  verifyGrantId: string | undefined;
  verifyUserId: string | undefined;
  selectedFolderId: string | undefined;
  setSelectedFolderId: (folderId: string | undefined) => void;
};

export function HubProvider({
  children,
  selectedInbox,
  setSelectedInbox,
  dealThreads,
  myGrantId,
  myGrantEmail,
  verifyGrantId,
  verifyUserId,
  selectedFolderId,
  setSelectedFolderId,
}: HubProviderProps) {
  const [sendAsGrantId, setSendAsGrantId] = useState<string | undefined>();
  const [sendAsEmailAddress, setSendAsEmailAddress] = useState<string | undefined>();
  const [replyThread, setReplyThread] = useState<ThreadDB | undefined>();

  // Checks if a thread exists in dealThreads
  const isTrackedThread = useMemo(
    () => (threadId: string): boolean =>
      dealThreads.some((thread) => thread.thread_id === threadId),
    [dealThreads],
  );

  // Checks if the selected inbox is 'deal'
  const isDealInbox = selectedInbox === 'deal';

  // useMemo is used to prevent the value from being recalculated on every render
  const value = useMemo(
    () => ({
      selectedInbox,
      setSelectedInbox,
      selectedFolderId,
      setSelectedFolderId,
      dealThreads,
      isTrackedThread,
      isDealInbox,
      myGrantId,
      myGrantEmail,
      verifyGrantId,
      verifyUserId,
      replyThread,
      sendAsGrantId,
      setSendAsGrantId,
      sendAsEmailAddress,
      setSendAsEmailAddress,
      // Sets the reply thread by finding the matching ThreadDB in dealThreads
      // If threadId is undefined, clears the reply thread
      // If threadId is provided but not found in dealThreads, no thread will be set
      setReplyThread: (threadId: string | undefined) => {
        if (!threadId) {
          console.log(`no threadId provided, clearing replyThread`);
          setReplyThread(undefined);
          return;
        }
        // Find the thread in dealThreads
        const thread = dealThreads.find((t) => t.thread_id === threadId);
        if (!thread) {
          console.log(`threadId ${threadId} not found in dealThreads`);
          return;
        }
        console.log(`setting replyThread to:`, thread);
        setReplyThread(thread);
      },
    }),
    [selectedInbox, setSelectedInbox, selectedFolderId, setSelectedFolderId, dealThreads, myGrantId, myGrantEmail, verifyGrantId, verifyUserId, isTrackedThread, isDealInbox, replyThread, sendAsGrantId, sendAsEmailAddress],
  );

  return (
    <HubContext.Provider value={value}>
      {children}
    </HubContext.Provider>
  );
}

export function useHub(): HubContextType {
  const context = useContext(HubContext);
  if (context === undefined) {
    throw new Error('useHub must be used within a HubProvider');
  }
  return context;
}
