import { useRef, useState } from 'react';
import type { GridApi, RowDoubleClickedEvent } from '@ag-grid-community/core';
import { RotateCcw } from 'lucide-react';
import { toast } from 'sonner';
import AGDataGrid from '@/components/ag-data-grid';
import Container from '@/components/container';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { useGrid } from '@/contexts/grid-context';
import {
  useContacts,
  useCreateContact,
  useUpdateContact,
  useDeleteContact,
} from '@/hooks/use-contacts';
import type { Contact } from '@/hooks/use-contacts';
import type { ContactFormData } from './components/contacts-sheet';
import { contactColumnDefs } from './components/contacts-grid-columns';
import ContactSheet from './components/contacts-sheet';

function ContactsPage() {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRows, setSelectedRows] = useState<Contact[]>([]);
  const [editingContact, setEditingContact] = useState<Contact | null>(null);
  const [isSheetOpen, setIsSheetOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const gridApiRef = useRef<GridApi<Contact> | null>(null);
  const { registerGridApi } = useGrid();

  const { data: contacts, isLoading } = useContacts();
  const createMutation = useCreateContact();
  const updateMutation = useUpdateContact();
  const deleteMutation = useDeleteContact();

  const handleCreateSubmit = async (data: ContactFormData) => {
    try {
      await createMutation.mutateAsync(data);
      toast.success('Contact created successfully');
      setIsSheetOpen(false);
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      }
      throw error;
    }
  };

  const handleUpdateSubmit = async (data: ContactFormData) => {
    if (!editingContact)
      return;

    try {
      await updateMutation.mutateAsync({ id: editingContact.id, ...data });
      toast.success('Contact updated successfully');
      setEditingContact(null);
      setIsSheetOpen(false);
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      }
      throw error;
    }
  };

  const handleDelete = async () => {
    if (selectedRows.length === 0)
      return;

    try {
      await Promise.all(selectedRows.map(async (row) => deleteMutation.mutateAsync(row.id)));
      toast.success('Selected contacts deleted successfully');
      setSelectedRows([]);
      setIsDeleteDialogOpen(false);
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      }
    }
  };

  const handleRowDoubleClick = (event: RowDoubleClickedEvent<Contact>) => {
    if (event.data) {
      setEditingContact(event.data);
      setIsSheetOpen(true);
    }
  };

  const handleSelectionChanged = (event: { api: { getSelectedRows: () => Contact[] } }) => {
    setSelectedRows(event.api.getSelectedRows());
  };

  const handleSheetOpenChange = (open: boolean) => {
    setIsSheetOpen(open);
    if (!open) {
      setEditingContact(null);
      // Deselect rows when closing the sheet
      if (gridApiRef.current) {
        gridApiRef.current.deselectAll();
      }
    }
  };

  const handleGridReady = (params: { api: GridApi<Contact> }) => {
    gridApiRef.current = params.api;
    registerGridApi(params.api);
  };

  return (
    <Container className="pb-12">
      <div className="mb-3 flex justify-between">
        <div>
          <h1 className="text-xl font-bold">Contacts</h1>
        </div>
        <div className="flex items-center gap-3">
          {selectedRows.length > 0 && (
            <Button
              variant="destructive"
              onClick={() => setIsDeleteDialogOpen(true)}
            >
              Delete Selected
            </Button>
          )}
          <Button onClick={() => setIsSheetOpen(true)}>
            Create Contact
          </Button>
        </div>
      </div>
      <div className="mb-6">
        <Input
          type="search"
          placeholder="Search contacts..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
      <div className="flex flex-col gap-6">
        <AGDataGrid
          columnDefs={contactColumnDefs}
          rowData={contacts}
          quickFilterText={searchQuery}
          rowSelection={{ mode: 'multiRow' }}
          onRowDoubleClicked={handleRowDoubleClick}
          onSelectionChanged={handleSelectionChanged}
          onGridReady={handleGridReady}
          overlayLoadingTemplate={
            isLoading ? '<span class="ag-overlay-loading-center">Loading contacts...</span>' : undefined
          }
          overlayNoRowsTemplate="No contacts found"
        />
      </div>

      <ContactSheet
        open={isSheetOpen}
        onOpenChange={handleSheetOpenChange}
        initialData={editingContact || undefined}
        onSubmit={editingContact ? handleUpdateSubmit : handleCreateSubmit}
      />

      <AlertDialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This will permanently delete the selected contacts. This action cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={handleDelete}>Delete</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Container>
  );
}

export default ContactsPage;
