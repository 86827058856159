import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import useAxios, { isAxiosError } from '@/hooks/use-axios';

type UserTemplate = {
  id: string;
  name: string;
  template: string;
  created_at: string;
  updated_at: string;
  created_by: string;
};

type CreateTemplateRequest = {
  name: string;
  template: string;
};

type UpdateTemplateRequest = {
  id: string;
  name?: string;
  template?: string;
};

/** React Query cache keys for user templates */
const USER_TEMPLATES_QUERY_KEYS = {
  /** Base key for all user template queries */
  all: ['user-templates'] as const,
  /** Key for getting a template by name */
  byName: (name: string, userId?: string) => [...USER_TEMPLATES_QUERY_KEYS.all, name, userId] as const,
} as const;

type UseUserTemplatesOptions = {
  /** Name of template to fetch */
  name?: string;
  /** Optional user ID to fetch template for specific user */
  userId?: string;
};

/**
 * Hook for managing user templates
 *
 * Provides functionality to:
 * - Fetch a template by name (optionally for a specific user)
 * - Create new templates
 * - Update existing templates
 * - Delete templates
 * - Track loading and error states
 *
 * @param options - Hook options
 * @param options.name - Optional name of template to fetch
 * @param options.userId - Optional user ID to fetch template for specific user
 * @returns Object containing:
 *  - template: The requested template if name was provided
 *  - isLoading: Boolean indicating if template is being fetched
 *  - error: Any error that occurred during fetching or mutations
 *  - createTemplate: Function to create a new template
 *  - updateTemplate: Function to update an existing template
 *  - deleteTemplate: Function to delete a template
 *  - isCreating: Boolean indicating if a template is being created
 *  - isUpdating: Boolean indicating if a template is being updated
 *  - isDeleting: Boolean indicating if a template is being deleted
 */
export function useUserTemplates({ name, userId }: UseUserTemplatesOptions = {}) {
  const axios = useAxios();
  const queryClient = useQueryClient();

  // Fetch template by name if provided
  const {
    data: template,
    isLoading,
    error: queryError,
  } = useQuery({
    queryKey: USER_TEMPLATES_QUERY_KEYS.byName(name || '', userId),
    queryFn: async () => {
      if (!name)
        return null;

      try {
        const { data } = await axios.get<UserTemplate>(`/user-templates/${name}`, {
          params: userId ? { userId } : undefined,
        });
        return data;
      } catch (error) {
        // Return null for 404s (template doesn't exist)
        if (isAxiosError(error) && error.response?.status === 404) {
          return null;
        }
        throw error;
      }
    },
    enabled: !!name,
    retry: false,
  });

  // Create a new template
  const { mutateAsync: createTemplate, isPending: isCreating } = useMutation({
    mutationFn: async (template: CreateTemplateRequest) => {
      const { data } = await axios.post<UserTemplate>('/user-templates', template);
      return data;
    },
    onMutate: async (newTemplate) => {
      await queryClient.cancelQueries({ queryKey: USER_TEMPLATES_QUERY_KEYS.all });
      const previousTemplates = queryClient.getQueryData<UserTemplate[]>(USER_TEMPLATES_QUERY_KEYS.all);

      if (previousTemplates) {
        // Add template to cache with temporary ID
        queryClient.setQueryData<UserTemplate[]>(
          USER_TEMPLATES_QUERY_KEYS.all,
          [...previousTemplates, {
            id: `temp-${Date.now()}`,
            name: newTemplate.name,
            template: newTemplate.template,
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString(),
            created_by: 'temp', // Will be replaced with actual value after server response
          }],
        );
      }

      return { previousTemplates };
    },
    onError: (err, variables, context) => {
      if (context?.previousTemplates) {
        queryClient.setQueryData(USER_TEMPLATES_QUERY_KEYS.all, context.previousTemplates);
      }
      toast.error('Failed to create template', {
        description: err instanceof Error ? err.message : 'An unknown error occurred',
      });
      throw err;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: USER_TEMPLATES_QUERY_KEYS.all,
      });
    },
  });

  // Update an existing template
  const { mutateAsync: updateTemplate, isPending: isUpdating } = useMutation({
    mutationFn: async ({ id, ...template }: UpdateTemplateRequest) => {
      const { data } = await axios.put<UserTemplate>(`/user-templates/${id}`, template);
      return data;
    },
    onMutate: async ({ id, ...updates }) => {
      await queryClient.cancelQueries({ queryKey: USER_TEMPLATES_QUERY_KEYS.all });
      const previousTemplates = queryClient.getQueryData<UserTemplate[]>(USER_TEMPLATES_QUERY_KEYS.all);

      if (previousTemplates) {
        queryClient.setQueryData<UserTemplate[]>(
          USER_TEMPLATES_QUERY_KEYS.all,
          previousTemplates.map((template) =>
            template.id === id
              ? { ...template, ...updates, updated_at: new Date().toISOString() }
              : template,
          ),
        );
      }

      return { previousTemplates };
    },
    onError: (err, variables, context) => {
      if (context?.previousTemplates) {
        queryClient.setQueryData(USER_TEMPLATES_QUERY_KEYS.all, context.previousTemplates);
      }
      toast.error('Failed to update template', {
        description: err instanceof Error ? err.message : 'An unknown error occurred',
      });
      throw err;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: USER_TEMPLATES_QUERY_KEYS.all,
      });
    },
  });

  // Delete a template
  const { mutateAsync: deleteTemplate, isPending: isDeleting } = useMutation({
    mutationFn: async (id: string) => {
      const { data } = await axios.delete(`/user-templates/${id}`);
      return data;
    },
    onMutate: async (id) => {
      await queryClient.cancelQueries({ queryKey: USER_TEMPLATES_QUERY_KEYS.all });
      const previousTemplates = queryClient.getQueryData<UserTemplate[]>(USER_TEMPLATES_QUERY_KEYS.all);

      if (previousTemplates) {
        queryClient.setQueryData<UserTemplate[]>(
          USER_TEMPLATES_QUERY_KEYS.all,
          previousTemplates.filter((template) => template.id !== id),
        );
      }

      return { previousTemplates };
    },
    onError: (err, variables, context) => {
      if (context?.previousTemplates) {
        queryClient.setQueryData(USER_TEMPLATES_QUERY_KEYS.all, context.previousTemplates);
      }
      toast.error('Failed to delete template', {
        description: err instanceof Error ? err.message : 'An unknown error occurred',
      });
      throw err;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: USER_TEMPLATES_QUERY_KEYS.all,
      });
    },
  });

  // Combine query and mutation errors
  const error = queryError || undefined;

  return {
    template,
    isLoading,
    error,
    createTemplate,
    updateTemplate,
    deleteTemplate,
    isCreating,
    isUpdating,
    isDeleting,
  };
}
