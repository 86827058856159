import { useMemo, useState } from 'react';
import { format } from 'date-fns';
import { Calendar, Users, RotateCcw, Mail } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { ScrollArea } from '@/components/ui/scroll-area';
import { usePartners } from '@/hooks/use-partners';
import type { Partner } from '@/types/partner';

export function PartnersPanel() {
  const [searchQuery, setSearchQuery] = useState('');
  const { data: partners, isLoading } = usePartners();

  const filteredPartners = useMemo(() => {
    if (!partners)
      return [];
    if (!searchQuery)
      return partners;

    const query = searchQuery.toLowerCase();
    return partners.filter((partner) => {
      return (
        partner.name.toLowerCase().includes(query)
        || partner.primary_contact.toLowerCase().includes(query)
        || partner.email.toLowerCase().includes(query)
      );
    });
  }, [partners, searchQuery]);

  return (
    <div className="flex h-full max-h-[600px] flex-col overflow-hidden">
      <div className="flex items-center gap-3 border-b p-4">
        <div className="flex-1">
          <Input
            type="search"
            placeholder="Search partners..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <Button
          variant="outline"
          onClick={() => setSearchQuery('')}
          className="gap-2"
        >
          <RotateCcw className="size-4" />
          Clear
        </Button>
      </div>
      <ScrollArea className="h-[calc(600px-56px)]">
        <div className="p-4">
          {isLoading
            ? (
                <div className="py-6 text-center text-sm text-muted-foreground">
                  Loading partners...
                </div>
              )
            : (filteredPartners.length === 0
                ? (
                    <div className="py-6 text-center text-sm text-muted-foreground">
                      No partners found
                    </div>
                  )
                : (
                    filteredPartners.map((partner) => (
                      <div
                        key={partner.id}
                        className="mb-4 rounded-lg border bg-card p-4 shadow-sm transition-colors hover:bg-accent hover:text-accent-foreground"
                      >
                        <div className="min-w-0 space-y-3">
                          <div className="flex items-start justify-between gap-4">
                            <div className="min-w-0 flex-1">
                              <div className="flex items-center gap-2">
                                <Users className="size-4 text-muted-foreground" />
                                <span className="text-sm font-medium">
                                  {partner.name}
                                </span>
                              </div>
                              <div className="mt-1 flex items-center gap-2 text-sm text-muted-foreground">
                                <span>{partner.primary_contact}</span>
                              </div>
                            </div>
                            <div className="flex items-center gap-1.5 text-xs text-muted-foreground">
                              <Calendar className="size-3" />
                              <span>
                                {format(new Date(partner.updated_at), 'MMM d, yyyy')}
                              </span>
                            </div>
                          </div>
                          <div className="border-t pt-2">
                            <a
                              href={`mailto:${partner.email}`}
                              className="flex items-center gap-2 text-sm text-primary hover:underline"
                            >
                              <Mail className="size-3" />
                              {partner.email}
                            </a>
                          </div>
                        </div>
                      </div>
                    ))
                  ))}
        </div>
      </ScrollArea>
    </div>
  );
}
