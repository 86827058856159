import { useMutation } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { useAxios } from './use-axios';

const API_BASE_URL = '/annual-verification-batches';

interface ApiError {
  error?: {
    message?: string;
    status?: number;
  };
  details?: Array<{ message: string }>;
}

export type BatchFilters = {
  // Positive filters (include if matching)
  regions?: string[]; // US, EU-EEA, ASIA, INT, INT excluding ASIA, INT excluding EU-EEA
  registrars?: string[]; // Filter by most recent registrar
  emails?: string[]; // Match against both registrant and verification contact emails
  names?: string[]; // Match against both registrant and verification contact real names
  licenses?: Array<{
    authority: string;
    value: string;
  }>;
  domains?: string[]; // Match against domain names
  organizations?: string[]; // Match against both registrant and verification contact org names

  // Negative filters (exclude if matching)
  exclude_regions?: string[]; // Exclude domains in these regions
  exclude_registrars?: string[]; // Exclude domains with these registrars
  exclude_emails?: string[]; // Exclude domains with matching contact emails
  exclude_names?: string[]; // Exclude domains with matching contact names
  exclude_licenses?: Array<{
    authority: string;
    value: string;
  }>;
  exclude_domains?: string[]; // Exclude matching domain names
  exclude_organizations?: string[]; // Exclude domains with matching org names
  skip_rdds_check?: boolean; // Skip RDDS check for domains
  skip_noavsend?: boolean; // Skip NOAVSEND check for domains
};

export type BatchCriteria = 'registrant_email' | 'license' | 'registrant_org_name';

export interface CalculateBatchesRequest {
  filters: BatchFilters;
  batch_by: BatchCriteria; // Required batching criteria
  limit?: number; // Optional limit on number of batches
}

export interface BatchResult {
  criteria: string;
  criteria_type: BatchCriteria;
  domains: string[];
  oldest_verification: string;
}

export interface CalculateBatchesResponse {
  success: boolean;
  batches: BatchResult[];
  total_domains: number;
  filtered_domains: number;
  batch_count: number;
}

function getErrorMessage(error: unknown): string {
  if (isAxiosError<ApiError>(error)) {
    // Handle validation errors
    if (error.response?.data?.details) {
      return 'Please check your input and try again';
    }

    // Handle specific error codes
    if (error.response?.status === 500) {
      return 'An internal server error occurred. Please try again later';
    }

    if (error.response?.status === 401 || error.response?.status === 403) {
      return 'You do not have permission to perform this action';
    }

    if (error.response?.status === 404) {
      return 'The requested resource was not found';
    }

    // Handle network errors
    if (error.message === 'Network Error') {
      return 'Unable to connect to the server. Please check your internet connection';
    }
  }

  return 'An unexpected error occurred. Please try again';
}

/**
 * Hook for calculating annual verification batches based on filters and criteria
 *
 * @returns Mutation for calculating batches with the following parameters:
 * @param filters - Filters to apply when selecting domains for batching
 * @param batch_by - Criteria to use for grouping domains into batches
 * @param limit - Optional maximum number of batches to return
 *
 * @example
 * ```tsx
 * const { calculateBatches, isCalculating, error } = useCalculateVerificationBatches();
 *
 * // Calculate batches
 * const handleCalculate = async () => {
 *   try {
 *     const result = await calculateBatches({
 *       filters: {
 *         regions: ['US'],
 *         registrars: ['Example Registrar']
 *       },
 *       batch_by: 'registrant_email',
 *       limit: 10
 *     });
 *     console.log(result.batches);
 *   } catch (error) {
 *     console.error('Failed to calculate batches:', error);
 *   }
 * };
 * ```
 */
export function useCalculateVerificationBatches() {
  const axios = useAxios();

  const mutation = useMutation<CalculateBatchesResponse, Error, CalculateBatchesRequest>({
    mutationFn: async (request) => {
      try {
        const { data } = await axios.post<CalculateBatchesResponse>(API_BASE_URL, request);
        if (!data.success) {
          throw new Error('Failed to calculate verification batches');
        }
        return data;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
  });

  return {
    calculateBatches: mutation.mutate,
    calculateBatchesAsync: mutation.mutateAsync,
    isCalculating: mutation.isPending,
    error: mutation.error,
    data: mutation.data,
  };
}
