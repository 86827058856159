function HomePage() {
  return (
    <div className="flex-1">
      <div className="container mx-auto">
        <div className="relative flex h-[400px] items-center">
          {/* Yellow circle background */}
          <div
            className="absolute -right-1/3 top-1/2 size-[800px] -translate-y-1/2 rounded-full bg-[#ffc745]"
            aria-hidden="true"
          />
          {/* Content */}
          <div className="relative z-10 max-w-2xl p-10">
            <h1 className="mb-4 font-serif text-5xl font-semibold">What is fTLD?</h1>
            <p className="text-lg font-light leading-relaxed">
              fTLD Registry Services, LLC (fTLD) is the domain registry operator for the .Bank and .Insurance
              generic Top-Level Domains (gTLDs). The company was formed by banks, insurance companies and
              financial services trade associations to operate these gTLDs as trusted, verified and more
              secure spaces on the internet for the global banking and insurance communities and those
              that serve them.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
