import { createContext, useContext, useMemo, type ReactNode } from 'react';

type DealContextType = {
  dealId: string | undefined;
};

const DealContext = createContext<DealContextType>({ dealId: undefined });

export function DealProvider({
  children,
  dealId,
}: {
  children: ReactNode;
  dealId: string | undefined;
}) {

  // useMemo is used to prevent the value from being recalculated on every render
  const value = useMemo(() => ({ dealId }), [dealId]);

  return (
    <DealContext.Provider value={value}>
      {children}
    </DealContext.Provider>
  );
}

export function useDeal() {
  const context = useContext(DealContext);
  if (context === undefined) {
    throw new Error('useDeal must be used within a DealProvider');
  }
  return context;
}
