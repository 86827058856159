import { useEffect, useState } from 'react';
import { WebhookTriggers } from '@/types/nylas';
import { Sheet, SheetContent, SheetHeader, SheetTitle } from '@/components/ui/sheet';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
// import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { useCreateNylasWebhook, useUpdateNylasWebhook, type Webhook } from '@/hooks/use-nylas-webhooks';
// import { useNylasGrants } from '@/hooks/use-nylas-grants';
import MultipleSelector, { Option } from '@/components/ui/multiple-selector';
import { getPublicAPIBaseUrl } from '@/lib/globals';

type CreateWebhookSheetProps = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  webhook?: Webhook | null;
};

export default function WebhookSheetEditor({
  open,
  onOpenChange,
  webhook,
}: CreateWebhookSheetProps) {
  // // Extract email from webhook URL if in edit mode
  // const getEmailFromWebhookUrl = (url: string) => {
  //   const match = url.match(/\/webhooks\/nylas\/(.+)$/);
  //   return match ? decodeURIComponent(match[1]) : '';
  // };

  // const [selectedEmail, setSelectedEmail] = useState('');
  const [description, setDescription] = useState<string>('');
  const [selectedTriggers, setSelectedTriggers] = useState<Option[]>([]);
  const [notificationEmail, setNotificationEmail] = useState<string>('');

  const createWebhook = useCreateNylasWebhook();
  const updateWebhook = useUpdateNylasWebhook();
  // const { data: grants = [] } = useNylasGrants();

  // Initialize form with webhook data when editing
  useEffect(() => {
    if (webhook) {
      // const email = getEmailFromWebhookUrl(webhook.webhookUrl);
      // setSelectedEmail(email);
      setDescription(webhook.description || '');
      setSelectedTriggers(
        webhook.triggerTypes.map(trigger => ({
          value: trigger,
          label: trigger.replace(/\./g, ' ').replace(/\b\w/g, (c) => c.toUpperCase())
        }))
      );
      setNotificationEmail(webhook.notificationEmailAddresses?.[0] || '');
    } else if (!open) {
      // Only reset if not in edit mode and sheet is closed
      // setSelectedEmail('');
      setDescription('');
      setSelectedTriggers([]);
      setNotificationEmail('');
    }
  }, [webhook, open]);

  // Helper function to format enum values into human-readable labels
  function toLabel(trigger: string): string {
    return trigger
    .split('.')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
  }
  
  // Get all trigger types from the WebhookTriggers enum
  const triggerOptions = (Object.values(WebhookTriggers) as string[]).map(trigger => ({
    value: trigger,
    label: toLabel(trigger)
  }));

  // // Get email options from grants
  // const emailOptions = grants.map(grant => ({
  //   value: grant.email,
  //   label: grant.email
  // }));

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Create webhook URL from selected email
    // const webhookUrl = `${getPublicAPIBaseUrl()}/webhooks/nylas/}${encodeURIComponent(selectedEmail)}`;
    const webhookUrl = `${getPublicAPIBaseUrl()}/webhooks/nylas/}`;

    const onSuccess = () => {
      // Reset form and close sheet on success
      // setSelectedEmail('');
      setDescription('');
      setSelectedTriggers([]);
      setNotificationEmail('');
      onOpenChange(false);
    };

    if (webhook) {
      await updateWebhook.mutateAsync(
        {
          webhookId: webhook.id,
          triggerTypes: selectedTriggers.map(t => t.value) as WebhookTriggers[],
          description,
          notificationEmailAddresses: notificationEmail ? [notificationEmail] : undefined,
        },
        { onSuccess }
      );
    } else {
      await createWebhook.mutateAsync(
        {
          triggerTypes: selectedTriggers.map(t => t.value) as WebhookTriggers[],
          webhookUrl,
          description,
          notificationEmailAddresses: notificationEmail ? [notificationEmail] : undefined,
        },
        { onSuccess }
      );
    }
  };

  const isPending = webhook ? updateWebhook.isPending : createWebhook.isPending;

  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent>
        <SheetHeader>
          <SheetTitle>{webhook ? 'Edit Webhook' : 'Create New Webhook'}</SheetTitle>
        </SheetHeader>
        <form onSubmit={handleSubmit} className="mt-4 space-y-4">
          {/* <div className="space-y-2">
            <Label htmlFor="email">Email Address *</Label>
            <Select
              value={selectedEmail}
              onValueChange={setSelectedEmail}
              disabled={!!webhook}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select an email address" />
              </SelectTrigger>
              <SelectContent>
                {emailOptions
                  .filter((option) => option.value !== undefined)
                  .map((option) => (
                    <SelectItem key={option.value} value={option.value!}>
                      {option.label}
                    </SelectItem>
                  ))}
              </SelectContent>
            </Select>
          </div> */}
          <div className="space-y-2">
            <Label htmlFor="triggerTypes">Trigger Types *</Label>
            <div className="min-h-[38px]">
              <MultipleSelector
                options={triggerOptions}
                value={selectedTriggers}
                onChange={setSelectedTriggers}
                placeholder="Select trigger types..."
              />
            </div>
          </div>
          <div className="space-y-2">
            <Label htmlFor="description">Description</Label>
            <Input
              id="description"
              placeholder="Webhook description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="notificationEmail">Notification Email</Label>
            <Input
              id="notificationEmail"
              type="email"
              placeholder="Email to receive webhook notifications"
              value={notificationEmail}
              onChange={(e) => setNotificationEmail(e.target.value)}
            />
          </div>
          <div className="flex justify-end gap-2">
            <Button
              type="button"
              variant="outline"
              onClick={() => onOpenChange(false)}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={isPending}
            >
              {isPending ? (webhook ? 'Updating...' : 'Creating...') : (webhook ? 'Update Webhook' : 'Create Webhook')}
            </Button>
          </div>
        </form>
      </SheetContent>
    </Sheet>
  );
}
