import type { ColDef } from '@ag-grid-community/core';
import { formatInTimeZone } from 'date-fns-tz';
import { Trash2 } from 'lucide-react';
import { Button } from '@/components/ui/button';
import type { SchedulerEvent } from '@/hooks/use-scheduler';
import type { SchedulerEventMetadata } from '@/types/scheduler';

export const schedulerColumnDefs: ColDef<SchedulerEvent>[] = [
  {
    field: 'scheduled_time',
    headerName: 'Scheduled Time (EST)',
    sortable: true,
    sort: 'desc',
    valueFormatter: (params) => {
      if (!params.value)
        return '';
      return formatInTimeZone(new Date(params.value), 'America/New_York', 'MMM d, yyyy h:mm a');
    },
    flex: 1,
  },
  {
    field: 'comment',
    headerName: 'Batch Info',
    flex: 2,
    valueGetter: (params) => {
      try {
        if (!params.data?.comment)
          return '';
        const metadata: SchedulerEventMetadata = JSON.parse(params.data.comment);
        return `${metadata.batchSize} domains grouped by ${metadata.batchCriteria} (${metadata.criteriaValue})`;
      } catch {
        return params.data?.comment || '';
      }
    },
  },
  {
    field: 'comment',
    headerName: 'Filters',
    flex: 2,
    valueGetter: (params) => {
      try {
        if (!params.data?.comment)
          return '';
        const metadata: SchedulerEventMetadata = JSON.parse(params.data.comment);
        const filters = [];

        if (metadata.filters.regions?.length)
          filters.push(`Regions: ${metadata.filters.regions.join(', ')}`);
        if (metadata.filters.exclude_regions?.length)
          filters.push(`Exclude Regions: ${metadata.filters.exclude_regions.join(', ')}`);
        if (metadata.filters.registrars?.length)
          filters.push(`Registrars: ${metadata.filters.registrars.join(', ')}`);
        if (metadata.filters.exclude_registrars?.length)
          filters.push(`Exclude Registrars: ${metadata.filters.exclude_registrars.join(', ')}`);
        if (metadata.filters.emails?.length)
          filters.push(`Emails: ${metadata.filters.emails.join(', ')}`);
        if (metadata.filters.exclude_emails?.length)
          filters.push(`Exclude Emails: ${metadata.filters.exclude_emails.join(', ')}`);
        if (metadata.filters.names?.length)
          filters.push(`Names: ${metadata.filters.names.join(', ')}`);
        if (metadata.filters.exclude_names?.length)
          filters.push(`Exclude Names: ${metadata.filters.exclude_names.join(', ')}`);
        if (metadata.filters.domains?.length)
          filters.push(`Domains: ${metadata.filters.domains.join(', ')}`);
        if (metadata.filters.exclude_domains?.length)
          filters.push(`Exclude Domains: ${metadata.filters.exclude_domains.join(', ')}`);
        if (metadata.filters.organizations?.length)
          filters.push(`Organizations: ${metadata.filters.organizations.join(', ')}`);
        if (metadata.filters.exclude_organizations?.length)
          filters.push(`Exclude Organizations: ${metadata.filters.exclude_organizations.join(', ')}`);

        return filters.join('; ');
      } catch {
        return '';
      }
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    sortable: true,
    width: 120,
    cellClass: (params: { value: string }) => {
      switch (params.value) {
        case 'scheduled': {
          return 'text-blue-600';
        }
        case 'delivered': {
          return 'text-green-600';
        }
        case 'error': {
          return 'text-red-600';
        }
        case 'deleted': {
          return 'text-gray-600';
        }
        default: {
          return '';
        }
      }
    },
  },
  {
    field: 'id',
    headerName: 'Actions',
    width: 100,
    cellRenderer: (params: { data: SchedulerEvent | undefined; context: { onDelete?: (id: string) => void } }) => {
      const { data } = params;
      if (!data || data.status !== 'scheduled')
        return null;

      return (
        <Button
          variant="ghost"
          size="icon"
          onClick={() => params.context.onDelete?.(data.id)}
          className="size-8"
        >
          <Trash2 className="size-4" />
        </Button>
      );
    },
  },
];
