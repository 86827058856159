import { useEffect, useState } from 'react';
import { Plus, X } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';

interface DomainEditModalProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  domains: string[];
  onSave: (domains: string[]) => void;
}

export function DomainEditModal({ open, onOpenChange, domains: initialDomains, onSave }: DomainEditModalProps) {
  const [domains, setDomains] = useState<string[]>([]);
  const [newDomain, setNewDomain] = useState('');

  // Sync domains when initialDomains changes or modal opens
  useEffect(() => {
    if (open) {
      setDomains(initialDomains);
      setNewDomain('');
    }
  }, [initialDomains, open]);

  const handleAddDomain = () => {
    if (!newDomain)
      return;
    setDomains([...domains, newDomain]);
    setNewDomain('');
  };

  const handleRemoveDomain = (domainToRemove: string) => {
    setDomains(domains.filter((domain) => domain !== domainToRemove));
  };

  const handleSave = () => {
    onSave(domains);
    onOpenChange(false);
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-h-[80vh] overflow-y-auto sm:max-w-[600px]">
        <DialogHeader>
          <DialogTitle>Edit Domains</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col gap-4">
          <div className="flex items-center gap-2">
            <Input
              placeholder="Add domain..."
              value={newDomain}
              onChange={(e) => setNewDomain(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleAddDomain();
                }
              }}
              className="h-8"
            />
            <Button
              size="sm"
              onClick={handleAddDomain}
              disabled={!newDomain}
              className="h-8 px-2"
            >
              <Plus className="size-4" />
            </Button>
          </div>

          <div className="flex flex-wrap gap-2">
            {domains.map((domain) => (
              <div
                key={domain}
                className="flex items-center gap-2 rounded-md border bg-muted px-2 py-1 text-sm"
              >
                {domain}
                <button
                  onClick={() => handleRemoveDomain(domain)}
                  className="text-muted-foreground hover:text-foreground"
                >
                  <X className="size-3" />
                </button>
              </div>
            ))}
          </div>

          <div className="flex justify-end gap-2">
            <Button variant="outline" onClick={() => onOpenChange(false)}>
              Cancel
            </Button>
            <Button onClick={handleSave}>
              Save
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
