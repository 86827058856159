import { format } from 'date-fns';
import { Check, PenTool, X } from 'lucide-react';
import { toast } from 'sonner';
import { Button } from '@/components/ui/button';
import type { Deal } from '@/hooks/use-deals';
import type { SignableNote, VerificationItem } from '../types';

interface SignableNoteProps {
  item: SignableNote;
  onUpdateContent: (id: string, content: string) => void;
  onSign: (id: string, role: 'creator' | 'reviewer') => void;
  userId: string | undefined;
  deal: Deal;
}

export function SignableNoteComponent({
  item,
  onUpdateContent,
  onSign,
  userId,
  deal,
}: SignableNoteProps) {
  return (
    <div className="space-y-4">
      <textarea
        value={item.content}
        onChange={(e) => onUpdateContent(item.id, e.target.value)}
        className="h-32 w-full rounded-md border bg-transparent px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
        placeholder="Enter note content"
      />
      <div className="flex items-center gap-4">
        {item.id === 'name-selection' && (
          <Button
            variant="outline"
            className="gap-2"
            onClick={() => onUpdateContent(item.id, `Domain(s) comply with Name Selection Policy${item.content ? `\n\n${item.content}` : ''}`)}
          >
            Default
          </Button>
        )}
        {item.id === 'employment-authority' && (
          <Button
            variant="outline"
            className="gap-2"
            onClick={() => {
              const contacts = deal.verification_contacts.people;
              if (!contacts || contacts.length === 0) {
                toast.error('No verification contacts found');
                return;
              }
              const contactsText = contacts
                .map((contact) => `By: ${contact.name}, ${contact.title}\nHow: via eMail (DLB)`)
                .join('\n\n');
              onUpdateContent(item.id, contactsText);
            }}
          >
            Default
          </Button>
        )}
        <Button
          variant="outline"
          className="gap-2"
          onClick={() => onSign(item.id, 'creator')}
        >
          {item.signatures.some(
            (s) => s.userId === userId && s.role === 'creator',
          )
            ? (
                <>
                  <X className="size-4" />
                  Remove Creator Signature
                </>
              )
            : (
                <>
                  <PenTool className="size-4" />
                  Sign as Creator
                </>
              )}
        </Button>
        <Button
          variant="outline"
          className="gap-2"
          onClick={() => onSign(item.id, 'reviewer')}
        >
          {item.signatures.some(
            (s) => s.userId === userId && s.role === 'reviewer',
          )
            ? (
                <>
                  <X className="size-4" />
                  Remove Reviewer Signature
                </>
              )
            : (
                <>
                  <PenTool className="size-4" />
                  Sign as Reviewer
                </>
              )}
        </Button>
      </div>
      {item.signatures.length > 0 && (
        <div className="space-y-2">
          <div className="text-sm font-medium">Signatures:</div>
          {item.signatures.map((signature, index) => (
            <div
              key={index}
              className="text-sm text-muted-foreground"
            >
              {signature.name}
              {' '}
              (
              {signature.role}
              ) -
              {' '}
              {format(new Date(signature.timestamp), 'MMM d, yyyy h:mm a')}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
