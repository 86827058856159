import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from "@/components/ui/alert-dialog"
import { buttonVariants } from "@/components/ui/button"
import { cn } from "@/lib/utils"
import type { ButtonProps } from "@/components/ui/button"

type ConfirmationDialogProps = {
  /**
   * Controls the visibility of the dialog
   */
  open: boolean
  /**
   * Called when the dialog should be closed (cancel button or escape key)
   */
  onOpenChange: (open: boolean) => void
  /**
   * The title of the dialog
   */
  title: string
  /**
   * The description/content of the dialog
   */
  description: string
  /**
   * Called when the confirm button is clicked
   */
  onConfirm: () => void
  /**
   * Text for the confirm button
   * @default "Confirm"
   */
  confirmText?: string
  /**
   * Text for the cancel button
   * @default "Cancel"
   */
  cancelText?: string
  /**
   * Additional class names for the confirm button
   */
  confirmButtonClassName?: string
  /**
   * Additional class names for the cancel button
   */
  cancelButtonClassName?: string
  /**
   * Variant for the confirm button
   * @default "default"
   */
  confirmVariant?: ButtonProps["variant"]
}

export function ConfirmationDialog({
  open,
  onOpenChange,
  title,
  description,
  onConfirm,
  confirmText = "Confirm",
  cancelText = "Cancel",
  confirmButtonClassName,
  cancelButtonClassName,
  confirmVariant = "default"
}: ConfirmationDialogProps) {
  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{title}</AlertDialogTitle>
          <AlertDialogDescription>{description}</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel className={cn(cancelButtonClassName)}>
            {cancelText}
          </AlertDialogCancel>
          <AlertDialogAction
            onClick={onConfirm}
            className={cn(
              buttonVariants({ variant: confirmVariant }),
              confirmButtonClassName
            )}
          >
            {confirmText}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
