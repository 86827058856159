import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Building2, Mail, Phone, User, Pencil, Copy, ChevronDown, ChevronRight, Globe } from 'lucide-react';
import { toast } from 'sonner';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Switch } from '@/components/ui/switch';
import { useDeal } from '@/contexts/deal-context';
import {
  useUpdateContact,
  useCreateContact,
  useAddLicense,
  useUpdateLicense,
  useRemoveLicense,
} from '@/hooks/use-contacts';
import { useDeal as useCurrentDeal, DEALS_QUERY_KEY } from '@/hooks/use-deals';

interface EditableFieldProps {
  label: string;
  value: string | null | undefined;
  onSave: (value: string) => Promise<void>;
  type?: string;
  className?: string;
}

function EditableField({ label, value, onSave, type = 'text', className }: EditableFieldProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState(value || '');

  const handleSave = async () => {
    try {
      await onSave(editValue);
      setIsEditing(false);
      toast.success('Updated successfully');
    } catch {
      toast.error('Failed to update');
    }
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(value || '');
      toast.success('Copied to clipboard');
    } catch {
      toast.error('Failed to copy to clipboard');
    }
  };

  if (isEditing) {
    return (
      <div className="flex items-center gap-2">
        <Input
          type={type}
          value={editValue}
          onChange={(e) => setEditValue(e.target.value)}
          className="h-8"
        />
        <Button size="sm" onClick={handleSave}>Save</Button>
        <Button size="sm" variant="outline" onClick={() => setIsEditing(false)}>Cancel</Button>
      </div>
    );
  }

  return (
    <div className="group flex items-center justify-between">
      <div className={className}>
        {label && (
          <span className="text-muted-foreground">
            {label}
            :
            {' '}
          </span>
        )}
        {value || 'Not set'}
      </div>
      <div className="flex gap-1 opacity-0 group-hover:opacity-100">
        <Button
          size="sm"
          variant="ghost"
          onClick={handleCopy}
        >
          <Copy className="size-3" />
        </Button>
        <Button
          size="sm"
          variant="ghost"
          onClick={() => setIsEditing(true)}
        >
          <Pencil className="size-3" />
        </Button>
      </div>
    </div>
  );
}

interface EditableSwitchProps {
  label: string;
  value: boolean;
  onSave: (value: boolean) => Promise<void>;
}

function EditableSwitch({ label, value, onSave }: EditableSwitchProps) {
  return (
    <div className="flex items-center justify-between">
      <span className="text-muted-foreground">{label}</span>
      <Switch
        checked={value}
        onCheckedChange={async (checked) => {
          try {
            await onSave(checked);
            toast.success('Updated successfully');
          } catch {
            toast.error('Failed to update');
          }
        }}
      />
    </div>
  );
}

interface PersonCardProps {
  person: {
    name: string;
    legal_name: string;
    alt_name: string | null;
    email: string;
    alt_email: string | null;
    phone_number: string;
    alt_phone: string | null;
    fax_number: string | null;
    address_line_1: string;
    address_line_2: string | null;
    address_line_3: string | null;
    city: string;
    state_province: string;
    postal_code: string;
    country: string;
    title: string;
    c_suite: boolean;
    sole_prop: boolean;
    new_legal_name: boolean;
    associated_domains: string[];
  };
  index: number;
  type: 'registrant' | 'verification';
  onUpdate: (index: number, field: string, value: string | boolean) => Promise<void>;
}

function PersonCard({ person, index, type, onUpdate }: PersonCardProps) {
  const queryClient = useQueryClient();
  const { dealId } = useDeal();
  const [showDomains, setShowDomains] = useState(false);

  const handleUpdate = async (field: string, value: string | boolean) => {
    await onUpdate(index, field, value);
    await queryClient.invalidateQueries({ queryKey: [...DEALS_QUERY_KEY, dealId] });
  };

  return (
    <div className="space-y-2">
      <div className="grid gap-x-4 gap-y-1">
        <div className="font-medium">{person.name}</div>
        <div className="grid grid-cols-2 gap-x-4">
          <EditableField
            label="Legal Name"
            value={person.legal_name}
            onSave={async (value) => handleUpdate('legal_name', value)}
            className="font-medium"
          />
          <EditableField
            label="Alt Name"
            value={person.alt_name || ''}
            onSave={async (value) => handleUpdate('alt_name', value)}
            className="text-sm"
          />
        </div>
        <EditableField
          label="Title"
          value={person.title}
          onSave={async (value) => handleUpdate('title', value)}
          className="text-sm"
        />
        {type === 'verification' && (
          <EditableField
            label="Organization Name"
            value={(person as any).organization_name || ''}
            onSave={async (value) => handleUpdate('organization_name', value)}
            className="text-sm"
          />
        )}
      </div>

      {/* Associated Domains Section */}
      {person.associated_domains.length > 0 && (
        <div className="space-y-1">
          <button
            onClick={() => setShowDomains(!showDomains)}
            className="flex w-full items-center gap-2 text-sm text-muted-foreground hover:text-foreground"
          >
            {showDomains ? <ChevronDown className="size-4" /> : <ChevronRight className="size-4" />}
            <Globe className="size-4" />
            <span>
              {person.associated_domains.length}
              {' '}
              Associated Domain
              {person.associated_domains.length === 1 ? '' : 's'}
            </span>
          </button>
          {showDomains && (
            <div className="ml-6 space-y-1">
              {person.associated_domains.map((domain, idx) => (
                <div key={idx} className="flex items-center gap-2 text-sm">
                  <span>{domain}</span>
                  <Button
                    size="sm"
                    variant="ghost"
                    onClick={async () => {
                      try {
                        await navigator.clipboard.writeText(domain);
                        toast.success('Domain copied to clipboard');
                      } catch {
                        toast.error('Failed to copy to clipboard');
                      }
                    }}
                  >
                    <Copy className="size-3" />
                  </Button>
                </div>
              ))}
            </div>
          )}
        </div>
      )}

      <div className="grid gap-1 text-sm">
        <EditableField
          label="Address Line 1"
          value={person.address_line_1}
          onSave={async (value) => handleUpdate('address_line_1', value)}
        />
        <EditableField
          label="Address Line 2"
          value={person.address_line_2 || ''}
          onSave={async (value) => handleUpdate('address_line_2', value)}
        />
        <EditableField
          label="Address Line 3"
          value={person.address_line_3 || ''}
          onSave={async (value) => handleUpdate('address_line_3', value)}
        />
        <EditableField
          label="City"
          value={person.city}
          onSave={async (value) => handleUpdate('city', value)}
        />
        <EditableField
          label="State/Province"
          value={person.state_province}
          onSave={async (value) => handleUpdate('state_province', value)}
        />
        <EditableField
          label="Postal Code"
          value={person.postal_code}
          onSave={async (value) => handleUpdate('postal_code', value)}
        />
        <EditableField
          label="Country"
          value={person.country}
          onSave={async (value) => handleUpdate('country', value)}
        />
      </div>

      <div className="grid grid-cols-2 gap-x-4 gap-y-1">
        <EditableField
          label="Company Phone"
          value={person.phone_number}
          onSave={async (value) => handleUpdate('phone_number', value)}
          type="tel"
        />
        <EditableField
          label="Direct Phone"
          value={person.alt_phone || ''}
          onSave={async (value) => handleUpdate('alt_phone', value)}
          type="tel"
        />
        <EditableField
          label="Fax"
          value={person.fax_number || ''}
          onSave={async (value) => handleUpdate('fax_number', value)}
          type="tel"
        />
        <EditableField
          label="Email"
          value={person.email}
          onSave={async (value) => handleUpdate('email', value)}
          type="email"
        />
        <EditableField
          label="Alt Email"
          value={person.alt_email || ''}
          onSave={async (value) => handleUpdate('alt_email', value)}
          type="email"
        />
      </div>

      <div className="grid grid-cols-3 gap-x-4">
        <EditableSwitch
          label="C-Suite"
          value={person.c_suite}
          onSave={async (value) => handleUpdate('c_suite', value)}
        />
        <EditableSwitch
          label="Sole Prop"
          value={person.sole_prop}
          onSave={async (value) => handleUpdate('sole_prop', value)}
        />
        <EditableSwitch
          label="New Legal Name"
          value={person.new_legal_name}
          onSave={async (value) => handleUpdate('new_legal_name', value)}
        />
      </div>
    </div>
  );
}

interface License {
  authority: string;
  value: string;
  contact_ids?: string[];
  license_ids?: string[];
}

interface Organization {
  name: string;
  email: string;
  phone_number: string;
  alt_phone: string | null;
  fax_number: string | null;
  address_line_1: string;
  address_line_2: string | null;
  address_line_3: string | null;
  city: string;
  state_province: string;
  postal_code: string;
  country: string;
  contact_ids?: string[];
  licenses: License[];
  associated_domains: string[];
}

interface OrganizationCardProps {
  organization: Organization;
  index: number;
  onUpdate: (index: number, field: string, value: string | Array<License> | any) => Promise<void>;
}

function OrganizationCard({ organization, index, onUpdate }: OrganizationCardProps) {
  const [isAddingLicense, setIsAddingLicense] = useState(false);
  const [editingLicenseIndex, setEditingLicenseIndex] = useState<number | null>(null);
  const [showDomains, setShowDomains] = useState(false);
  const addLicense = useAddLicense();
  const updateLicense = useUpdateLicense();
  const removeLicense = useRemoveLicense();
  const queryClient = useQueryClient();
  const { dealId } = useDeal();

  const handleUpdate = async (field: string, value: string | any) => {
    await onUpdate(index, field, value);
    await queryClient.invalidateQueries({ queryKey: [...DEALS_QUERY_KEY, dealId] });
  };

  return (
    <div className="space-y-2">
      <div className="grid gap-1">
        <EditableField
          label="Organization Name"
          value={organization.name}
          onSave={async (value) => handleUpdate('organization_legal_name', value)}
          className="font-medium"
        />
      </div>

      {/* Associated Domains Section */}
      {organization.associated_domains.length > 0 && (
        <div className="space-y-1">
          <button
            onClick={() => setShowDomains(!showDomains)}
            className="flex w-full items-center gap-2 text-sm text-muted-foreground hover:text-foreground"
          >
            {showDomains ? <ChevronDown className="size-4" /> : <ChevronRight className="size-4" />}
            <Globe className="size-4" />
            <span>
              {organization.associated_domains.length}
              {' '}
              Associated Domain
              {organization.associated_domains.length === 1 ? '' : 's'}
            </span>
          </button>
          {showDomains && (
            <div className="ml-6 space-y-1">
              {organization.associated_domains.map((domain, idx) => (
                <div key={idx} className="flex items-center gap-2 text-sm">
                  <span>{domain}</span>
                  <Button
                    size="sm"
                    variant="ghost"
                    onClick={async () => {
                      try {
                        await navigator.clipboard.writeText(domain);
                        toast.success('Domain copied to clipboard');
                      } catch {
                        toast.error('Failed to copy to clipboard');
                      }
                    }}
                  >
                    <Copy className="size-3" />
                  </Button>
                </div>
              ))}
            </div>
          )}
        </div>
      )}

      <div className="grid gap-1 text-sm">
        <EditableField
          label="Address Line 1"
          value={organization.address_line_1}
          onSave={async (value) => handleUpdate('address_line_1', value)}
        />
        <EditableField
          label="Address Line 2"
          value={organization.address_line_2 || ''}
          onSave={async (value) => handleUpdate('address_line_2', value)}
        />
        <EditableField
          label="Address Line 3"
          value={organization.address_line_3 || ''}
          onSave={async (value) => handleUpdate('address_line_3', value)}
        />
        <EditableField
          label="City"
          value={organization.city}
          onSave={async (value) => handleUpdate('city', value)}
        />
        <EditableField
          label="State/Province"
          value={organization.state_province}
          onSave={async (value) => handleUpdate('state_province', value)}
        />
        <EditableField
          label="Postal Code"
          value={organization.postal_code}
          onSave={async (value) => handleUpdate('postal_code', value)}
        />
        <EditableField
          label="Country"
          value={organization.country}
          onSave={async (value) => handleUpdate('country', value)}
        />
      </div>

      <div className="grid grid-cols-2 gap-x-4 gap-y-1">
        <EditableField
          label="Company Phone"
          value={organization.phone_number}
          onSave={async (value) => handleUpdate('phone_number', value)}
          type="tel"
        />
        <EditableField
          label="Direct Phone"
          value={organization.alt_phone || ''}
          onSave={async (value) => handleUpdate('alt_phone', value)}
          type="tel"
        />
        <EditableField
          label="Fax"
          value={organization.fax_number || ''}
          onSave={async (value) => handleUpdate('fax_number', value)}
          type="tel"
        />
        <EditableField
          label="Email"
          value={organization.email}
          onSave={async (value) => handleUpdate('email', value)}
          type="email"
        />
      </div>

      <div className="grid gap-1">
        <div className="flex items-center justify-between">
          <div className="text-sm font-medium text-muted-foreground">Licenses</div>
          <Button
            size="sm"
            variant="outline"
            onClick={() => setIsAddingLicense(true)}
          >
            Add License
          </Button>
        </div>
        {isAddingLicense && (
          <LicenseForm
            onSubmit={async (authority, value) => {
              try {
                // Call API to add license for each contact
                const addedLicenses = await Promise.all(
                  organization.contact_ids?.map(async (contactId) => {
                    const result = await addLicense.mutateAsync({
                      contactId,
                      authority,
                      value,
                    });
                    return result;
                  }) ?? [],
                );

                // Update local state with new license
                if (addedLicenses.length > 0) {
                  const newLicense = {
                    authority,
                    value,
                    contact_ids: organization.contact_ids,
                    license_ids: addedLicenses.map((license) => license.id),
                  };
                  await handleUpdate('licenses', [...organization.licenses, newLicense]);
                }

                toast.success('License added');
                setIsAddingLicense(false);
              } catch {
                toast.error('Failed to add license');
              }
            }}
            onCancel={() => setIsAddingLicense(false)}
          />
        )}
        <div className="grid gap-1">
          {organization.licenses.map((license, licenseIndex) => (
            <div key={licenseIndex} className="rounded-md border p-2">
              {editingLicenseIndex === licenseIndex
                ? (
                    <LicenseForm
                      initialAuthority={license.authority}
                      initialValue={license.value}
                      onSubmit={async (authority, value) => {
                        try {
                          const license = organization.licenses[licenseIndex];
                          // Update license for each contact
                          await Promise.all(
                            organization.contact_ids?.map(async (contactId) => {
                              const licenseId = license.license_ids?.find((id) =>
                                license.contact_ids?.includes(contactId),
                              );
                              if (licenseId) {
                                await updateLicense.mutateAsync({
                                  contactId,
                                  licenseId,
                                  authority,
                                  value,
                                });
                              }
                            }) ?? [],
                          );

                          // Update local state
                          const updatedLicenses = [...organization.licenses];
                          updatedLicenses[licenseIndex] = {
                            ...license,
                            authority,
                            value,
                          };
                          await handleUpdate('licenses', updatedLicenses);

                          toast.success('License updated');
                          setEditingLicenseIndex(null);
                        } catch {
                          toast.error('Failed to update license');
                        }
                      }}
                      onCancel={() => setEditingLicenseIndex(null)}
                    />
                  )
                : (
                    <div className="flex items-center justify-between">
                      <div>
                        <div className="font-medium">{license.authority}</div>
                        <div className="text-sm text-muted-foreground">{license.value}</div>
                      </div>
                      <div className="flex gap-2">
                        <Button
                          size="sm"
                          variant="ghost"
                          onClick={() => setEditingLicenseIndex(licenseIndex)}
                        >
                          Edit
                        </Button>
                        <Button
                          size="sm"
                          variant="ghost"
                          onClick={async () => {
                            try {
                              const license = organization.licenses[licenseIndex];
                              // Remove license from each contact
                              await Promise.all(
                                organization.contact_ids?.map(async (contactId) => {
                                  const licenseId = license.license_ids?.find((id) =>
                                    license.contact_ids?.includes(contactId),
                                  );
                                  if (licenseId) {
                                    await removeLicense.mutateAsync({ contactId, licenseId });
                                  }
                                }) ?? [],
                              );

                              // Update local state by removing the license
                              const updatedLicenses = organization.licenses.filter((_, idx) => idx !== licenseIndex);
                              await handleUpdate('licenses', updatedLicenses);

                              toast.success('License removed');
                            } catch {
                              toast.error('Failed to remove license');
                            }
                          }}
                        >
                          Remove
                        </Button>
                      </div>
                    </div>
                  )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

interface LicenseFormProps {
  onSubmit: (authority: string, value: string) => Promise<void>;
  onCancel: () => void;
  initialAuthority?: string;
  initialValue?: string;
}

function LicenseForm({ onSubmit, onCancel, initialAuthority = '', initialValue = '' }: LicenseFormProps) {
  const [authority, setAuthority] = useState(initialAuthority);
  const [value, setValue] = useState(initialValue);

  return (
    <div className="rounded-md border p-4">
      <div className="grid grid-cols-2 gap-4">
        <div>
          <div className="mb-2 text-sm font-medium">Authority</div>
          <Input
            placeholder="Enter authority"
            value={authority}
            onChange={(e) => setAuthority(e.target.value)}
          />
        </div>
        <div>
          <div className="mb-2 text-sm font-medium">Value</div>
          <Input
            placeholder="Enter value"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </div>
      </div>
      <div className="mt-4 flex justify-end gap-2">
        <Button
          size="sm"
          variant="outline"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          size="sm"
          onClick={async () => {
            if (!authority || !value) {
              toast.error('Please fill in both fields');
              return;
            }
            await onSubmit(authority, value);
          }}
        >
          Save
        </Button>
      </div>
    </div>
  );
}

export function ContactsPanel() {
  const { dealId } = useDeal();
  const { data: deal, isLoading: isLoadingDeal } = useCurrentDeal(dealId!);
  const updateContact = useUpdateContact();
  const createContact = useCreateContact();
  const addLicense = useAddLicense();
  const updateLicense = useUpdateLicense();
  const removeLicense = useRemoveLicense();
  const queryClient = useQueryClient();

  if (isLoadingDeal || !deal) {
    return (
      <div className="flex h-full items-center justify-center">
        <div className="text-sm text-muted-foreground">Loading deal...</div>
      </div>
    );
  }

  const handleUpdateRegistrantPerson = async (index: number, field: string, value: string | boolean) => {
    const person = deal.registrants.people[index];

    try {
      const contactData = {
        legal_name: person.legal_name,
        email: person.email,
        phone_number: person.phone_number,
        alt_phone: person.alt_phone,
        fax_number: person.fax_number,
        address_line_1: person.address_line_1,
        address_line_2: person.address_line_2,
        address_line_3: person.address_line_3,
        city: person.city,
        state_province: person.state_province,
        postal_code: person.postal_code,
        country: person.country,
        alt_name: person.alt_name,
        alt_email: person.alt_email,
        title: person.title,
        c_suite: person.c_suite,
        sole_prop: person.sole_prop,
        new_legal_name: person.new_legal_name,
      };

      // Update the specific field
      const fieldParts = field.split('.');
      let current: any = contactData;
      for (let i = 0; i < fieldParts.length - 1; i++) {
        if (!current[fieldParts[i]])
          current[fieldParts[i]] = {};
        current = current[fieldParts[i]];
      }
      const lastField = fieldParts.at(-1);
      if (lastField) {
        current[lastField] = value;
      }

      // Update each contact ID for this person
      if (person.contact_ids?.length) {
        await Promise.all(
          person.contact_ids.map(async (contactId) =>
            updateContact.mutateAsync({ id: contactId, ...contactData }),
          ),
        );
      }
    } catch (error) {
      toast.error('Failed to update contact');
      throw error;
    }
  };

  const handleUpdateRegistrantOrg = async (index: number, field: string, value: string | any) => {
    const org = deal.registrants.organizations[index];

    try {
      if (field.startsWith('licenses')) {
        const licenseIndex = Number.parseInt(field.split('.')[1], 10);
        const license = org.licenses[licenseIndex];

        if (field.endsWith('authority') || field.endsWith('value')) {
          // Update the specific license field
          const fieldName = field.split('.').pop();
          const licenseData = {
            authority: fieldName === 'authority' ? value : license.authority,
            value: fieldName === 'value' ? value : license.value,
          };

          // Update or create license for each contact ID
          await Promise.all(org.contact_ids?.map(async (contactId) => {
            const licenseId = license.license_ids?.find((id) =>
              license.contact_ids?.includes(contactId),
            );

            await (licenseId
              ? updateLicense.mutateAsync({
                  contactId,
                  licenseId,
                  ...licenseData,
                })
              : addLicense.mutateAsync({
                  contactId,
                  ...licenseData,
                }));
          }) ?? []);
        }
      } else {
        const contactData = {
          organization_legal_name: field === 'organization_legal_name' ? value : org.name,
          email: org.email,
          phone_number: org.phone_number,
          alt_phone: org.alt_phone,
          fax_number: org.fax_number,
          address_line_1: org.address_line_1,
          address_line_2: org.address_line_2,
          address_line_3: org.address_line_3,
          city: org.city,
          state_province: org.state_province,
          postal_code: org.postal_code,
          country: org.country,
        };

        // Update the specific field
        const fieldParts = field.split('.');
        let current: any = contactData;
        for (let i = 0; i < fieldParts.length - 1; i++) {
          if (!current[fieldParts[i]])
            current[fieldParts[i]] = {};
          current = current[fieldParts[i]];
        }
        const lastField = fieldParts.at(-1);
        if (lastField) {
          current[lastField] = value;
        }

        // Update each contact ID for this organization
        if (org.contact_ids?.length) {
          await Promise.all(
            org.contact_ids.map(async (contactId) =>
              updateContact.mutateAsync({ id: contactId, ...contactData }),
            ),
          );
        }
      }
    } catch (error) {
      toast.error('Failed to update organization');
      throw error;
    }
  };

  const handleUpdateVerificationPerson = async (index: number, field: string, value: string | boolean) => {
    const person = deal.verification_contacts.people[index];

    try {
      const contactData = {
        legal_name: person.legal_name,
        email: person.email,
        phone_number: person.phone_number,
        alt_phone: person.alt_phone,
        fax_number: person.fax_number,
        address_line_1: person.address_line_1,
        address_line_2: person.address_line_2,
        address_line_3: person.address_line_3,
        city: person.city,
        state_province: person.state_province,
        postal_code: person.postal_code,
        country: person.country,
        alt_name: person.alt_name,
        alt_email: person.alt_email,
        title: person.title,
        organization_legal_name: person.organization_name,
        c_suite: person.c_suite,
        sole_prop: person.sole_prop,
        new_legal_name: person.new_legal_name,
      };

      // Update the specific field
      const fieldParts = field.split('.');
      let current: any = contactData;
      for (let i = 0; i < fieldParts.length - 1; i++) {
        if (!current[fieldParts[i]])
          current[fieldParts[i]] = {};
        current = current[fieldParts[i]];
      }
      const lastField = fieldParts.at(-1);
      if (lastField) {
        current[lastField] = value;
      }

      // Update each contact ID for this person
      if (person.contact_ids?.length) {
        await Promise.all(
          person.contact_ids.map(async (contactId) =>
            updateContact.mutateAsync({ id: contactId, ...contactData }),
          ),
        );
      }
    } catch (error) {
      toast.error('Failed to update verification contact');
      throw error;
    }
  };

  return (
    <ScrollArea className="h-[600px]">
      <div className="mx-auto max-w-2xl space-y-4 p-4">
        <Accordion
          type="multiple"
          className="w-full"
          defaultValue={['registrant-people', 'registrant-orgs', 'verification-people']}
        >
          {/* Registrant People */}
          {deal.registrants.people.length > 0 && (
            <AccordionItem value="registrant-people">
              <AccordionTrigger className="px-4 py-2 hover:no-underline">
                <div className="flex items-center gap-2">
                  <User className="size-4" />
                  <span>Registrant Contacts</span>
                </div>
              </AccordionTrigger>
              <AccordionContent className="space-y-4 px-4 pb-4">
                {deal.registrants.people.map((person, index) => (
                  <PersonCard
                    key={index}
                    person={person}
                    index={index}
                    type="registrant"
                    onUpdate={handleUpdateRegistrantPerson}
                  />
                ))}
              </AccordionContent>
            </AccordionItem>
          )}

          {/* Registrant Organizations */}
          {deal.registrants.organizations.length > 0 && (
            <AccordionItem value="registrant-orgs">
              <AccordionTrigger className="px-4 py-2 hover:no-underline">
                <div className="flex items-center gap-2">
                  <Building2 className="size-4" />
                  <span>Registrant Organizations</span>
                </div>
              </AccordionTrigger>
              <AccordionContent className="space-y-4 px-4 pb-4">
                {deal.registrants.organizations.map((org, index) => (
                  <OrganizationCard
                    key={index}
                    organization={org}
                    index={index}
                    onUpdate={handleUpdateRegistrantOrg}
                  />
                ))}
              </AccordionContent>
            </AccordionItem>
          )}

          {/* Verification People */}
          {deal.verification_contacts.people.length > 0 && (
            <AccordionItem value="verification-people">
              <AccordionTrigger className="px-4 py-2 hover:no-underline">
                <div className="flex items-center gap-2">
                  <User className="size-4" />
                  <span>Verification Contacts</span>
                </div>
              </AccordionTrigger>
              <AccordionContent className="space-y-4 px-4 pb-4">
                {deal.verification_contacts.people.map((person, index) => (
                  <PersonCard
                    key={index}
                    person={person}
                    index={index}
                    type="verification"
                    onUpdate={handleUpdateVerificationPerson}
                  />
                ))}
              </AccordionContent>
            </AccordionItem>
          )}
        </Accordion>
      </div>
    </ScrollArea>
  );
}
