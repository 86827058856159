/* eslint-disable react-dom/no-dangerously-set-innerhtml */
import * as React from 'react';
import DOMPurify from 'dompurify';

import type { Message as NylasMessage } from 'nylas';

type MessageContentProps = {
  content: Partial<NylasMessage> | null;
};

export function MessageContent({ content }: MessageContentProps) {
  if (!content) {
    return (
      <div className="text-sm text-muted-foreground">
        Loading message content...
      </div>
    );
  }

  return (
    <div
      className="prose prose-sm dark:prose-invert max-w-none"
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(
          content?.body as string || '',
        ),
      }}
    />
  );
}
