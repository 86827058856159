import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import type { GridApi } from '@ag-grid-community/core';
import { toast } from 'sonner';
import AGDataGrid from '@/components/ag-data-grid';
import Container from '@/components/container';
import { Button } from '@/components/ui/button';
import { Form } from '@/components/ui/form';
import { Label } from '@/components/ui/label';
import type { Option } from '@/components/ui/multi-combobox';
import { MultiCombobox } from '@/components/ui/multi-combobox';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Switch } from '@/components/ui/switch';
import { useGrid } from '@/contexts/grid-context';
import { useCalculateVerificationBatches, type BatchCriteria, type BatchResult } from '@/hooks/use-annual-verification-batches';
import { useRegistrars } from '@/hooks/use-domains';
import { useCreateSchedulerEvent } from '@/hooks/use-scheduler';
import { convertToEST } from '@/types/scheduler';
import type { SchedulerTimeValue } from '@/types/scheduler';
import ChipInput from '../components/chip-input';
import { DomainsDialog } from '../components/domains-dialog';
import { previewColumnDefs } from '../components/preview-grid-columns';
import { SchedulerSheet } from '../components/scheduler-sheet';

const REGIONS: Option[] = [
  { label: 'US', value: 'US' },
  { label: 'EU-EEA', value: 'EU-EEA' },
  { label: 'ASIA', value: 'ASIA' },
  { label: 'INT', value: 'INT' },
  { label: 'INT excluding ASIA', value: 'INT excluding ASIA' },
  { label: 'INT excluding EU-EEA', value: 'INT excluding EU-EEA' },
];

const BATCH_CRITERIA: { value: BatchCriteria; label: string }[] = [
  { value: 'registrant_email', label: 'Registrant Email' },
  { value: 'license', label: 'License' },
  { value: 'registrant_org_name', label: 'Organization Name' },
];

function SchedulePage() {
  const navigate = useNavigate();
  const [selectedBatch, setSelectedBatch] = useState<BatchResult | null>(null);
  const [calculatedBatches, setCalculatedBatches] = useState<BatchResult[]>([]);
  const [viewingDomains, setViewingDomains] = useState<string[]>([]);
  const gridApiRef = useRef<GridApi<BatchResult> | null>(null);
  const { registerGridApi } = useGrid();

  // Filter state
  const [selectedRegions, setSelectedRegions] = useState<string[]>([]);
  const [selectedExcludeRegions, setSelectedExcludeRegions] = useState<string[]>([]);
  const [registrars, setRegistrars] = useState<string[]>([]);
  const [excludeRegistrars, setExcludeRegistrars] = useState<string[]>([]);
  const [emails, setEmails] = useState<string[]>([]);
  const [excludeEmails, setExcludeEmails] = useState<string[]>([]);
  const [names, setNames] = useState<string[]>([]);
  const [excludeNames, setExcludeNames] = useState<string[]>([]);
  const [domains, setDomains] = useState<string[]>([]);
  const [excludeDomains, setExcludeDomains] = useState<string[]>([]);
  const [organizations, setOrganizations] = useState<string[]>([]);
  const [excludeOrganizations, setExcludeOrganizations] = useState<string[]>([]);
  const [licenses, setLicenses] = useState<Array<{ authority: string; value: string }>>([]);
  const [excludeLicenses, setExcludeLicenses] = useState<Array<{ authority: string; value: string }>>([]);
  const [batchCriteria, setBatchCriteria] = useState<BatchCriteria>('registrant_email');
  const [skipRddsCheck, setSkipRddsCheck] = useState(false);
  const [skipNoavsend, setSkipNoavsend] = useState(false);
  const [batchLimit, setBatchLimit] = useState(50);

  const { calculateBatchesAsync, isCalculating } = useCalculateVerificationBatches();
  const { createEventAsync, isCreating } = useCreateSchedulerEvent();
  const { data: registrarList } = useRegistrars();

  const registrarOptions = registrarList
    ? registrarList.map((registrar) => ({ label: registrar, value: registrar }))
    : [];

  const handleGridReady = (params: { api: GridApi<BatchResult> }) => {
    gridApiRef.current = params.api;
    registerGridApi(params.api);
  };

  const handleCalculate = async () => {
    try {
      const result = await calculateBatchesAsync({
        filters: {
          regions: selectedRegions,
          exclude_regions: selectedExcludeRegions,
          registrars,
          exclude_registrars: excludeRegistrars,
          emails,
          exclude_emails: excludeEmails,
          names,
          exclude_names: excludeNames,
          domains,
          exclude_domains: excludeDomains,
          organizations,
          exclude_organizations: excludeOrganizations,
          licenses,
          exclude_licenses: excludeLicenses,
          skip_rdds_check: skipRddsCheck,
          skip_noavsend: skipNoavsend,
        },
        batch_by: batchCriteria,
        limit: batchLimit,
      });

      if (result.batches.length === 0) {
        toast.info('No batches found matching the criteria');
      }

      setCalculatedBatches(result.batches);
      toast.success(`Found ${result.batches.length} batches with ${result.filtered_domains} domains`);
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      }
    }
  };

  const createScheduleMetadata = (batch: BatchResult) => {
    return {
      batchCriteria: batch.criteria_type,
      criteriaValue: batch.criteria,
      batchSize: batch.domains.length,
      oldestVerification: batch.oldest_verification,
      filters: {
        regions: selectedRegions,
        exclude_regions: selectedExcludeRegions,
        registrars,
        exclude_registrars: excludeRegistrars,
        emails,
        exclude_emails: excludeEmails,
        names,
        exclude_names: excludeNames,
        domains,
        exclude_domains: excludeDomains,
        organizations,
        exclude_organizations: excludeOrganizations,
        licenses,
        exclude_licenses: excludeLicenses,
        skip_rdds_check: skipRddsCheck,
        skip_noavsend: skipNoavsend,
      },
    };
  };

  const handleSchedule = async (time: SchedulerTimeValue) => {
    if (!selectedBatch)
      return;

    try {
      if (selectedBatch === calculatedBatches[0] && calculatedBatches.length > 1) {
        // Schedule all batches
        await Promise.all(
          calculatedBatches.map(async (batch) =>
            createEventAsync({
              schedule_at: convertToEST(time).toISOString(),
              payload: {
                domainLists: [batch.domains],
              },
              comment: JSON.stringify(createScheduleMetadata(batch)),
            }),
          ),
        );
        toast.success(`Scheduled ${calculatedBatches.length} batches successfully`);
      } else {
        // Schedule single batch
        await createEventAsync({
          schedule_at: convertToEST(time).toISOString(),
          payload: {
            domainLists: [selectedBatch.domains],
          },
          comment: JSON.stringify(createScheduleMetadata(selectedBatch)),
        });
        toast.success('Batch scheduled successfully');
      }

      navigate('/app/scheduler');
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      }
    }
  };

  const handleScheduleAll = () => {
    if (calculatedBatches.length === 0)
      return;

    setSelectedBatch(calculatedBatches[0]);
  };

  return (
    <Container maxWidth={false} className="pb-12">
      <div className="mb-6">
        <h1 className="text-xl font-bold">Schedule New Batch</h1>
        <p className="text-sm text-muted-foreground">
          Calculate and schedule domain verification batches
        </p>
      </div>

      <div className="mb-8 space-y-6">
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
          <div>
            <Label>Regions</Label>
            <MultiCombobox
              placeholder="Select regions..."
              options={REGIONS}
              selected={selectedRegions}
              onChange={setSelectedRegions}
            />
          </div>
          <div>
            <Label>Exclude Regions</Label>
            <MultiCombobox
              placeholder="Select regions to exclude..."
              options={REGIONS}
              selected={selectedExcludeRegions}
              onChange={setSelectedExcludeRegions}
            />
          </div>
        </div>

        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
          <div>
            <Label>Registrars</Label>
            <MultiCombobox
              placeholder="Select registrars..."
              options={registrarOptions}
              selected={registrars}
              onChange={setRegistrars}
            />
          </div>
          <div>
            <Label>Exclude Registrars</Label>
            <MultiCombobox
              placeholder="Select registrars to exclude..."
              options={registrarOptions}
              selected={excludeRegistrars}
              onChange={setExcludeRegistrars}
            />
          </div>
        </div>

        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
          <div>
            <Label>Emails</Label>
            <ChipInput
              placeholder="Enter emails..."
              defaultValues={emails}
              onValuesChange={setEmails}
            />
          </div>
          <div>
            <Label>Exclude Emails</Label>
            <ChipInput
              placeholder="Enter emails to exclude..."
              defaultValues={excludeEmails}
              onValuesChange={setExcludeEmails}
            />
          </div>
        </div>

        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
          <div>
            <Label>Names</Label>
            <ChipInput
              placeholder="Enter names..."
              defaultValues={names}
              onValuesChange={setNames}
            />
          </div>
          <div>
            <Label>Exclude Names</Label>
            <ChipInput
              placeholder="Enter names to exclude..."
              defaultValues={excludeNames}
              onValuesChange={setExcludeNames}
            />
          </div>
        </div>

        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
          <div>
            <Label>Domains</Label>
            <ChipInput
              placeholder="Enter domains..."
              defaultValues={domains}
              onValuesChange={setDomains}
            />
          </div>
          <div>
            <Label>Exclude Domains</Label>
            <ChipInput
              placeholder="Enter domains to exclude..."
              defaultValues={excludeDomains}
              onValuesChange={setExcludeDomains}
            />
          </div>
        </div>

        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
          <div>
            <Label>Organizations</Label>
            <ChipInput
              placeholder="Enter organizations..."
              defaultValues={organizations}
              onValuesChange={setOrganizations}
            />
          </div>
          <div>
            <Label>Exclude Organizations</Label>
            <ChipInput
              placeholder="Enter organizations to exclude..."
              defaultValues={excludeOrganizations}
              onValuesChange={setExcludeOrganizations}
            />
          </div>
        </div>

        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
          <div>
            <Label>Licenses</Label>
            <ChipInput
              placeholder="Enter licenses (authority:value)..."
              defaultValues={licenses.map((l) => `${l.authority}:${l.value}`)}
              onValuesChange={(values) => {
                setLicenses(
                  values
                    .map((v) => {
                      const [authority, value] = v.split(':');
                      return authority && value ? { authority, value } : null;
                    })
                    .filter((l): l is { authority: string; value: string } => l !== null),
                );
              }}
            />
          </div>
          <div>
            <Label>Exclude Licenses</Label>
            <ChipInput
              placeholder="Enter licenses to exclude (authority:value)..."
              defaultValues={excludeLicenses.map((l) => `${l.authority}:${l.value}`)}
              onValuesChange={(values) => {
                setExcludeLicenses(
                  values
                    .map((v) => {
                      const [authority, value] = v.split(':');
                      return authority && value ? { authority, value } : null;
                    })
                    .filter((l): l is { authority: string; value: string } => l !== null),
                );
              }}
            />
          </div>
        </div>

        <div className="mb-6 flex items-center gap-8">
          <div className="flex items-center gap-4">
            <Switch
              id="skip-rdds"
              checked={skipRddsCheck}
              onCheckedChange={setSkipRddsCheck}
            />
            <Label htmlFor="skip-rdds">Skip RDDS Check</Label>
          </div>
          <div className="flex items-center gap-4">
            <Switch
              id="skip-noavsend"
              checked={skipNoavsend}
              onCheckedChange={setSkipNoavsend}
            />
            <Label htmlFor="skip-noavsend">Skip NOAVSEND</Label>
          </div>
        </div>

        <div className="flex items-end gap-6">
          <div className="flex gap-4">
            <div className="w-[300px]">
              <Label>Batch Criteria</Label>
              <Select value={batchCriteria} onValueChange={(value: BatchCriteria) => setBatchCriteria(value)}>
                <SelectTrigger>
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  {BATCH_CRITERIA.map((criteria) => (
                    <SelectItem key={criteria.value} value={criteria.value}>
                      {criteria.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div className="w-[100px]">
              <Label>Batch Limit</Label>
              <input
                type="number"
                min="1"
                value={batchLimit}
                onChange={(e) => setBatchLimit(Math.max(1, Number.parseInt(e.target.value) || 1))}
                className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
              />
            </div>
          </div>
          <Button onClick={handleCalculate} disabled={isCalculating}>
            Calculate Batches
          </Button>
          {calculatedBatches.length > 0 && (
            <Button
              onClick={handleScheduleAll}
              disabled={isCalculating}
            >
              Schedule All Batches
            </Button>
          )}
        </div>
      </div>

      <div className={isCalculating ? 'opacity-50' : ''}>
        <AGDataGrid
          columnDefs={previewColumnDefs}
          rowData={calculatedBatches}
          onGridReady={handleGridReady}
          context={{ onSchedule: setSelectedBatch, onViewDomains: setViewingDomains }}
          overlayLoadingTemplate={
            isCalculating ? '<span class="ag-overlay-loading-center">Calculating batches...</span>' : undefined
          }
          overlayNoRowsTemplate={
            isCalculating ? '' : '<span class="ag-overlay-no-rows-center">No batches calculated yet</span>'
          }
        />

        <DomainsDialog
          domains={viewingDomains}
          isOpen={viewingDomains.length > 0}
          onClose={() => setViewingDomains([])}
        />
      </div>

      <SchedulerSheet
        batch={selectedBatch}
        isOpen={!!selectedBatch}
        onClose={() => setSelectedBatch(null)}
        onSchedule={handleSchedule}
      />
    </Container>
  );
}

export default SchedulePage;
