import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { ScrollArea } from '@/components/ui/scroll-area';

interface DomainsDialogProps {
  domains: string[];
  isOpen: boolean;
  onClose: () => void;
}

export function DomainsDialog({ domains, isOpen, onClose }: DomainsDialogProps) {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-2xl">
        <DialogHeader>
          <DialogTitle>Domains in Batch</DialogTitle>
        </DialogHeader>
        <ScrollArea className="h-[400px]">
          <div className="space-y-1">
            {domains.map((domain) => (
              <div key={domain} className="rounded-sm p-1 hover:bg-muted">
                {domain}
              </div>
            ))}
          </div>
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
}
