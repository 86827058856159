import { useRef, useState } from 'react';
import type { GridApi, RowDoubleClickedEvent, ColDef } from '@ag-grid-community/core';
import { toast } from 'sonner';
import AGDataGrid from '@/components/ag-data-grid';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { useAddLicense, useRemoveLicense, useContact, useUpdateLicense } from '@/hooks/use-contacts';
import type { Contact, ContactLicense } from '@/hooks/use-contacts';
import { LicenseSheet } from './license-sheet';

const licenseColumnDefs: ColDef<ContactLicense>[] = [
  {
    field: 'authority',
    headerName: 'Authority',
    flex: 1,
    sortable: true,
    filter: true,
  },
  {
    field: 'value',
    headerName: 'License Number',
    flex: 1,
    sortable: true,
    filter: true,
  },
];

interface DomainLicensesProps {
  registrantContact: Contact | undefined;
}

export function DomainLicenses({ registrantContact }: DomainLicensesProps) {
  const [selectedRows, setSelectedRows] = useState<ContactLicense[]>([]);
  const [editingLicense, setEditingLicense] = useState<ContactLicense | null>(null);
  const [isSheetOpen, setIsSheetOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const gridApiRef = useRef<GridApi<ContactLicense> | null>(null);

  const addLicense = useAddLicense();
  const removeLicense = useRemoveLicense();
  const updateLicense = useUpdateLicense();
  const { data: fullContact } = useContact(registrantContact?.id || '');

  if (!registrantContact) {
    return (
      <div className="flex h-[200px] items-center justify-center text-center text-muted-foreground">
        <p>A registrant contact is required to manage licenses.</p>
      </div>
    );
  }

  const handleCreateSubmit = async (data: { authority: string; value: string }) => {
    try {
      await addLicense.mutateAsync({
        contactId: registrantContact.id,
        ...data,
      });
      toast.success('License added successfully');
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      }
      throw error;
    }
  };

  const handleUpdateSubmit = async (data: { authority: string; value: string }) => {
    if (!editingLicense)
      return;

    try {
      await updateLicense.mutateAsync({
        contactId: registrantContact.id,
        licenseId: editingLicense.id,
        ...data,
      });
      toast.success('License updated successfully');
      setEditingLicense(null);
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      }
      throw error;
    }
  };

  const handleDelete = async () => {
    if (selectedRows.length === 0)
      return;

    try {
      await Promise.all(
        selectedRows.map(async (row) =>
          removeLicense.mutateAsync({
            contactId: registrantContact.id,
            licenseId: row.id,
          }),
        ),
      );
      toast.success('Selected licenses deleted successfully');
      setSelectedRows([]);
      setIsDeleteDialogOpen(false);
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      }
    }
  };

  const handleRowDoubleClick = (event: RowDoubleClickedEvent<ContactLicense>) => {
    if (event.data) {
      setEditingLicense(event.data);
      setIsSheetOpen(true);
    }
  };

  const handleSelectionChanged = (event: { api: { getSelectedRows: () => ContactLicense[] } }) => {
    setSelectedRows(event.api.getSelectedRows());
  };

  const handleSheetOpenChange = (open: boolean) => {
    setIsSheetOpen(open);
    if (!open) {
      setEditingLicense(null);
      // Deselect rows when closing the sheet
      if (gridApiRef.current) {
        gridApiRef.current.deselectAll();
      }
    }
  };

  const handleGridReady = (params: { api: GridApi<ContactLicense> }) => {
    gridApiRef.current = params.api;
  };

  const handleDeleteClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsDeleteDialogOpen(true);
  };

  // Use licenses from the full contact data
  const licenses = fullContact?.licenses ?? [];

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h3 className="text-lg font-medium">Licenses</h3>
        <div className="flex items-center gap-4">
          {selectedRows.length > 0 && (
            <Button
              variant="destructive"
              onClick={handleDeleteClick}
              disabled={removeLicense.isPending}
            >
              Delete Selected
            </Button>
          )}
          <Button
            variant="outline"
            onClick={() => setIsSheetOpen(true)}
          >
            Add License
          </Button>
        </div>
      </div>

      <div>
        <AGDataGrid
          columnDefs={licenseColumnDefs}
          rowData={licenses}
          rowSelection={{ mode: 'multiRow' }}
          onRowDoubleClicked={handleRowDoubleClick}
          onSelectionChanged={handleSelectionChanged}
          onGridReady={handleGridReady}
          overlayNoRowsTemplate="No licenses found"
          domLayout="autoHeight"
        />
      </div>

      <LicenseSheet
        open={isSheetOpen}
        onOpenChange={handleSheetOpenChange}
        initialData={editingLicense || undefined}
        onSubmit={editingLicense ? handleUpdateSubmit : handleCreateSubmit}
      />

      <AlertDialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This will permanently delete
              {' '}
              {selectedRows.length}
              {' '}
              selected
              {' '}
              {selectedRows.length === 1 ? 'license' : 'licenses'}
              .
              This action cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={handleDelete}
              className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
            >
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
}
