import React, { useState } from 'react';
import { Button } from '@/components/ui/button';
import { MessagesSquare, LucideIcon } from 'lucide-react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { cn } from '@/lib/utils';

type Position = 'bottom-right' | 'bottom-left' | 'top-right' | 'top-left';

interface FloatingActionButtonProps {
  icon?: LucideIcon;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  position?: Position;
  className?: string;
  animate?: boolean;
  tooltip?: string;
}

const positionClasses: Record<Position, string> = {
  'bottom-right': 'bottom-6 right-6',
  'bottom-left': 'bottom-6 left-6',
  'top-right': 'top-6 right-6',
  'top-left': 'top-6 left-6',
};

const FloatingActionButton = ({
  icon: Icon = MessagesSquare,
  onClick,
  position = 'bottom-right',
  className,
  animate = true,
  tooltip,
}: FloatingActionButtonProps) => {
  const [isAnimating, setIsAnimating] = useState(false);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (animate) {
      setIsAnimating(true);
      // Reset animation state after animation completes
      setTimeout(() => setIsAnimating(false), 600);
    }
    onClick?.(e);
  };

  const FabButton = (
    <Button
      onClick={handleClick}
      size="icon"
      className={cn(
        // Base styles
        'fixed rounded-full w-14 h-14 p-0 shadow-lg hover:shadow-xl [&_svg]:size-6',
        // Animation styles
        'transition-all duration-500',
        isAnimating && 'scale-[100] opacity-0',
        // Position styles
        positionClasses[position],
        className
      )}
    >
      <Icon 
        className={cn(
          isAnimating && 'scale-0 opacity-0 transition-all duration-400'
        )}
      />
    </Button>
  );

  if (tooltip) {
    return (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            {FabButton}
          </TooltipTrigger>
          <TooltipContent 
            side="top" 
            align="center"
          >
            {tooltip}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  }

  return FabButton;
};

export { FloatingActionButton };
