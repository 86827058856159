import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import type { GridApi } from '@ag-grid-community/core';
import { Plus, RotateCcw } from 'lucide-react';
import { toast } from 'sonner';
import AGDataGrid from '@/components/ag-data-grid';
import Container from '@/components/container';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { useGrid } from '@/contexts/grid-context';
import { useSchedulerEvents, useDeleteSchedulerEvent, type SchedulerEvent } from '@/hooks/use-scheduler';
import { schedulerColumnDefs } from './components/scheduler-grid-columns';

function SchedulerPage() {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const gridApiRef = useRef<GridApi<SchedulerEvent> | null>(null);
  const { registerGridApi } = useGrid();

  const { data: schedulerData, isLoading, error } = useSchedulerEvents();
  const { deleteEventAsync, isDeleting } = useDeleteSchedulerEvent();

  // Show error toast if the query fails
  if (error instanceof Error) {
    toast.error(error.message);
  }

  const handleGridReady = (params: { api: GridApi<SchedulerEvent> }) => {
    gridApiRef.current = params.api;
    registerGridApi(params.api);
  };

  const clearFilters = () => {
    if (gridApiRef.current) {
      gridApiRef.current.setFilterModel(null);
      setSearchQuery('');
    }
  };

  const handleDelete = async (id: string) => {
    try {
      await deleteEventAsync(id);
      toast.success('Batch deleted successfully');
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      }
    }
  };

  return (
    <Container maxWidth={false} className="pb-12">
      <div className="mb-3 flex justify-between">
        <div>
          <h1 className="mt-2 text-xl font-bold">Scheduled Batches</h1>
          <p className="text-sm text-muted-foreground">
            View and manage scheduled domain verification batches
          </p>
        </div>
        <div className="flex items-center gap-4">
          <Button
            onClick={() => navigate('/app/scheduler/schedule')}
            className="gap-2"
          >
            <Plus className="size-4" />
            Schedule New Batch
          </Button>
        </div>
      </div>

      <div className="mb-6 flex gap-4">
        <div className="flex-1">
          <Input
            type="search"
            placeholder="Search scheduled batches..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <Button
          variant="outline"
          onClick={clearFilters}
          className="gap-2"
        >
          <RotateCcw className="size-4" />
          Clear Filters
        </Button>
      </div>

      <div className={isLoading || isDeleting ? 'opacity-50' : ''}>
        <AGDataGrid
          columnDefs={schedulerColumnDefs}
          rowData={schedulerData?.events || []}
          quickFilterText={searchQuery}
          onGridReady={handleGridReady}
          context={{ onDelete: handleDelete }}
          overlayLoadingTemplate={
            isLoading ? '<span class="ag-overlay-loading-center">Loading...</span>' : undefined
          }
          overlayNoRowsTemplate={
            isLoading ? '' : '<span class="ag-overlay-no-rows-center">No scheduled batches found</span>'
          }
        />
      </div>
    </Container>
  );
}

export default SchedulerPage;
