import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { WebhookTriggers } from '@/types/nylas';
import useAxios from './use-axios';

/**
 * Interface representing a Nylas webhook configuration
 */
export type Webhook = {
  id: string;
  triggerTypes: WebhookTriggers[];
  webhookUrl: string;
  description?: string;
  notificationEmailAddresses?: string[];
}

/**
 * Parameters for creating a new webhook
 */
export type CreateWebhookParams = {
  triggerTypes: WebhookTriggers[];
  webhookUrl: string;
  description?: string;
  notificationEmailAddresses?: string[];
}

/**
 * Query keys for Tanstack Query cache management
 */
export const NYLAS_QUERY_KEYS = {
  webhooks: {
    all: ['nylasWebhooks'] as const,
    list: () => [...NYLAS_QUERY_KEYS.webhooks.all] as const,
    detail: (id: string) => [...NYLAS_QUERY_KEYS.webhooks.all, id] as const,
  },
} as const;

/**
 * Hook to fetch all registered Nylas webhooks
 * @returns Query result containing array of webhook configurations
 */
export function useNylasWebhooks() {
  const axios = useAxios();

  return useQuery({
    queryKey: NYLAS_QUERY_KEYS.webhooks.list(),
    queryFn: async () => {
      const { data } = await axios.get<Webhook[]>('/nylas/webhooks');
      return data;
    },
  });
}

/**
 * Hook to create a new Nylas webhook
 * @returns Mutation function and state for creating webhooks
 */
export function useCreateNylasWebhook() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params: CreateWebhookParams) => {
      const { data } = await axios.post<Webhook>('/nylas/webhooks', params);
      return data;
    },
    onSuccess: () => {
      toast.success('Webhook created successfully');
      queryClient.invalidateQueries({
        queryKey: NYLAS_QUERY_KEYS.webhooks.list(),
      });
    },
    onError: (error: Error) => {
      toast.error(`Failed to create webhook: ${error.message}`);
    },
  });
}

/**
 * Hook to delete a Nylas webhook
 * @returns Mutation function and state for deleting webhooks
 */
export function useDeleteNylasWebhook() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (webhookId: string) => {
      await axios.delete(`/nylas/webhooks/${webhookId}`);
    },
    onSuccess: () => {
      toast.success('Webhook deleted successfully');
      queryClient.invalidateQueries({
        queryKey: NYLAS_QUERY_KEYS.webhooks.list(),
      });
    },
    onError: (error: Error) => {
      toast.error(`Failed to delete webhook: ${error.message}`);
    },
  });
}

/**
 * Hook to update an existing Nylas webhook
 * @returns Mutation function and state for updating webhooks
 */
export function useUpdateNylasWebhook() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ webhookId, ...params }: { webhookId: string } & Partial<CreateWebhookParams>) => {
      const { data } = await axios.put<Webhook>(`/nylas/webhooks/${webhookId}`, params);
      return data;
    },
    onSuccess: () => {
      toast.success('Webhook updated successfully');
      queryClient.invalidateQueries({
        queryKey: NYLAS_QUERY_KEYS.webhooks.list(),
      });
    },
    onError: (error: Error) => {
      toast.error(`Failed to update webhook: ${error.message}`);
    },
  });
}
