import * as React from 'react';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import type { CommunicationDisplay } from '../types/communications';

type InternalMessageProps = {
  message: CommunicationDisplay;
  isSelected?: boolean;
  onClick?: (messageId: string) => void;
};

export function InternalMessage({ message, isSelected, onClick }: InternalMessageProps) {
  return (
    <button
      type="button"
      onClick={() => onClick?.(message.id)}
      className={`w-full cursor-pointer text-left ${isSelected ? 'bg-accent' : ''}`}
    >
      <div className="flex gap-3 rounded-lg p-3 hover:bg-accent">
        <Avatar className="size-8">
          {message.sender.avatar
            ? (
              <AvatarImage src={message.sender.avatar} alt={message.sender.name} />
            )
            : null}
          <AvatarFallback>{message.sender.name.charAt(0)}</AvatarFallback>
        </Avatar>
        <div className="flex-1">
          <div className="flex items-baseline justify-between">
            <span className="text-sm font-medium">{message.sender.name}</span>
            <span className="text-xs text-muted-foreground">
              {new Date(message.timestamp).toLocaleTimeString('en-US', {
                hour: 'numeric',
                minute: '2-digit',
                hour12: true,
              })}
            </span>
          </div>
          <div className="line-clamp-2 text-sm text-muted-foreground">
            {message.snippet}
          </div>
        </div>
      </div>
    </button>
  );
}
