import { useMutation } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { useAxios } from './use-axios';

const API_BASE_URL = '/trade';

interface ApiError {
  error?: string;
  details?: Array<{ message: string }>;
}

export interface TradeAddress {
  address: string;
  city: string;
  state: string;
  postal_code: string;
  country: string;
}

export interface TradeSearchResult {
  id: string;
  name: string;
  alt_names: string[];
  call_sign: string;
  citizenships: string[];
  country: string | null;
  dates_of_birth: string[];
  end_date: string | null;
  entity_number: string | null;
  federal_register_notice: string;
  gross_registered_tonnage: string;
  gross_tonnage: string;
  license_policy: string;
  license_requirement: string;
  nationalities: string[];
  places_of_birth: string[];
  programs: string[];
  remarks: string;
  source: string;
  source_information_url: string;
  source_list_url: string;
  standard_order: string | null;
  start_date: string;
  title: string | null;
  type: string | null;
  vessel_flag: string;
  vessel_owner: string;
  vessel_type: string;
  addresses: TradeAddress[];
  ids: string[];
}

export interface TradeSearchResponse {
  results: {
    total: number;
    results: TradeSearchResult[];
  } | string;
}

function getErrorMessage(error: unknown): string {
  if (isAxiosError<ApiError>(error)) {
    // Handle validation errors
    if (error.response?.data?.details) {
      return 'Please check your input and try again';
    }

    // Handle specific error codes
    if (error.response?.status === 500) {
      return 'An internal server error occurred. Please try again later';
    }

    if (error.response?.status === 401 || error.response?.status === 403) {
      return 'You do not have permission to perform this action';
    }

    if (error.response?.status === 404) {
      return 'The requested resource was not found';
    }

    // Handle network errors
    if (error.message === 'Network Error') {
      return 'Unable to connect to the server. Please check your internet connection';
    }

    // Handle specific Trade.gov API error
    if (error.response?.status === 400) {
      return error.response.data.error || 'Invalid search request';
    }
  }

  return 'An unexpected error occurred. Please try again';
}

// Search Trade.gov data
export function useTradeSearch() {
  const axios = useAxios();

  return useMutation({
    mutationFn: async (entity: string) => {
      try {
        const { data } = await axios.post<TradeSearchResponse>(`${API_BASE_URL}/search`, {
          entity,
        });
        return data;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
  });
}
