import * as React from 'react';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import type { CommunicationDisplay } from '../types/communications';

type MessageHeaderProps = {
  sender: CommunicationDisplay['sender'];
  timestamp: string | Date;
  isExternal?: boolean;
  isEmail?: boolean;
  emailTo?: string[];
  emailSubject?: string;
};

export function MessageHeader({
  sender,
  timestamp,
  isExternal,
  isEmail,
  emailTo,
  emailSubject,
}: MessageHeaderProps) {
  return (
    <div className="flex items-start gap-3">
      <Avatar className="size-8">
        <AvatarImage
          src={sender.avatar || ''}
          alt={sender.name || sender.email}
        />
        <AvatarFallback>{sender.name.charAt(0)}</AvatarFallback>
      </Avatar>
      <div className="flex-1">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <span className="font-semibold">{sender.name}</span>
            {isExternal && (
              <span className="rounded-full bg-blue-100 px-2 py-0.5 text-xs text-blue-800">
                External
              </span>
            )}
            {isEmail && (
              <span className="rounded-full bg-purple-100 px-2 py-0.5 text-xs text-purple-800">
                Email
              </span>
            )}
          </div>
          <span className="text-sm text-muted-foreground">
            {new Date(timestamp).toLocaleString()}
          </span>
        </div>
        <span className="text-sm text-muted-foreground">
          {sender.email}
        </span>
        {isEmail && (
          <div className="mt-1 space-y-1 text-sm text-muted-foreground">
            <div>
              To:
              {' '}
              {emailTo?.join(', ')}
            </div>
            <div>
              Subject:
              {' '}
              {emailSubject}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
