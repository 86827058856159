import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import { X } from 'lucide-react';
import { cn } from '@/lib/utils';

interface ChipInputProps {
  /** Initial values to populate the input */
  defaultValues?: string[];
  /** Callback when the list of values changes */
  onValuesChange?: (values: string[]) => void;
  /** Callback when a new value is added */
  onValueAdd?: (value: string) => void;
  /** Callback when a value is removed */
  onValueRemove?: (value: string, index: number) => void;
  /** Whether the input is disabled */
  disabled?: boolean;
  /** Character(s) or regex that trigger value creation */
  delimiter?: string | RegExp;
  /** Placeholder text when no values are entered */
  placeholder?: string;
  /** Optional validation function */
  validate?: (value: string) => boolean;
  /** Optional error message */
  error?: string;
  /** Optional className */
  className?: string;
}

/** Methods exposed via the forwarded ref */
export interface ChipInputHandle {
  /** Focus the input field */
  focus: () => void;
  /** Clear all values and input */
  clear: () => void;
  /** Add a new value */
  addValue: (value: string) => boolean;
  /** Remove a value by index */
  removeValue: (index: number) => void;
  /** Get current list of values */
  getValues: () => string[];
}

const DEFAULT_VALUES: string[] = [];
const DEFAULT_DELIMITER = /[,;\s]/;

const ChipInput = forwardRef<ChipInputHandle, ChipInputProps>(({
  defaultValues = DEFAULT_VALUES,
  onValuesChange,
  onValueAdd,
  onValueRemove,
  disabled = false,
  delimiter = DEFAULT_DELIMITER,
  placeholder = 'Enter values...',
  validate = (value: string) => value.length > 0,
  error,
  className,
}, ref) => {
  const [values, setValues] = useState<string[]>(defaultValues);
  const [inputValue, setInputValue] = useState('');
  const [selectedChipIndex, setSelectedChipIndex] = useState<number | null>(null);

  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  /**
   * Removes a value chip at the specified index and updates state
   */
  const removeValue = useCallback((indexToRemove: number) => {
    const valueToRemove = values[indexToRemove];
    const newValues = values.filter((_, idx) => idx !== indexToRemove);

    setValues(newValues);
    onValuesChange?.(newValues);
    onValueRemove?.(valueToRemove, indexToRemove);

    setSelectedChipIndex(null);
    inputRef.current?.focus();
  }, [values, onValuesChange, onValueRemove]);

  /**
   * Attempts to add a new value chip. Returns true if successful.
   */
  const addValue = useCallback((text: string): boolean => {
    const value = text.trim();
    if (!value || !validate(value))
      return false;

    setValues((prev) => {
      const newValues = [...prev, value];
      onValuesChange?.(newValues);
      onValueAdd?.(value);
      return newValues;
    });
    setInputValue('');
    return true;
  }, [onValuesChange, onValueAdd, validate]);

  // Expose methods via ref
  useImperativeHandle(ref, () => ({
    focus: () => {
      inputRef.current?.focus();
    },
    clear: () => {
      setValues([]);
      setInputValue('');
      setSelectedChipIndex(null);
      onValuesChange?.([]);
    },
    addValue,
    removeValue,
    getValues: () => values,
  }), [values, addValue, removeValue, onValuesChange]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled)
      return;

    const { value } = e.target;
    setInputValue(value);
    setSelectedChipIndex(null);

    const lastChar = value.at(-1);
    if (lastChar && (delimiter instanceof RegExp ? delimiter.test(lastChar) : lastChar === delimiter)) {
      const valueToTry = value.slice(0, -1).trim();
      if (valueToTry && addValue(valueToTry)) {
        setInputValue('');
      }
    }
  };

  const handleBlur = useCallback(() => {
    if (disabled)
      return;
    const trimmed = inputValue.trim();
    if (trimmed && validate(trimmed)) {
      addValue(trimmed);
    }
  }, [disabled, inputValue, addValue, validate]);

  const handlePaste = useCallback((e: React.ClipboardEvent) => {
    if (disabled)
      return;

    e.preventDefault();
    const pastedText = e.clipboardData.getData('text');
    const potentialValues = pastedText.split(/[,;\s]+/).filter(Boolean);

    const invalidValues: string[] = [];
    for (const value of potentialValues) {
      if (!addValue(value)) {
        invalidValues.push(value);
      }
    }

    if (invalidValues.length > 0) {
      setInputValue((prev) => {
        const combined = [prev, ...invalidValues].filter(Boolean).join(' ');
        return combined;
      });
    }
  }, [disabled, addValue]);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (disabled)
      return;

    if (e.key === 'Enter') {
      e.preventDefault();
      const trimmed = inputValue.trim();
      if (trimmed) {
        const success = addValue(trimmed);
        if (!success) {
          setInputValue(trimmed);
        }
      }
      return;
    }

    if (e.key === 'Backspace' && values.length > 0) {
      const isCursorAtStart = inputRef.current?.selectionStart === 0;
      const hasSelection = inputRef.current?.selectionStart !== inputRef.current?.selectionEnd;

      if (!hasSelection && ((inputValue && isCursorAtStart) || !inputValue)) {
        e.preventDefault();
        if (selectedChipIndex === null) {
          const lastIndex = values.length - 1;
          setSelectedChipIndex(lastIndex);
        } else {
          removeValue(selectedChipIndex);
          setSelectedChipIndex(null);
        }
      }
      return;
    }

    if (e.key === 'Delete' && selectedChipIndex !== null) {
      e.preventDefault();
      removeValue(selectedChipIndex);
      setSelectedChipIndex(null);
      return;
    }

    if (e.key === 'ArrowLeft') {
      if (selectedChipIndex === null && inputRef.current?.selectionStart === 0 && values.length > 0) {
        e.preventDefault();
        const newIndex = values.length - 1;
        setSelectedChipIndex(newIndex);
      } else if (selectedChipIndex !== null && selectedChipIndex > 0) {
        e.preventDefault();
        const newIndex = selectedChipIndex - 1;
        setSelectedChipIndex(newIndex);
      }
      return;
    }

    if (e.key === 'ArrowRight' && selectedChipIndex !== null) {
      e.preventDefault();
      if (selectedChipIndex < values.length - 1) {
        const newIndex = selectedChipIndex + 1;
        setSelectedChipIndex(newIndex);
      } else {
        setSelectedChipIndex(null);
        inputRef.current?.focus();
      }
    }
  };

  const handleContainerClick = () => {
    if (!disabled) {
      inputRef.current?.focus();
      if (selectedChipIndex !== null) {
        setSelectedChipIndex(null);
      }
    }
  };

  const handleChipClick = (index: number, e: React.MouseEvent) => {
    if (disabled)
      return;
    e.stopPropagation();
    const newIndex = selectedChipIndex === index ? null : index;
    setSelectedChipIndex(newIndex);
    inputRef.current?.focus();
  };

  return (
    <div
      ref={containerRef}
      className={cn(
        'w-full max-w-2xl mx-auto',
        disabled && 'opacity-50 cursor-not-allowed',
        className,
      )}
    >
      <div
        className={cn(
          'group/container flex flex-wrap items-center gap-1 rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background',
          'outline-none ring-0 transition-none',
          'has-[:focus]:ring-1 has-[:focus]:ring-ring has-[:focus]:ring-offset-0',
          'hover:border-input',
          !disabled && 'cursor-text',
          selectedChipIndex !== null && 'ring-1 ring-ring ring-offset-0',
          error && 'border-red-500',
        )}
        onClick={handleContainerClick}
      >
        {values.map((value, index) => (
          <div
            key={index}
            onClick={(e) => handleChipClick(index, e)}
            className={cn(
              'flex cursor-pointer select-none items-center gap-1 rounded-full px-1.5 py-0.5 text-sm transition-all duration-150',
              selectedChipIndex === index
                ? 'bg-blue-200 text-blue-900 ring-2 ring-blue-400'
                : 'bg-blue-100 text-blue-800 hover:bg-blue-200 hover:text-blue-900',
              disabled && 'opacity-50 cursor-not-allowed',
            )}
          >
            <span className="px-0.5">{value}</span>
            {!disabled && (
              <button
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  removeValue(index);
                }}
                className={cn(
                  'rounded-full p-0.5 transition-all duration-150',
                  'hover:bg-blue-300/80 active:bg-blue-400',
                  'hover:scale-110 active:scale-90',
                  'outline-none focus:ring-2 focus:ring-blue-400',
                )}
                aria-label="Remove value"
              >
                <X size={12} />
              </button>
            )}
          </div>
        ))}
        <input
          ref={inputRef}
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onPaste={handlePaste}
          onBlur={handleBlur}
          disabled={disabled}
          className={cn(
            'flex-1 min-w-[100px] h-auto border-0 bg-transparent p-0',
            'outline-none focus:outline-none focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0',
            'placeholder:text-muted-foreground',
          )}
          placeholder={values.length === 0 ? placeholder : ''}
        />
      </div>
      {error && (
        <p className="mt-1 text-xs text-red-500">{error}</p>
      )}
    </div>
  );
});

ChipInput.displayName = 'ChipInput';

export type { ChipInputProps };
export default ChipInput;
