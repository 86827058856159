import * as React from 'react';
import { hasExternalEmail } from '@/lib/messages';
import { getThreadTimestamp } from '@/lib/threads';
import { truncateText } from '@/lib/utils';
import type { CommunicationDisplay } from '@/pages/app/deals/communications/types/communications';
import type { NylasThread } from '@/types/thread';

/**
 * Hook to parse Nylas threads into CommunicationDisplay format for inbox viewing
 */
export function useInboxMessageParser(threads: NylasThread[] = []) {
  return React.useMemo(() => {
    // Convert threads to display format
    const threadDisplays = threads.map((thread): CommunicationDisplay => {
      const firstParticipant = thread.participants?.[0] || { name: 'Unknown', email: '' };

      return {
        id: thread.id,
        snippet: truncateText(thread.snippet || '', 160),
        sender: {
          name: firstParticipant.name || firstParticipant.email,
          email: firstParticipant.email,
        },
        timestamp: getThreadTimestamp(thread),
        isExternal: hasExternalEmail(thread.participants),
        isEmail: true,
        participants: thread.participants,
        emailMessageIds: thread.messageIds,
        emailSubject: thread.subject,
      };
    });

    // Sort by date (latest to earliest)
    return threadDisplays.sort((a, b) =>
      new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime(),
    );
  }, [threads]);
}
