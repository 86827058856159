import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import type { Content } from '@tiptap/react';
import debounce from 'lodash.debounce';
import { Pencil, Plus, Trash2 } from 'lucide-react';
import { toast } from 'sonner';
import AGDataGrid from '@/components/ag-data-grid';
import { ConfirmationDialog } from '@/components/confirmation-dialog';
import Container from '@/components/container';
import { TiptapEditor } from '@/components/tiptap';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useNylasGrants } from '@/hooks/use-nylas-grants';
import { useNylasWebhooks, useDeleteNylasWebhook, type Webhook } from '@/hooks/use-nylas-webhooks';
import { useUserTemplates } from '@/hooks/use-user-templates';
import { convertToEmailHtml } from '@/lib/email';
import { nylasColumnDefs, webhookColumnDefs } from './components/column-defs';
import CreateConnectionSheet from './components/create-connection-sheet';
import WebhookSheetEditor from './components/webhook-sheet-editor';

function SettingsPage() {
  const [searchParams] = useSearchParams();
  
  // Check URL parameters for success message
  useEffect(() => {
    const success = searchParams.get('success');
    if (success === 'true') {
      toast.success('Successfully added email connection!');
    } else if (success === 'false') {
      toast.error('Adding email connection was unsuccessful.');
    }
  }, [searchParams]);

  // Email signature state
  const {
    template: personalFooter,
    updateTemplate,
    createTemplate,
    isLoading: isUserTemplateLoading,
    error: templateError,
  } = useUserTemplates({
    name: 'personal-footer',
  });
  const editorContent = personalFooter?.template || '<p></p>';

  // Show error toast if template fetch fails
  useEffect(() => {
    if (templateError) {
      toast.error('Failed to load signature template', {
        description: templateError.message,
      });
    }
  }, [templateError]);

  // Create a debounced update function
  const debouncedUpdate = useCallback(
    debounce(async (content: string) => {
      if (!isUserTemplateLoading) {
        try {
          const processedHtml = convertToEmailHtml(content);
          await (personalFooter
            ? updateTemplate({ id: personalFooter.id, template: processedHtml })
            : createTemplate({ name: 'personal-footer', template: processedHtml })
          );
          toast.success('Signature updated successfully');
        } catch (error) {
          toast.error('Failed to update signature', {
            description: error instanceof Error ? error.message : 'An unknown error occurred',
          });
        }
      }
    }, 1000),
    [updateTemplate, createTemplate, personalFooter, isUserTemplateLoading],
  );

  // Handle signature changes by calling the debounced update function
  const handleSignatureChange = useCallback(
    (content: Content) => {
      if (typeof content === 'string') {
        void debouncedUpdate(content);
      }
    },
    [debouncedUpdate],
  );

  // Cleanup debounced function on unmount
  useEffect(() => {
    return () => {
      debouncedUpdate.cancel();
    };
  }, [debouncedUpdate]);

  // State for Connections tab
  const [searchQuery, setSearchQuery] = useState('');
  const [isCreateSheetOpen, setIsCreateSheetOpen] = useState(false);
  const [selectedGrant, setSelectedGrant] = useState<any | null>(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const { data: grants, isLoading: isGrantLoading, deleteGrant, isDeletingGrant } = useNylasGrants();

  // State for Webhooks tab
  const [webhookSearchQuery, setWebhookSearchQuery] = useState('');
  const [isCreateWebhookSheetOpen, setIsCreateWebhookSheetOpen] = useState(false);
  const [selectedWebhook, setSelectedWebhook] = useState<Webhook | null>(null);
  const [isDeleteWebhookDialogOpen, setIsDeleteWebhookDialogOpen] = useState(false);
  const [isEditWebhookSheetOpen, setIsEditWebhookSheetOpen] = useState(false);
  const { data: webhooks, isLoading: isLoadingWebhooks } = useNylasWebhooks();
  const { mutate: deleteWebhook, isPending: isDeletingWebhook } = useDeleteNylasWebhook();

  // Connections handlers
  const handleConnectionSelectionChanged = (event: any) => {
    const selectedRows = event.api.getSelectedRows();
    setSelectedGrant(selectedRows.length === 1 ? selectedRows[0] : null);
  };

  const handleDeleteConnection = async () => {
    if (selectedGrant) {
      deleteGrant(selectedGrant.id);
      setSelectedGrant(null);
      setIsDeleteDialogOpen(false);
    }
  };

  // Webhooks handlers
  const handleWebhookSelectionChanged = (event: any) => {
    const selectedRows = event.api.getSelectedRows();
    setSelectedWebhook(selectedRows.length === 1 ? selectedRows[0] : null);
  };

  const handleDeleteWebhook = () => {
    if (selectedWebhook) {
      deleteWebhook(selectedWebhook.id, {
        onSuccess: () => {
          setSelectedWebhook(null);
          setIsDeleteWebhookDialogOpen(false);
        },
      });
    }
  };

  return (
    <Container className="mt-8 pb-12">
      <div className="mb-8 flex justify-between">
        <div>
          <h1 className="text-xl font-bold">Settings</h1>
        </div>
      </div>

      <Card className="mb-8">
        <CardHeader>
          <CardTitle>Email Signature</CardTitle>
        </CardHeader>
        <CardContent className="flex flex-col space-y-2">
          {isUserTemplateLoading
            ? (
              <div className="flex h-64 items-center justify-center rounded-md border bg-muted/50">
                <p className="text-sm text-muted-foreground">Loading signature...</p>
              </div>
            )
            : (
              <TiptapEditor
                className="h-64"
                value={editorContent}
                onChange={handleSignatureChange}
                placeholder="Enter your email signature..."
                hideSections={['one', 'tasks']}
                editorContentClassName="prose prose-sm max-w-none focus:outline-none overflow-y-auto px-3 py-2"
              />
            )}
          <CardDescription>
            Your email signature will be available in templates using the
            {' '}
            <span className="font-mono">{'{{PERSONALFOOTER}}'}</span>
            {' '}
            variable.
          </CardDescription>
        </CardContent>
      </Card>

      <Tabs defaultValue="connections" className="space-y-4">
        <TabsList>
          <TabsTrigger value="connections">Connections</TabsTrigger>
          <TabsTrigger value="webhooks">Web Hooks</TabsTrigger>
        </TabsList>

        <TabsContent value="connections">
          <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-4">
              <CardTitle>Email Connections</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="mb-4">
                <Input
                  type="search"
                  placeholder="Search connections..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
              <AGDataGrid
                columnDefs={nylasColumnDefs}
                rowData={grants}
                quickFilterText={searchQuery}
                rowSelection={{ mode: 'singleRow' }}
                onSelectionChanged={handleConnectionSelectionChanged}
                loadingOverlayComponent={isGrantLoading ? 'Loading...' : undefined}
              />
              <div className="mt-4 flex justify-end gap-2">
                <Button
                  className="ml-auto"
                  onClick={() => setIsCreateSheetOpen(true)}
                  size="sm"
                >
                  <Plus className="mr-2 size-4" />
                  Add New Connection
                </Button>
                <Button
                  variant="destructive"
                  onClick={() => setIsDeleteDialogOpen(true)}
                  size="sm"
                  disabled={!selectedGrant || isDeletingGrant}
                >
                  <Trash2 className="mr-2 size-4" />
                  Delete Connection
                </Button>
              </div>
            </CardContent>
          </Card>
        </TabsContent>

        <TabsContent value="webhooks">
          <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-4">
              <CardTitle>Active Webhooks</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="mb-4">
                <Input
                  type="search"
                  placeholder="Search webhooks..."
                  value={webhookSearchQuery}
                  onChange={(e) => setWebhookSearchQuery(e.target.value)}
                />
              </div>
              <AGDataGrid
                columnDefs={webhookColumnDefs}
                rowData={webhooks}
                quickFilterText={webhookSearchQuery}
                rowSelection={{ mode: 'singleRow' }}
                onSelectionChanged={handleWebhookSelectionChanged}
                onRowDoubleClicked={() => {
                  if (selectedWebhook) {
                    setIsEditWebhookSheetOpen(true);
                  }
                }}
                loadingOverlayComponent={isLoadingWebhooks ? 'Loading...' : undefined}
              />
              <div className="mt-4 flex justify-end gap-2">
                {/* <Button
                  className="ml-auto"
                  onClick={() => setIsCreateWebhookSheetOpen(true)}
                  size="sm"
                >
                  <Plus className="mr-2 size-4" />
                  Add New Webhook
                </Button> */}
                <Button
                  onClick={() => setIsEditWebhookSheetOpen(true)}
                  size="sm"
                  disabled={!selectedWebhook}
                >
                  <Pencil className="mr-2 size-4" />
                  Edit Webhook
                </Button>
                <Button
                  variant="destructive"
                  onClick={() => setIsDeleteWebhookDialogOpen(true)}
                  size="sm"
                  disabled={!selectedWebhook || isDeletingWebhook}
                >
                  <Trash2 className="mr-2 size-4" />
                  Delete Webhook
                </Button>
              </div>
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>

      {/* Sheets */}
      <CreateConnectionSheet
        open={isCreateSheetOpen}
        onOpenChange={setIsCreateSheetOpen}
      />

      <WebhookSheetEditor
        open={isCreateWebhookSheetOpen}
        onOpenChange={setIsCreateWebhookSheetOpen}
      />

      <WebhookSheetEditor
        open={isEditWebhookSheetOpen}
        onOpenChange={setIsEditWebhookSheetOpen}
        webhook={selectedWebhook}
      />

      {/* Dialogs */}
      <ConfirmationDialog
        open={isDeleteDialogOpen}
        onOpenChange={setIsDeleteDialogOpen}
        title="Delete Connection"
        description="Are you sure you want to delete this connection? This action cannot be undone."
        onConfirm={handleDeleteConnection}
        confirmText={isDeletingGrant ? 'Deleting...' : 'Delete'}
        confirmVariant="destructive"
        confirmButtonClassName="bg-destructive text-destructive-foreground hover:bg-destructive/90"
      />

      <ConfirmationDialog
        open={isDeleteWebhookDialogOpen}
        onOpenChange={setIsDeleteWebhookDialogOpen}
        title="Delete Webhook"
        description="Are you sure you want to delete this webhook? This action cannot be undone."
        onConfirm={handleDeleteWebhook}
        confirmText={isDeletingWebhook ? 'Deleting...' : 'Delete'}
        confirmVariant="destructive"
        confirmButtonClassName="bg-destructive text-destructive-foreground hover:bg-destructive/90"
      />
    </Container>
  );
}

export default SettingsPage;
