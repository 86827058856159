import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import type { Contact } from './use-contacts';
import type { Domain, UpdateDomainInput } from '../types/domain';
import { useAxios } from './use-axios';

const API_BASE_URL = '/domains';

export const DOMAINS_QUERY_KEY = ['domains'];
export const DOMAIN_CONTACTS_QUERY_KEY = 'contacts';
export const REGISTRARS_QUERY_KEY = ['domains', 'registrars'];

interface ApiError {
  error?: {
    message?: string;
    status?: number;
  };
  details?: Array<{ message: string }>;
}

function getErrorMessage(error: unknown): string {
  if (isAxiosError<ApiError>(error)) {
    // Handle validation errors
    if (error.response?.data?.details) {
      return 'Please check your input and try again';
    }

    // Handle specific error codes
    if (error.response?.status === 500) {
      return 'An internal server error occurred. Please try again later';
    }

    if (error.response?.status === 401 || error.response?.status === 403) {
      return 'You do not have permission to perform this action';
    }

    if (error.response?.status === 404) {
      return 'The requested resource was not found';
    }

    // Handle network errors
    if (error.message === 'Network Error') {
      return 'Unable to connect to the server. Please check your internet connection';
    }
  }

  return 'An unexpected error occurred. Please try again';
}

// List all domains
export function useDomains() {
  const axios = useAxios();

  return useQuery({
    queryKey: DOMAINS_QUERY_KEY,
    queryFn: async () => {
      try {
        const { data } = await axios.get<Domain[]>(API_BASE_URL);
        return data;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
  });
}

// Get a single domain by name
export function useDomain(name: string) {
  const axios = useAxios();

  return useQuery({
    queryKey: [...DOMAINS_QUERY_KEY, name],
    queryFn: async () => {
      try {
        const { data } = await axios.get<Domain>(`${API_BASE_URL}/${name}`);
        return data;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    enabled: !!name,
  });
}

// Get domain contacts
export function useDomainContacts(name: string) {
  const axios = useAxios();

  return useQuery({
    queryKey: [...DOMAINS_QUERY_KEY, name, DOMAIN_CONTACTS_QUERY_KEY],
    queryFn: async () => {
      try {
        const { data } = await axios.get<Contact[]>(`${API_BASE_URL}/${name}/contacts`);
        return data;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    enabled: !!name,
  });
}

// Update a domain
export function useUpdateDomain() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ name, ...updateData }: { name: string } & UpdateDomainInput) => {
      try {
        const { data } = await axios.put<Domain>(`${API_BASE_URL}/${name}`, updateData);
        return data;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    onSuccess: async (_, { name }) => {
      await queryClient.invalidateQueries({ queryKey: [...DOMAINS_QUERY_KEY, name] });
      await queryClient.invalidateQueries({ queryKey: DOMAINS_QUERY_KEY });
    },
  });
}

// Get all registrars
export function useRegistrars() {
  const axios = useAxios();

  return useQuery({
    queryKey: REGISTRARS_QUERY_KEY,
    queryFn: async () => {
      try {
        const { data } = await axios.get<string[]>(`${API_BASE_URL}/registrars`);
        return data;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
  });
}

// Update multiple domains
export function useUpdateDomains() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ domains, updateData }: { domains: string[]; updateData: UpdateDomainInput }) => {
      try {
        const results = await Promise.all(
          domains.map(async (name) =>
            axios.put<Domain>(`${API_BASE_URL}/${name}`, updateData),
          ),
        );
        return results.map((r) => r.data);
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    onSuccess: async (_, { domains }) => {
      await Promise.all([
        ...domains.map(async (name) =>
          queryClient.invalidateQueries({ queryKey: [...DOMAINS_QUERY_KEY, name] }),
        ),
        queryClient.invalidateQueries({ queryKey: DOMAINS_QUERY_KEY }),
      ]);
    },
  });
}
