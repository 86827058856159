import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import type { Partner, CreatePartnerInput, UpdatePartnerInput } from '@/types/partner';
import { useAxios } from './use-axios';

const API_BASE_URL = '/partner';

export const PARTNERS_QUERY_KEY = ['partners'];

interface ApiError {
  error?: {
    message?: string;
    status?: number;
  };
  details?: Array<{ message: string }>;
}

function getErrorMessage(error: unknown): string {
  if (isAxiosError<ApiError>(error)) {
    // Handle validation errors
    if (error.response?.data?.details) {
      return 'Please check your input and try again';
    }

    // Handle specific error codes
    if (error.response?.status === 500) {
      return 'An internal server error occurred. Please try again later';
    }

    if (error.response?.status === 401 || error.response?.status === 403) {
      return 'You do not have permission to perform this action';
    }

    if (error.response?.status === 404) {
      return 'The requested resource was not found';
    }

    // Handle network errors
    if (error.message === 'Network Error') {
      return 'Unable to connect to the server. Please check your internet connection';
    }
  }

  return 'An unexpected error occurred. Please try again';
}

// List all partners
export function usePartners() {
  const axios = useAxios();

  return useQuery({
    queryKey: PARTNERS_QUERY_KEY,
    queryFn: async () => {
      try {
        const { data } = await axios.get<Partner[]>(API_BASE_URL);
        return data;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
  });
}

// Get a single partner
export function usePartner(id: string) {
  const axios = useAxios();

  return useQuery({
    queryKey: [...PARTNERS_QUERY_KEY, id],
    queryFn: async () => {
      try {
        const { data } = await axios.get<Partner>(`${API_BASE_URL}/${id}`);
        return data;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    enabled: !!id,
  });
}

// Create a partner
export function useCreatePartner() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (newPartner: CreatePartnerInput) => {
      try {
        const { data } = await axios.post<Partner>(API_BASE_URL, newPartner);
        return data;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: PARTNERS_QUERY_KEY });
    },
  });
}

// Update a partner
export function useUpdatePartner() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, ...updateData }: { id: string } & UpdatePartnerInput) => {
      try {
        const { data } = await axios.put<Partner>(`${API_BASE_URL}/${id}`, updateData);
        return data;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: PARTNERS_QUERY_KEY });
    },
  });
}

// Delete a partner
export function useDeletePartner() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id: string) => {
      try {
        const { data } = await axios.delete(`${API_BASE_URL}/${id}`);
        return data;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: PARTNERS_QUERY_KEY });
    },
  });
}

// Delete multiple partners
export function useDeletePartners() {
  const queryClient = useQueryClient();
  const deleteMutation = useDeletePartner();

  return useMutation({
    mutationFn: async (ids: string[]) => {
      try {
        await Promise.all(ids.map(async (id) => {
          await deleteMutation.mutateAsync(id);
        }));
        return ids;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: PARTNERS_QUERY_KEY });
    },
  });
}
