// src/pages/app/deals/index.tsx
import { useRef, useState } from 'react';
import type { GridApi } from '@ag-grid-community/core';
import { Archive, LayoutGrid, RotateCcw, Table2 } from 'lucide-react';
import { toast } from 'sonner';
import AGDataGrid from '@/components/ag-data-grid';
import Container from '@/components/container';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { ToggleGroup, ToggleGroupItem } from '@/components/ui/toggle-group';
import { useGrid } from '@/contexts/grid-context';
import { useDeals, useUpdateDeal } from '@/hooks/use-deals';
import { usePersistedState } from '@/hooks/use-persisted-state';
import { DealColumns } from './components/deal-columns';
import { dealColumnDefs } from './components/deal-grid-columns';

type ViewMode = 'grid' | 'columns';

function DealsPage() {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [viewMode, setViewMode] = usePersistedState<ViewMode>('deals-view-mode', 'grid');
  const gridApiRef = useRef<GridApi | null>(null);
  const { registerGridApi } = useGrid();

  const { data: deals, isLoading, error } = useDeals();
  const updateDeal = useUpdateDeal();

  const handleSelectionChanged = (event: { api: { getSelectedRows: () => any[] } }) => {
    setSelectedRows(event.api.getSelectedRows());
  };

  const handleArchive = async () => {
    try {
      await Promise.all(
        selectedRows.map(async (row) =>
          updateDeal.mutateAsync({
            id: row.id,
            archived_at: new Date().toISOString(),
          }),
        ),
      );
      toast.success('Selected deals archived successfully');
      setSelectedRows([]);
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      }
    }
  };

  const handleGridReady = (params: { api: GridApi }) => {
    gridApiRef.current = params.api;
    registerGridApi(params.api);
  };

  const clearFilters = () => {
    if (gridApiRef.current) {
      gridApiRef.current.setFilterModel(null);
      setSearchQuery('');
    }
  };

  if (error) {
    toast.error('Failed to load deals');
  }

  return (
    <Container maxWidth={false} className="pb-12">
      <div className="mb-3 flex justify-between">
        <div>
          <h1 className="mt-2 text-xl font-bold">All Deals</h1>
        </div>
        <div className="flex items-center gap-4">
          <ToggleGroup
            type="single"
            value={viewMode}
            onValueChange={(value) => value && setViewMode(value as ViewMode)}
            aria-label="View mode"
          >
            <ToggleGroupItem
              value="grid"
              aria-label="Grid view"
              className="px-3"
            >
              <Table2 className="size-4" />
            </ToggleGroupItem>
            <ToggleGroupItem
              value="columns"
              aria-label="Column view"
              className="px-3"
            >
              <LayoutGrid className="size-4" />
            </ToggleGroupItem>
          </ToggleGroup>
          {selectedRows.length > 0 && (
            <Button
              variant="outline"
              onClick={handleArchive}
              disabled={updateDeal.isPending}
              className="gap-2"
            >
              <Archive className="size-4" />
              Archive Selected
            </Button>
          )}
        </div>
      </div>
      <div className="mb-6 flex gap-4">
        <div className="flex-1">
          <Input
            type="search"
            placeholder="Search deals..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <Button
          variant="outline"
          onClick={clearFilters}
          className="gap-2"
        >
          <RotateCcw className="size-4" />
          Clear Filters
        </Button>
      </div>
      {viewMode === 'grid'
        ? (
            <div className={isLoading ? 'opacity-50' : ''}>
              <AGDataGrid
                columnDefs={dealColumnDefs}
                rowData={deals || []}
                quickFilterText={searchQuery}
                rowSelection={{ mode: 'multiRow' }}
                onGridReady={handleGridReady}
                onSelectionChanged={handleSelectionChanged}
                overlayLoadingTemplate={
                  isLoading ? '<span class="ag-overlay-loading-center">Loading...</span>' : undefined
                }
                overlayNoRowsTemplate={
                  isLoading ? '' : '<span class="ag-overlay-no-rows-center">No deals found</span>'
                }
              />
            </div>
          )
        : (
            <DealColumns deals={deals || []} />
          )}
    </Container>
  );
}

export default DealsPage;
