import { Mail, FileText, MessageSquare } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Editor } from '@tiptap/react';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  TooltipProvider,
} from '@/components/ui/tooltip';

interface MessageActionsProps {
  editor: Editor;
  isEmailMode?: boolean;
  onEmailModeChange?: (isEmail: boolean) => void;
  onTemplateClick?: () => void;
}

export function MessageActions({
  editor,
  isEmailMode = false,
  onEmailModeChange,
  onTemplateClick,
}: MessageActionsProps) {
  if (!onEmailModeChange && !onTemplateClick) return null;

  return (
    <TooltipProvider delayDuration={0}>
      <div className="flex items-center gap-0.5">
        {onEmailModeChange && (
          <Tooltip delayDuration={0}>
            <TooltipTrigger asChild>
              <Button
                variant={isEmailMode ? "secondary" : "ghost"}
                size="sm"
                onClick={() => onEmailModeChange(!isEmailMode)}
                className="h-8 gap-2 px-2"
              >
                {isEmailMode ? <MessageSquare className="size-4" /> : <Mail className="size-4" />}
                {isEmailMode ? 'Chat' : 'Email'}
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>Switch to {isEmailMode ? 'chat' : 'email'} mode</p>
            </TooltipContent>
          </Tooltip>
        )}
        {onTemplateClick && (
          <Tooltip delayDuration={0}>
            <TooltipTrigger asChild>
              <Button
                variant="ghost"
                size="sm"
                onClick={onTemplateClick}
                className="h-8 gap-2 px-2"
              >
                <FileText className="size-4" />
                Template
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>Insert template</p>
            </TooltipContent>
          </Tooltip>
        )}
      </div>
    </TooltipProvider>
  );
}
