import { useMutation } from '@tanstack/react-query';
import axios, { isAxiosError } from 'axios';
import { API_BASE_URL } from '@/lib/globals';

interface OnboardingFormData {
  domainNames: string;
  isNewBrand?: boolean;
  registrantName: string;
  registrantTitle: string;
  useRoleName?: boolean;
  roleName?: string;
  roleEmail?: string;
  registrantEmail: string;
  registrantPhone: string;
  orgLegalName: string;
  isNewLegalName?: boolean;
  addressLine1: string;
  addressLine2?: string;
  addressLine3?: string;
  country: string;
  city: string;
  state: string;
  zipCode: string;
  faxNumber?: string;
  isSoleProprietor?: boolean;
  verifierName: string;
  verifierTitle: string;
  verifierEmail: string;
  verifierPhone: string;
  applicationType: string;
  regulatoryAuthority?: string;
  regulatoryLicenseNumber?: string;
  supportingFiles?: File[];
  additionalInfo?: string;
  registrarChoice?: string;
  referralSources: string[];
  privacyPolicy: boolean;
  dataConsent: boolean;
  registrarConsent: boolean;
  joinMailingList?: boolean;
}

interface OnboardingResponse {
  success: boolean;
  dealId?: string;
  message?: string;
  error?: string;
}

interface OnboardingSubmitData extends OnboardingFormData {
  onProgress?: (progress: number) => void;
}

function getErrorMessage(error: unknown): string {
  if (isAxiosError<{ error?: string }>(error)) {
    // Return API error message if available
    if (error.response?.data?.error) {
      return error.response.data.error;
    }

    // Handle specific error codes
    const status = error.response?.status;
    if (status !== undefined) {
      switch (status) {
        case 400: {
          return 'Please check your input and try again.';
        }
        case 413: {
          return 'The uploaded files are too large. Please reduce their size and try again.';
        }
        case 415: {
          return 'One or more files are in an unsupported format.';
        }
        case 429: {
          return 'Too many requests. Please wait a moment and try again.';
        }
        case 500: {
          return 'An internal server error occurred. Please try again later.';
        }
        default: {
          return `Server error (${status}). Please try again later.`;
        }
      }
    }

    // Handle network errors
    if (error.message === 'Network Error') {
      return 'Unable to connect to the server. Please check your internet connection.';
    }
  }

  return 'An unexpected error occurred. Please try again.';
}

/**
 * Hook for submitting the public onboarding form
 * @returns Mutation for submitting the form with the following parameters:
 * @param formData - The form data to submit
 * @param onProgress - Optional callback for upload progress (0-100)
 */
export function usePublicOnboarding() {
  return useMutation<OnboardingResponse, Error, OnboardingSubmitData>({
    mutationFn: async ({ onProgress, ...data }) => {
      // Create FormData instance
      const formData = new FormData();

      // Append all form fields to FormData
      for (const [key, value] of Object.entries(data)) {
        if (key === 'supportingFiles') {
          if (value && Array.isArray(value)) {
            value.forEach((file: File, index: number) => {
              formData.append(`supportingFiles`, file);
            });
          }
        } else if (key === 'referralSources') {
          // Handle array of strings
          if (Array.isArray(value)) {
            for (const source of value as string[]) {
              formData.append('referralSources[]', source);
            }
          }
        } else if (value !== undefined && value !== null) {
          // Handle all other fields, converting booleans to strings
          formData.append(key, value.toString());
        }
      }

      try {
        const { data: response } = await axios.post<OnboardingResponse>(
          `${API_BASE_URL}/public-onboarding/submit`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            // Track upload progress
            onUploadProgress: (progressEvent) => {
              if (progressEvent.total && onProgress) {
                const progress = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total,
                );
                // Keep progress at 99% during server processing
                onProgress(progress >= 100 ? 99 : progress);
              }
            },
          },
        );

        // Set final progress to 100% after server response
        if (onProgress) {
          onProgress(100);
        }

        if (!response.success) {
          throw new Error(response.error || 'Form submission failed');
        }

        return response;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
  });
}
