import * as React from 'react';
import { ScrollArea } from '@/components/ui/scroll-area';
import { useHub } from '@/contexts/hub-context';
import { useMessagesByIds } from '@/hooks/use-messages';
import { useNylasMessages } from '@/hooks/use-nylas-messages';
import type { MessageDB } from '@/types/message';
import type { CommunicationDisplay } from '../types/communications';
import type { Message as NylasMessage } from 'nylas';
import { MessageContainer } from '../components/message-container';
import { MessageSkeleton } from '../components/message-skeleton';

type RightPaneProps = {
  communication: CommunicationDisplay | null;
  messageId?: string;
  activeGrantId?: string;
};

export function RightPane({ communication, messageId, activeGrantId }: RightPaneProps) {
  const { selectedInbox } = useHub();
  // Use different hooks based on inbox type
  const { messages: dealMessages, isLoading: isDealLoading } = useMessagesByIds(
    selectedInbox === 'deal' ? communication?.emailMessageIds : undefined,
  );

  const { data: nylasData, isLoading: isNylasLoading } = useNylasMessages(
    selectedInbox !== 'deal' && activeGrantId && communication?.emailMessageIds
      ? {
        grantId: activeGrantId,
        messageIds: communication.emailMessageIds,
      }
      : { grantId: '', messageIds: [] },
  );

  // Combine messages and loading states
  const threadMessages = selectedInbox === 'deal' ? dealMessages : nylasData?.messages;
  const isLoading = selectedInbox === 'deal' ? isDealLoading : isNylasLoading;

  if (isLoading && messageId) {
    return (
      <ScrollArea className="flex h-full flex-col">
        <div className="flex flex-col space-y-8 p-4">
          <MessageSkeleton />
          <MessageSkeleton />
        </div>
      </ScrollArea>
    );
  }

  if (!communication) {
    return (
      <div className="flex h-full items-center justify-center">
        <p className="text-sm text-muted-foreground">Select a message to view</p>
      </div>
    );
  }

  // Handle single message case when content exists directly on communication
  if (communication.content && !communication.emailMessageIds) {
    return (
      <ScrollArea className="flex h-full flex-col">
        <div className="flex flex-col space-y-8 p-4">
          <MessageContainer
            communication={communication}
            content={communication.content || null}
            timestamp={communication.timestamp}
          />
        </div>
      </ScrollArea>
    );
  }

  // Sort messages by timestamp, handling both MessageDB and NylasMessage types
  const sortedMessages = threadMessages?.sort((b, a) => {
    const aTime = (a as MessageDB).created_at || (a as NylasMessage).date;
    const bTime = (b as MessageDB).created_at || (b as NylasMessage).date;
    return new Date(aTime).getTime() - new Date(bTime).getTime();
  });

  return (
    <ScrollArea className="flex h-full flex-col">
      <div className="flex flex-col space-y-8 p-4">
        {sortedMessages?.map((message) => {
          // extract message timestamp (handle both MessageDB and NylasMessage types)
          const timestamp = (message as MessageDB).created_at
            || new Date((message as NylasMessage).date * 1000).toISOString();
          // extract message content (handle both MessageDB and NylasMessage types)
          const content = (message as MessageDB).content || (message as NylasMessage);
          // skip if content is undefined
          if (!content)
            return null;
          //
          return (
            <MessageContainer
              key={message.id}
              communication={communication}
              message={message}
              content={content}
              timestamp={timestamp}
            />
          );
        })}
      </div>
    </ScrollArea>
  );
}
