import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import type { GridApi, RowDoubleClickedEvent } from '@ag-grid-community/core';
import { RotateCcw } from 'lucide-react';
import { toast } from 'sonner';
import AGDataGrid from '@/components/ag-data-grid';
import ColumnStatistics from '@/components/card-statistics-column';
import Container from '@/components/container';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { useGrid } from '@/contexts/grid-context';
import { useDomains } from '@/hooks/use-domains';
import type { Domain } from '@/types/domain';
import { domainColumnDefs } from './components/domain-grid-columns';

function DomainsPage() {
  const [searchQuery, setSearchQuery] = useState('');
  const gridApiRef = useRef<GridApi<Domain> | null>(null);
  const { registerGridApi } = useGrid();
  const navigate = useNavigate();

  const { data: domains, isLoading, error } = useDomains();

  // Show error toast if the query fails
  if (error instanceof Error) {
    toast.error(error.message);
  }

  // Calculate statistics
  const bankDomains = domains?.filter((domain) => domain.name.endsWith('.bank')) || [];
  const insuranceDomains = domains?.filter((domain) => domain.name.endsWith('.insurance')) || [];

  const bankStats = {
    'Total Domains': bankDomains.length,
    'Live Domains': bankDomains.filter((domain) => domain.monitor_data.at(-1)?.status === 'live').length,
    'Redirected Domains': bankDomains.filter((domain) => domain.monitor_data.at(-1)?.status === 'redirect_out_of_zone').length,
  };

  const insuranceStats = {
    'Total Domains': insuranceDomains.length,
    'Live Domains': insuranceDomains.filter((domain) => domain.monitor_data.at(-1)?.status === 'live').length,
    'Redirected Domains': insuranceDomains.filter((domain) => domain.monitor_data.at(-1)?.status === 'redirect_out_of_zone').length,
  };

  const handleRowDoubleClick = (event: RowDoubleClickedEvent<Domain>) => {
    if (event.data) {
      navigate(`/app/domains/${event.data.name}`);
    }
  };

  const handleGridReady = (params: { api: GridApi<Domain> }) => {
    gridApiRef.current = params.api;
    registerGridApi(params.api);
  };

  const clearFilters = () => {
    if (gridApiRef.current) {
      gridApiRef.current.setFilterModel(null);
      setSearchQuery('');
    }
  };

  return (
    <Container className="pb-12">
      <h1 className="mb-6 text-3xl font-bold">Domains</h1>
      <div className="mb-6 grid grid-cols-1 gap-6 md:grid-cols-2">
        <ColumnStatistics
          title=".Bank"
          statistics={bankStats}
        />
        <ColumnStatistics
          title=".Insurance"
          statistics={insuranceStats}
        />
      </div>
      <div className="mb-6 flex gap-4">
        <div className="flex-1">
          <Input
            type="search"
            placeholder="Search domains..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <Button
          variant="outline"
          onClick={clearFilters}
          className="gap-2"
        >
          <RotateCcw className="size-4" />
          Clear Filters
        </Button>
      </div>
      <div className={isLoading ? 'opacity-50' : ''}>
        <AGDataGrid
          columnDefs={domainColumnDefs}
          rowData={domains || []}
          quickFilterText={searchQuery}
          onRowDoubleClicked={handleRowDoubleClick}
          onGridReady={handleGridReady}
          overlayLoadingTemplate={
            isLoading ? '<span class="ag-overlay-loading-center">Loading...</span>' : undefined
          }
          overlayNoRowsTemplate={
            isLoading ? '' : '<span class="ag-overlay-no-rows-center">No domains found</span>'
          }
        />
      </div>
    </Container>
  );
}

export default DomainsPage;
