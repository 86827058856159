import { useQuery, useMutation } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { useAxios } from './use-axios';

const API_BASE_URL = '/bounces';

export const BOUNCES_QUERY_KEY = ['bounces'];

interface ApiError {
  error?: {
    message?: string;
    status?: number;
  };
  details?: Array<{ message: string }>;
}

export interface Bounce {
  id: string;
  description: string;
  created_at: string;
  email: string;
  from: string;
  subject: string;
}

interface GetBouncesByEmailRequest {
  emails: string[];
}

function getErrorMessage(error: unknown): string {
  if (isAxiosError<ApiError>(error)) {
    // Handle validation errors
    if (error.response?.data?.details) {
      return 'Please check your input and try again';
    }

    // Handle specific error codes
    if (error.response?.status === 500) {
      return 'An internal server error occurred. Please try again later';
    }

    if (error.response?.status === 401 || error.response?.status === 403) {
      return 'You do not have permission to perform this action';
    }

    if (error.response?.status === 404) {
      return 'The requested resource was not found';
    }

    // Handle network errors
    if (error.message === 'Network Error') {
      return 'Unable to connect to the server. Please check your internet connection';
    }
  }

  return 'An unexpected error occurred. Please try again';
}

/**
 * Hook to fetch all bounce records
 *
 * @returns Query result containing:
 * - data: Array of bounce records if successful
 * - isLoading: Boolean indicating if request is in progress
 * - error: Error object if request failed
 *
 * @example
 * ```tsx
 * const { data: bounces, isLoading } = useBounces();
 *
 * if (isLoading) return <div>Loading...</div>;
 * return (
 *   <ul>
 *     {bounces?.map(bounce => (
 *       <li key={bounce.id}>{bounce.email}: {bounce.description}</li>
 *     ))}
 *   </ul>
 * );
 * ```
 */
export function useBounces() {
  const axios = useAxios();

  return useQuery({
    queryKey: BOUNCES_QUERY_KEY,
    queryFn: async () => {
      try {
        const { data } = await axios.get<Bounce[]>(API_BASE_URL);
        return data;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
  });
}

/**
 * Hook to fetch bounce records for specific email addresses
 *
 * @returns Mutation for fetching bounces with the following parameters:
 * @param emails - Array of email addresses to check for bounces
 *
 * @example
 * ```tsx
 * const { getBouncesByEmail, isLoading } = useBouncesByEmail();
 *
 * const handleCheck = async (emails: string[]) => {
 *   try {
 *     const bounces = await getBouncesByEmail({ emails });
 *     console.log('Bounce records:', bounces);
 *   } catch (error) {
 *     console.error('Failed to get bounces:', error);
 *   }
 * };
 * ```
 */
export function useBouncesByEmail() {
  const axios = useAxios();

  const mutation = useMutation<Bounce[], Error, GetBouncesByEmailRequest>({
    mutationFn: async ({ emails }) => {
      try {
        const { data } = await axios.post<Bounce[]>(`${API_BASE_URL}/by-email`, {
          emails,
        });
        return data;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
  });

  return {
    getBouncesByEmail: mutation.mutate,
    getBouncesByEmailAsync: mutation.mutateAsync,
    isLoading: mutation.isPending,
    error: mutation.error,
    data: mutation.data,
  };
}
