import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import type { NylasThread, ThreadListParams } from '@/types/thread';
import useAxios from './use-axios';

/**
 * Response type for Nylas threads API
 */
type ThreadsResponse = {
  threads: NylasThread[];
  nextPageToken: string | null;
  requestId: string;
};

/**
 * Query key factory for Nylas threads
 * @internal
 */
export const NYLAS_QUERY_KEYS = {
  threads: {
    all: ['nylasThreads'] as const,
    list: (params: ThreadListParams, pageToken: string | null) =>
      [...NYLAS_QUERY_KEYS.threads.all, params, pageToken] as const,
  },
} as const;

/**
 * Hook for fetching and managing Nylas email threads with pagination support
 *
 * @param params - Configuration parameters for the threads query; all Nylas ListThreadsQueryParams are supported
 * @param params.grantId - The Nylas grant ID to fetch threads for
 * @see {@link https://developer.nylas.com/docs/api/#get/threads Thread filtering documentation}
 *
 * @returns {object} An object containing:
 *   - {NylasThread[]} threads - Array of threads for the current page
 *   - {boolean} isLoading - Whether the data is loading
 *   - {boolean} isFetchingNextPage - Whether a page transition is in progress
 *   - {boolean} hasNextPage - Whether there is a next page available
 *   - {boolean} hasPrevPage - Whether there is a previous page available
 *   - {Function} nextPage - Function to go to the next page
 *   - {Function} prevPage - Function to go to the previous page
 *   - {Error | null} error - Any error that occurred during fetching
 *   - {Function} refetch - Function to refetch current page
 *   - {number} currentPage - Current page number (1-based)
 *   - {number} totalPages - Total number of pages
 */
export function useNylasThreads(params: ThreadListParams) {
  const axios = useAxios();
  const { grantId, ...queryParams } = params;
  const [pageTokens, setPageTokens] = useState<string[]>([]);
  const [currentTokenIndex, setCurrentTokenIndex] = useState(-1);
  const [isFetchingNextPage, setIsFetchingNextPage] = useState(false);

  const currentToken = currentTokenIndex >= 0 ? pageTokens[currentTokenIndex] : null;

  const {
    data,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: NYLAS_QUERY_KEYS.threads.list(params, currentToken),
    queryFn: async () => {
      setIsFetchingNextPage(true);
      try {
        const { data } = await axios.get<ThreadsResponse>('/nylas/threads', {
          params: {
            grantId,
            ...queryParams,
            ...(currentToken ? { pageToken: currentToken } : {}),
          },
        });

        // Only store non-null tokens that we haven't seen before
        if (data.nextPageToken && !pageTokens.includes(data.nextPageToken)) {
          const nextToken = data.nextPageToken;
          setPageTokens((tokens) => [...tokens.slice(0, currentTokenIndex + 1), nextToken]);
        }

        return data;
      } finally {
        setIsFetchingNextPage(false);
      }
    },
    enabled: !!grantId,
    staleTime: 1000 * 60 * 5, // Consider data fresh for 5 minutes
    gcTime: 1000 * 60 * 60, // Keep unused data in cache for 1 hour
  });

  const hasNextPage = !!data?.nextPageToken;
  const hasPrevPage = currentTokenIndex > -1;

  const nextPage = () => {
    if (data?.nextPageToken) {
      setCurrentTokenIndex((prev) => prev + 1);
    }
  };

  const prevPage = () => {
    if (hasPrevPage) {
      setCurrentTokenIndex((prev) => prev - 1);
    }
  };

  // Calculate page numbers for UI
  const currentPage = currentTokenIndex + 2; // Convert to 1-based and account for initial page
  const totalPages = pageTokens.length + 1; // Total fetched pages plus current

  return {
    threads: data?.threads ?? [],
    nextPage,
    prevPage,
    hasNextPage,
    hasPrevPage,
    isFetchingNextPage,
    isLoading,
    error,
    refetch,
    currentPage,
    totalPages,
  };
}
