import { useQuery, useMutation } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { useAxios } from './use-axios';

const API_BASE_URL = '/domain-info';

export const DOMAIN_INFO_QUERY_KEY = ['domain-info'];

interface ApiError {
  error?: {
    message?: string;
    status?: number;
  };
  details?: Array<{ message: string }>;
}

function getErrorMessage(error: unknown): string {
  if (isAxiosError<ApiError>(error)) {
    // Handle validation errors
    if (error.response?.data?.details) {
      return 'Please check your input and try again';
    }

    // Handle specific error codes
    if (error.response?.status === 500) {
      return 'An internal server error occurred. Please try again later';
    }

    if (error.response?.status === 401 || error.response?.status === 403) {
      return 'You do not have permission to perform this action';
    }

    if (error.response?.status === 404) {
      return 'The requested resource was not found';
    }

    // Handle network errors
    if (error.message === 'Network Error') {
      return 'Unable to connect to the server. Please check your internet connection';
    }
  }

  return 'An unexpected error occurred. Please try again';
}

export interface DomainInfo {
  ROID: string;
  'Domain Name': {
    'DNS form': string;
    'User form': string;
  };
  'Registry Status': string;
  'EPP Statuses': string[];
  'Registrant Contact': Contact;
  'Technical Contacts': Contact[];
  'Admin Contacts': Contact[];
  'Billing Contacts': Contact[];
  'Name Servers': NameServer[];
  'DNSSEC Information': {
    'DS Data items': DSDataItem[];
  };
  'Sponsoring Registrar': string;
  'Sponsoring Registrar IANA ID': string;
  'Creating Registrar': string;
  'Creating Registrar IANA ID': string;
  'Creation Date': string;
  'Expiry Date': string;
  'Last Updating Registrar': string;
  'Last Updating Registrar IANA ID': string;
  'Last Updated Date': string;
  Version: number;
}

export interface Contact {
  Version: number;
  ID: string;
  ROID: string;
  Name: string;
  Organisation: string;
  Address: {
    Street: (string | null)[];
    City: string;
    'State/Province': string;
    'Postal Code': string;
    Country: string;
  };
  Phone: {
    Number: string;
  };
  Fax: {
    Number: string;
  };
  Email: string;
}

export interface NameServer {
  'Name Server Name': string;
  ROID: string;
  Version: number;
  'IPv4 Addresses': string[];
  'IPv6 Addresses': string[];
}

export interface DSDataItem {
  'DS Data Name': string;
  'DS Data Elements': {
    Algorithm: number;
    'Key Tag': number;
    'Digest Type': number;
    Digest: string;
  }[];
}

interface DomainInfoResponse {
  message: string;
  domainInfo: DomainInfo;
}

export function useDomainInfo(domainName: string) {
  const axios = useAxios();

  return useQuery({
    queryKey: [...DOMAIN_INFO_QUERY_KEY, domainName],
    queryFn: async () => {
      try {
        const { data } = await axios.get<DomainInfoResponse>(`${API_BASE_URL}/${domainName}`);
        return data.domainInfo;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    enabled: !!domainName,
  });
}

export function useDomainInfos(domainNames: string[]) {
  const axios = useAxios();

  return useQuery({
    queryKey: [...DOMAIN_INFO_QUERY_KEY, ...domainNames],
    queryFn: async () => {
      try {
        const responses = await Promise.all(
          domainNames.map(async (domain) => {
            const { data } = await axios.get<DomainInfoResponse>(`${API_BASE_URL}/${domain}`);
            return { domain, info: data.domainInfo };
          }),
        );
        return Object.fromEntries(responses.map(({ domain, info }) => [domain, info]));
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    enabled: domainNames.length > 0,
  });
}

interface UpdateRddsResponse {
  message: string;
}

export function useUpdateDomainRdds() {
  const axios = useAxios();

  return useMutation({
    mutationFn: async (domainName: string) => {
      try {
        const { data } = await axios.post<UpdateRddsResponse>(
          `${API_BASE_URL}/${domainName}/rdds`,
        );
        return data;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
  });
}
