import { useState, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Loader2, Upload } from 'lucide-react';
import { toast } from 'sonner';
import { UploadedFilesCard } from '@/components/card-uploaded-files';
import { FileUploader } from '@/components/file-uploader';
import { Button } from '@/components/ui/button';
import { useDeal } from '@/contexts/deal-context';
import { useListFiles, useUploadMultipleFiles, DRIVE_QUERY_KEYS } from '@/hooks/use-drive';
import type { GoogleDriveFile } from '@/types/drive';

export function FilesPanel() {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [uploadProgress, setUploadProgress] = useState<Record<string, number>>({});
  const { dealId } = useDeal();
  const { data: fileList, isLoading } = useListFiles({ dealId });
  const queryClient = useQueryClient();
  const uploadMutation = useUploadMultipleFiles();

  const handleFilesSelected = (files: File[]) => {
    setSelectedFiles(files);
    setUploadProgress({});
  };

  const handleUpload = async () => {
    if (selectedFiles.length === 0) {
      toast.error('Please select files to upload');
      return;
    }

    try {
      await uploadMutation.mutateAsync({
        files: selectedFiles,
        dealId,
        onProgress: (progresses) => {
          setUploadProgress(progresses);
        },
      });

      await queryClient.invalidateQueries({ queryKey: DRIVE_QUERY_KEYS.files.all });
      toast.success('Files uploaded successfully');
      setSelectedFiles([]);
      setUploadProgress({});
    } catch (error) {
      console.error('Upload error:', error);
      toast.error(error instanceof Error ? error.message : 'Failed to upload files');
      setUploadProgress({});
    }
  };

  const truncateFileName = (name: string, maxLength = 30) => {
    if (name.length <= maxLength)
      return name;
    const extension = name.split('.').pop();
    const nameWithoutExt = name.slice(0, name.lastIndexOf('.'));
    const truncatedName = nameWithoutExt.slice(0, maxLength - 3 - (extension?.length || 0));
    return `${truncatedName}...${extension ? `.${extension}` : ''}`;
  };

  const uploadedFiles = useMemo(() => {
    const files = fileList?.pages.flatMap((page) => page.files) || [];
    return files.map((file) => ({
      ...file,
      name: truncateFileName(file.name),
    }));
  }, [fileList?.pages]);
  const isUploading = uploadMutation.isPending;
  const hasFiles = selectedFiles.length > 0;

  return (
    <div className="flex flex-col gap-6 p-4">
      <div className="flex flex-col">
        <FileUploader
          value={selectedFiles}
          onValueChange={handleFilesSelected}
          disabled={isUploading}
          progresses={uploadProgress}
        />
        <div className="mt-4">
          <Button
            onClick={handleUpload}
            disabled={isUploading || !hasFiles}
          >
            {isUploading
              ? (
                  <>
                    <Loader2 className="mr-2 size-4 animate-spin" />
                    Uploading...
                  </>
                )
              : (
                  <>
                    <Upload className="mr-2 size-4" />
                    Upload Files
                  </>
                )}
          </Button>
        </div>
      </div>

      <UploadedFilesCard uploadedFiles={uploadedFiles} />
    </div>
  );
}
