import * as React from 'react';
import { Separator } from '@/components/ui/separator';

type Attachment = {
  id: string;
  name: string;
  size: number;
};

type AttachmentsProps = {
  attachments: Attachment[];
};

function formatFileSize(bytes: number): string {
  const units = ['B', 'KB', 'MB', 'GB'];
  let size = bytes;
  let unitIndex = 0;

  while (size >= 1024 && unitIndex < units.length - 1) {
    size /= 1024;
    unitIndex++;
  }

  return `${size.toFixed(1)} ${units[unitIndex]}`;
}

export function MessageAttachments({ attachments }: AttachmentsProps) {
  if (attachments.length === 0)
    return null;

  return (
    <>
      <Separator className="my-4" />
      <div className="space-y-2">
        <h4 className="text-sm font-medium">Attachments</h4>
        {attachments.map((file) => (
          <div
            key={file.id}
            className="flex items-center gap-2 rounded-md border bg-muted/40 p-2 text-sm"
          >
            <div className="flex-1 truncate">{file.name}</div>
            <div className="text-xs text-muted-foreground">
              {formatFileSize(file.size)}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
