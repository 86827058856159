import * as React from 'react';
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from '@/components/ui/resizable';
import { useDeal } from '@/contexts/deal-context';
import { HubProvider } from '@/contexts/hub-context';
import { useClerkUserIdByEmail } from '@/hooks/use-clerk-user';
import { useInboxMessageParser } from '@/hooks/use-inbox-message-parser';
import { useMessageParser } from '@/hooks/use-message-parser';
import { useMessages } from '@/hooks/use-messages';
import { useNylasGrants } from '@/hooks/use-nylas-grants';
import { useNylasThreads } from '@/hooks/use-nylas-threads';
import { useThreads } from '@/hooks/use-threads';
import { stringArrayToEmailName } from '@/lib/email';
import type { ThreadListParams } from '@/types/thread';
import type { MessageContent } from './types/communications';
import { CenterPane } from './panes/center-pane';
import { LeftPane } from './panes/left-pane';
import { RightPane } from './panes/right-pane';

export function CommunicationsHub() {
  const { dealId } = useDeal();
  const [isCollapsed, setIsCollapsed] = React.useState(false);
  const [selectedInbox, setSelectedInbox] = React.useState<string>('deal');
  const [selectedFolderId, setSelectedFolderId] = React.useState<string>();
  const [selectedMessageId, setSelectedMessageId] = React.useState<string>();
  const { messages: rawMessages, sendMessage, isLoading: isMessagesLoading } = useMessages(dealId ?? '');
  const { threads: rawThreads } = useThreads(dealId ?? '');
  // Get grantIds for different inboxes
  const { grantId: dealGrantId } = useNylasGrants();
  const { grantId: verifyGrantId } = useNylasGrants('verify@ftld.com');
  const { data } = useClerkUserIdByEmail('verify@ftld.com');
  const verifyUserId = data?.userId;
  const { grantId: userGrantId, grantEmail: userGrantEmail } = useNylasGrants();

  // Get the active grantId based on selected inbox
  const activeGrantId = React.useMemo(() => {
    switch (selectedInbox) {
      case 'deal': {
        return dealGrantId;
      }
      case 'verify@ftld.com': {
        return verifyGrantId;
      }
      case userGrantEmail: {
        return userGrantId;
      }
      default: {
        // eslint-disable-next-line unicorn/no-useless-undefined
        return undefined;
      }
    }
  }, [selectedInbox, dealGrantId, verifyGrantId, userGrantId, userGrantEmail]);

  const [nylasParams, setNylasParams] = React.useState<ThreadListParams>({
    grantId: '',
    limit: 20,
  });

  React.useEffect(() => {
    if (selectedInbox !== 'deal' && activeGrantId) {
      setNylasParams({
        grantId: activeGrantId,
        limit: 20,
        in: selectedFolderId ? [selectedFolderId] : undefined,
      });
    }
  }, [selectedInbox, activeGrantId, selectedFolderId]);

  const {
    threads: nylasThreads,
    isLoading: isNylasLoading,
    nextPage,
    prevPage,
    hasNextPage,
    hasPrevPage,
    isFetchingNextPage,
  } = useNylasThreads(nylasParams);

  // Parse messages using both parsers at the top level
  const parsedDealMessages = useMessageParser(rawMessages, rawThreads);
  const parsedInboxMessages = useInboxMessageParser(nylasThreads);

  // Select which messages to use based on selectedInbox
  const messages = React.useMemo(() => {
    return selectedInbox === 'deal' ? parsedDealMessages : parsedInboxMessages;
  }, [selectedInbox, parsedDealMessages, parsedInboxMessages]);

  // Combine loading states
  const isLoading = selectedInbox === 'deal' ? isMessagesLoading : isNylasLoading;

  const handleNextPage = React.useCallback(() => {
    setSelectedMessageId(undefined);
    nextPage();
  }, [nextPage, setSelectedMessageId]);

  const handlePrevPage = React.useCallback(() => {
    setSelectedMessageId(undefined);
    prevPage();
  }, [prevPage, setSelectedMessageId]);

  const handleSend = async (content: MessageContent) => {
    if (!dealId) {
      console.error('dealId is undefined');
      return;
    }

    if (content.isEmail) {
      // Send as threadGrantId if available, otherwise send email using the sendAsGrantId
      const grantId = content.threadGrantId || content.sendAsGrantId;
      if (!grantId) {
        console.error('grantId is undefined for email message');
        return;
      }
      if (!content.to) {
        console.error('recipients are required for email message');
        return;
      }

      await sendMessage({
        deal_id: dealId,
        type: 'email',
        grantId,
        reply_to_message_id: content.replyToMessageId,
        content: { body: content.html },
        to: stringArrayToEmailName(content.to),
        cc: content.cc ? stringArrayToEmailName(content.cc) : [],
        subject: content.subject,
      });
    } else {
      await sendMessage({
        deal_id: dealId,
        type: 'internal',
        content: { body: content.html },
      });
    }
  };

  const handleUploadFiles = async (files: File[]) => {
    // In a real app, you would upload these files to your server
    // and get back URLs/IDs for them
    console.log('Files to upload:', files);
  };

  return (
    <HubProvider
      selectedInbox={selectedInbox}
      setSelectedInbox={setSelectedInbox}
      myGrantId={userGrantId}
      myGrantEmail={userGrantEmail}
      verifyGrantId={verifyGrantId}
      verifyUserId={verifyUserId}
      dealThreads={rawThreads || []}
      selectedFolderId={selectedFolderId}
      setSelectedFolderId={setSelectedFolderId}
    >
      <ResizablePanelGroup
        direction="horizontal"
        className="h-full items-stretch"
      >
        <ResizablePanel
          defaultSize={20}
          collapsible
          minSize={15}
          maxSize={20}
          onCollapse={() => setIsCollapsed(true)}
          onExpand={() => setIsCollapsed(false)}
        >
          <LeftPane isCollapsed={isCollapsed} />
        </ResizablePanel>
        <ResizableHandle withHandle />
        <ResizablePanel defaultSize={45} minSize={30}>
          <CenterPane
            communications={messages}
            isLoading={isLoading || isFetchingNextPage}
            onMessageSelect={setSelectedMessageId}
            onSend={handleSend}
            onUploadFiles={handleUploadFiles}
            selectedMessageId={selectedMessageId}
            onNextPage={selectedInbox === 'deal' ? undefined : handleNextPage}
            onPrevPage={selectedInbox === 'deal' ? undefined : handlePrevPage}
            hasNextPage={selectedInbox === 'deal' ? false : hasNextPage}
            hasPrevPage={selectedInbox === 'deal' ? false : hasPrevPage}
          />
        </ResizablePanel>
        <ResizableHandle withHandle />
        <ResizablePanel defaultSize={35} minSize={30}>
          <RightPane
            communication={messages.find((m) => m.id === selectedMessageId) ?? null}
            messageId={selectedMessageId}
            activeGrantId={activeGrantId}
          />
        </ResizablePanel>
      </ResizablePanelGroup>
    </HubProvider>
  );
}
