import { useMutation } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { useAxios } from './use-axios';

const API_BASE_URL = '/fdic';

interface ApiError {
  error?: string;
  details?: Array<{ message: string }>;
}

interface FdicBasicInfo {
  id: string;
  name: string;
  previous_name: string;
  website: string;
  established_date: string;
  last_updated: string;
  prior_names: string[];
}

interface FdicLocationInfo {
  address: string;
  address2: string;
  city: string;
  state: string;
  state_code: string;
  zip: string;
  county: string;
  coordinates: {
    latitude: number;
    longitude: number;
  };
}

interface FdicFinancial {
  total_assets: number;
  total_deposits: number;
  total_equity: number;
  net_income: number;
  quarterly_net_income: number;
  return_on_assets: number;
  return_on_equity: number;
}

interface FdicOperational {
  total_offices: number;
  domestic_offices: number;
  foreign_offices: number;
  fdic_region: string;
  fdic_supervisor: string;
  regulatory_agency: string;
  institution_class: string;
  specialization: string;
  active: boolean;
  insured_by_fdic: boolean;
}

interface FdicLocation {
  ZIP: string;
  CBSA_NO: string;
  ACQDATE: string | null;
  CBSA_METRO_NAME: string;
  BKCLASS: string;
  FI_UNINUM: number;
  LONGITUDE: number;
  STNAME: string;
  MDI_STATUS_CODE: string;
  CSA: string;
  COUNTY: string;
  ADDRESS2: string;
  MAINOFF: number;
  OFFNAME: string;
  CBSA_METRO_FLG: string;
  CBSA_MICRO_FLG: string;
  CSA_NO: string;
  CBSA_METRO: string;
  CBSA_DIV_NO: string;
  RUNDATE: string;
  NAME: string;
  UNINUM: number;
  SERVTYPE: number;
  CSA_FLG: string;
  STCNTY: string;
  SERVTYPE_DESC: string;
  CBSA: string;
  MDI_STATUS_DESC: string;
  CBSA_DIV: string;
  CBSA_DIV_FLG: string;
  CITY: string;
  ADDRESS: string;
  CERT: string;
  STALP: string;
  LATITUDE: number;
  OFFNUM: number;
  ESTYMD: string;
  ID: string;
}

interface FdicLocations {
  data: FdicLocation[];
}

interface FdicSearchResponse {
  results: {
    basic_info: FdicBasicInfo;
    location: FdicLocationInfo;
    locations: FdicLocations;
    financial: FdicFinancial;
    operational: FdicOperational;
    additional_info: string[];
  } | string;
}

function getErrorMessage(error: unknown): string {
  if (isAxiosError<ApiError>(error)) {
    // Handle validation errors
    if (error.response?.data?.details) {
      return 'Please check your input and try again';
    }

    // Handle specific error codes
    if (error.response?.status === 500) {
      return 'An internal server error occurred. Please try again later';
    }

    if (error.response?.status === 401 || error.response?.status === 403) {
      return 'You do not have permission to perform this action';
    }

    if (error.response?.status === 404) {
      return 'The requested resource was not found';
    }

    // Handle network errors
    if (error.message === 'Network Error') {
      return 'Unable to connect to the server. Please check your internet connection';
    }

    // Handle specific FDIC API error
    if (error.response?.status === 400) {
      return error.response.data.error || 'Invalid certificate number';
    }
  }

  return 'An unexpected error occurred. Please try again';
}

// Search FDIC data
export function useFdicSearch() {
  const axios = useAxios();

  return useMutation({
    mutationFn: async (cert: string) => {
      try {
        const { data } = await axios.post<FdicSearchResponse>(`${API_BASE_URL}/search`, {
          cert,
        });
        return data;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
  });
}
