import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import type { User } from '@clerk/backend';
import { zodResolver } from '@hookform/resolvers/zod';
import { format } from 'date-fns';
import { CalendarIcon, Check } from 'lucide-react';
import { toast } from 'sonner';
import * as z from 'zod';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from '@/components/ui/command';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from '@/components/ui/sheet';
import { useAllClerkUsers } from '@/hooks/use-clerk-user';
import { useDeal } from '@/hooks/use-deals';
import { useCreateTask } from '@/hooks/use-tasks';
import { cn } from '@/lib/utils';

const formSchema = z.object({
  task: z.string().min(1, 'Task description is required.'),
  due: z.date({
    required_error: 'A due date is required.',
  }),
  assigned_to: z.array(z.string()).min(1, 'At least one assignee is required.'),
});

interface TaskSheetProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export function TaskSheet({ open, onOpenChange }: TaskSheetProps) {
  const [assigneeSearch, setAssigneeSearch] = useState('');
  const { dealId } = useParams();
  const { data: users } = useAllClerkUsers();
  const { data: deal } = useDeal(dealId!);
  const { mutate: createTask, isPending } = useCreateTask();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      assigned_to: [],
    },
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    if (!deal?.id)
      return;

    createTask(
      {
        deal_id: deal.id,
        task: values.task,
        due: values.due.toISOString(),
        assigned_to: values.assigned_to,
      },
      {
        onSuccess: () => {
          toast.success('Task created successfully');
          form.reset();
          onOpenChange(false);
        },
        onError: (error) => {
          toast.error(error.message);
        },
      },
    );
  }

  const filteredUsers = users?.filter((user) => {
    if (!assigneeSearch)
      return true;
    const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
    const email = user.primaryEmailAddress?.emailAddress.toLowerCase() ?? '';
    const search = assigneeSearch.toLowerCase();
    return fullName.includes(search) || email.includes(search);
  });

  const selectedUsers = users?.filter((user) =>
    form.watch('assigned_to').includes(user.id ?? ''),
  );

  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent>
        <SheetHeader>
          <SheetTitle>New Task</SheetTitle>
          <SheetDescription>
            Create a new task for this deal
          </SheetDescription>
        </SheetHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8 py-8">
            <FormField
              control={form.control}
              name="task"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Task Description</FormLabel>
                  <FormControl>
                    <textarea
                      {...field}
                      className="flex min-h-[100px] w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                      placeholder="Describe the task..."
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="due"
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <FormLabel>Due Date</FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant="outline"
                          className={cn(
                            'w-[240px] pl-3 text-left font-normal',
                            !field.value && 'text-muted-foreground',
                          )}
                        >
                          {field.value
                            ? (
                                format(field.value, 'PPP')
                              )
                            : (
                                <span>Pick a date</span>
                              )}
                          <CalendarIcon className="ml-auto size-4 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        mode="single"
                        selected={field.value}
                        onSelect={field.onChange}
                        disabled={(date) =>
                          date < new Date(new Date().setHours(0, 0, 0, 0))}
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="assigned_to"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Assignees</FormLabel>
                  <div className="space-y-4">
                    {selectedUsers && selectedUsers.length > 0 && (
                      <div className="flex flex-wrap gap-2">
                        {selectedUsers.map((user) => (
                          user && (
                            <div
                              key={user.id}
                              className="flex items-center gap-2 rounded-full bg-secondary px-3 py-1 text-sm"
                            >
                              <span>
                                {user.firstName}
                                {' '}
                                {user.lastName}
                              </span>
                              <button
                                type="button"
                                className="text-muted-foreground hover:text-foreground"
                                onClick={() => {
                                  const newValue = field.value.filter(
                                    (id) => id !== user.id,
                                  );
                                  field.onChange(newValue);
                                }}
                              >
                                ×
                              </button>
                            </div>
                          )
                        ))}
                      </div>
                    )}
                    <Command className="rounded-lg border shadow-md">
                      <CommandInput
                        placeholder="Search users..."
                        value={assigneeSearch}
                        onValueChange={setAssigneeSearch}
                      />
                      <CommandEmpty>No users found.</CommandEmpty>
                      <CommandGroup className="max-h-[200px] overflow-auto">
                        {filteredUsers?.map((user) => (
                          user && (
                            <CommandItem
                              key={user.id}
                              onSelect={() => {
                                const isSelected = field.value.includes(user.id ?? '');
                                if (isSelected) {
                                  const newValue = field.value.filter(
                                    (id) => id !== user.id,
                                  );
                                  field.onChange(newValue);
                                } else {
                                  field.onChange([...field.value, user.id ?? '']);
                                }
                              }}
                            >
                              <div
                                className={cn(
                                  'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                                  field.value.includes(user.id ?? '')
                                    ? 'bg-primary text-primary-foreground'
                                    : 'opacity-50 [&_svg]:invisible',
                                )}
                              >
                                <Check className="size-4" />
                              </div>
                              <div>
                                <div>
                                  {user.firstName}
                                  {' '}
                                  {user.lastName}
                                </div>
                                <div className="text-sm text-muted-foreground">
                                  {user.primaryEmailAddress?.emailAddress}
                                </div>
                              </div>
                            </CommandItem>
                          )
                        ))}
                      </CommandGroup>
                    </Command>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button type="submit" disabled={isPending}>
              Create Task
            </Button>
          </form>
        </Form>
      </SheetContent>
    </Sheet>
  );
}
