import { fromUnixTime, isValid } from 'date-fns';
import type { ThreadDB, NylasThread } from '@/types/thread';
import type { EmailName } from 'nylas';

/**
 * Returns a best-guess ISO timestamp derived from either a NylasThread or a ThreadDB.
 * If none of the relevant dates are available for a NylasThread, an empty string is returned.
 * For a ThreadDB, `created_at` is returned as a final fallback.
 *
 * @param thread - The thread object to extract a timestamp from
 * @returns A valid ISO timestamp or an empty string
 */
export function getThreadTimestamp(thread: ThreadDB | NylasThread): string {
  const isNylasThread = (obj: ThreadDB | NylasThread): obj is NylasThread =>
    'object' in obj && obj.object === 'thread';

  const dates: (number | null | undefined)[] = [];
  if (isNylasThread(thread)) {
    // Add dates from NylasThread
    dates.push(
      thread.latestDraftOrMessage?.date,
      thread.latestMessageReceivedDate,
      thread.latestMessageSentDate,
      thread.earliestMessageDate,
    );
  } else {
    // Add dates from ThreadDB
    dates.push(
      thread.latest_message_received_date,
      thread.latest_message_sent_date,
      thread.earliest_message_date,
    );
    // Add created_at as a final fallback for ThreadDB, converting from ISO to Unix timestamp
    if (thread.created_at) {
      const createdDate = new Date(thread.created_at);
      if (isValid(createdDate)) {
        dates.push(createdDate.getTime() / 1000);
      }
    }
  }

  // Filter out null/undefined values and find the maximum date
  const maxDate = Math.max(...dates.filter((date): date is number => typeof date === 'number'));
  return maxDate > 0 ? fromUnixTime(maxDate).toISOString() : '';
}

/** Type guard for EmailName objects */
export function isEmailName(value: unknown): value is EmailName {
  return typeof value === 'object'
    && value !== null
    && 'email' in value
    && typeof (value as EmailName).email === 'string';
}

/**
 * Parses and validates a participants value into an array of EmailName objects
 */
export function parseParticipants(participants: unknown): EmailName[] {
  if (!participants)
    return [];

  try {
    const parsed = typeof participants === 'string'
      ? JSON.parse(participants)
      : participants;

    return Array.isArray(parsed)
      ? parsed.filter((element) => isEmailName(element))
      : [];
  } catch {
    return [];
  }
}
