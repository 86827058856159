import { clsx, type ClassValue } from 'clsx';
import { htmlToText } from 'html-to-text';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function formatBytes(
  bytes: number,
  opts: {
    decimals?: number;
    sizeType?: 'accurate' | 'normal';
  } = {},
) {
  const { decimals = 0, sizeType = 'normal' } = opts;

  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const accurateSizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB'];
  if (bytes === 0)
    return '0 Byte';
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return `${(bytes / 1024 ** i).toFixed(decimals)} ${sizeType === 'accurate'
    ? (accurateSizes[i] ?? 'Bytes')
    : (sizes[i] ?? 'Bytes')
    }`;
}

/**
 * Converts HTML or any content to plain text and truncates to specified length with ellipsis
 * @param content - HTML string, plain text, or any other content to truncate
 * @param maxLength - Maximum length of the returned string
 * @returns Truncated string, with ellipsis if needed
 */
export function truncateText(content: unknown, maxLength: number): string {
  if (content === null || content === undefined) {
    return '';
  }

  const stringContent = typeof content === 'string' ? content : String(content);

  const plainText = htmlToText(stringContent, {
    wordwrap: false,
    selectors: [
      { selector: 'a', options: { ignoreHref: true } },
      { selector: 'img', format: 'skip' },
    ],
  });

  if (plainText.length <= maxLength) {
    return plainText;
  }
  const ellipsis = '…'; // Unicode ellipsis character
  return `${plainText.slice(0, maxLength - 1)}${ellipsis}`;
}
/**
 * Gets the last item of an array. Returns `undefined` for empty arrays, null, or invalid inputs.
 *
 * @param array - The array to retrieve the last item from.
 * @returns The last item of the array, or `undefined` if the array is empty or invalid.
 */
export function getLastItem<T>(array: T[] | null | undefined): T | undefined {
  if (!Array.isArray(array)) {
    return undefined;
  }
  return array.at(-1);
}
