import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import FormInput from '@/components/form-components/form-input';
import { Button } from '@/components/ui/button';
import { Sheet, SheetContent, SheetHeader, SheetTitle } from '@/components/ui/sheet';
import type { ContactLicense } from '@/hooks/use-contacts';

const licenseSchema = z.object({
  authority: z.string().min(1, 'Authority is required'),
  value: z.string().min(1, 'License number is required'),
});

type LicenseFormData = z.infer<typeof licenseSchema>;

type LicenseSheetProps = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  initialData?: ContactLicense;
  onSubmit: (data: LicenseFormData) => Promise<void>;
};

export function LicenseSheet({ open, onOpenChange, initialData, onSubmit }: LicenseSheetProps) {
  const form = useForm<LicenseFormData>({
    resolver: zodResolver(licenseSchema),
    defaultValues: {
      authority: '',
      value: '',
    },
  });

  // Update form values when initialData changes
  useEffect(() => {
    if (initialData) {
      form.reset({
        authority: initialData.authority,
        value: initialData.value,
      });
    } else {
      form.reset({
        authority: '',
        value: '',
      });
    }
  }, [initialData, form.reset]);

  const handleFormSubmit = async (data: LicenseFormData) => {
    await onSubmit(data);
    form.reset();
    onOpenChange(false);
  };

  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent
        side="right"
        className="w-full max-w-full sm:max-w-lg"
        onPointerDownOutside={(e) => e.preventDefault()}
      >
        <SheetHeader>
          <SheetTitle>{initialData ? 'Edit License' : 'New License'}</SheetTitle>
        </SheetHeader>
        <form onSubmit={form.handleSubmit(handleFormSubmit)} className="h-[calc(100vh-8rem)] space-y-6 overflow-y-auto px-1 pt-8">
          <FormInput
            control={form.control}
            name="authority"
            label="Authority"
            required
            rules={{ required: 'Authority is required' }}
            placeholder="e.g., FDIC, FRS"
          />

          <FormInput
            control={form.control}
            name="value"
            label="License Number"
            required
            rules={{ required: 'License number is required' }}
            placeholder="e.g., 12345"
          />

          <div className="pt-4">
            <Button type="submit" className="w-full" disabled={form.formState.isSubmitting}>
              {initialData ? 'Update License' : 'Add License'}
            </Button>
          </div>
        </form>
      </SheetContent>
    </Sheet>
  );
}
