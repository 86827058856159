import { useState, useEffect } from 'react';
import { useUser } from '@clerk/clerk-react';
import type { DragEndEvent } from '@dnd-kit/core';
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { PenTool, Plus, Save } from 'lucide-react';
import { toast } from 'sonner';
import { Button } from '@/components/ui/button';
import { useFdicSearch } from '@/hooks/use-fdic';
import { useTradeSearch } from '@/hooks/use-trade';
import type { VerificationDocumentProps, VerificationItem } from './types';
import { AddItemDialog } from './components/add-item-dialog';
import { SortableItem } from './components/sortable-item';
import { createDefaultItems } from './utils';

export function VerificationDocument({ deal, onSave }: VerificationDocumentProps): JSX.Element {
  const { user } = useUser();
  const userId = user?.id;
  const [items, setItems] = useState<VerificationItem[]>(() => {
    // Try to load existing items from verification_document
    if (deal.verification_document?.body) {
      try {
        const savedItems = JSON.parse(deal.verification_document.body);
        if (Array.isArray(savedItems) && savedItems.length > 0) {
          return savedItems as VerificationItem[];
        }
      } catch {
        // If parsing fails, continue to create default items
      }
    }

    // Create default items if verification_document is empty, null, or invalid
    const defaultItems = createDefaultItems(deal);

    // Save default items immediately
    if (!deal.verification_document?.body) {
      onSave(defaultItems);
    }

    return defaultItems;
  });

  const { mutate: searchTrade, isPending: isSearchingTrade } = useTradeSearch();
  const { mutate: searchFdic, isPending: isSearchingFdic } = useFdicSearch();
  const isSearching = isSearchingTrade || isSearchingFdic;

  // Update items when deal changes
  useEffect(() => {
    // Try to load existing items from verification_document
    if (deal.verification_document?.body) {
      try {
        const savedItems = JSON.parse(deal.verification_document.body);
        if (Array.isArray(savedItems) && savedItems.length > 0) {
          setItems(savedItems as VerificationItem[]);
          return;
        }
      } catch {
        // If parsing fails, continue to create default items
      }
    }

    // Create default items if verification_document is empty, null, or invalid
    const defaultItems = createDefaultItems(deal);

    // Save default items immediately
    if (!deal.verification_document?.body) {
      onSave(defaultItems);
    }

    setItems(defaultItems);
  }, [deal, onSave]);

  const handleVerifyOFAC = (id: string): void => {
    const item = items.find((i) => i.id === id && i.type === 'ofac');
    if (!item || item.type !== 'ofac')
      return;

    searchTrade(item.entityName, {
      onSuccess: (data) => {
        setItems((currentItems) => {
          const updatedItems = currentItems.map((i: VerificationItem) =>
            i.id === id && i.type === 'ofac'
              ? {
                  ...i,
                  searchResults: data,
                  verified: true,
                  verifiedBy: {
                    userId: userId!,
                    name: user?.fullName || 'Unknown User',
                    timestamp: new Date().toISOString(),
                  },
                }
              : i,
          );
          onSave(updatedItems);
          return updatedItems;
        });
        toast.success('OFAC check completed and saved');
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });
  };

  const handleVerifyFDIC = (id: string): void => {
    const item = items.find((i) => i.id === id && i.type === 'fdic');
    if (!item || item.type !== 'fdic')
      return;

    searchFdic(item.certNumber, {
      onSuccess: (data) => {
        setItems((currentItems) => {
          const updatedItems = currentItems.map((i: VerificationItem) => {
            // Update FDIC check item
            if (i.id === id && i.type === 'fdic') {
              return {
                ...i,
                searchResults: data,
                verified: true,
                verifiedBy: {
                  userId: userId!,
                  name: user?.fullName || 'Unknown User',
                  timestamp: new Date().toISOString(),
                },
              };
            }

            // Update signable notes if we have valid FDIC results
            if (typeof data.results !== 'string') {
              const results = data.results;
              const orgEligibilityString = `FDIC Results: FDIC #${item.certNumber} (${results.basic_info.name})`;
              // Update org eligibility note
              if (i.id === 'org-eligibility' && i.type === 'note' && !i.content.includes(orgEligibilityString)) {
                return {
                  ...i,
                  content: `${orgEligibilityString}\n${i.content}`,
                };
              }

              const emailEligibilityString = `FDIC Results: FDIC #${item.certNumber} (${results.basic_info.website})`;
              // Update email eligibility note
              if (i.id === 'email-eligibility' && i.type === 'note' && !i.content.includes(emailEligibilityString)) {
                return {
                  ...i,
                  content: `${emailEligibilityString}\n${i.content}`,
                };
              }
              const orgAddressStringAddress = [
                results.location.address,
                results.location.address2,
                results.location.city,
                results.location.state,
                results.location.zip,
              ].filter(Boolean).join(', ');
              const orgAddressString = `FDIC Results: FDIC #${item.certNumber} (${orgAddressStringAddress})`;
              // Update org address note
              if (i.id === 'org-address' && i.type === 'note' && !i.content.includes(orgAddressString)) {
                return {
                  ...i,
                  content: `${orgAddressString}\n${i.content}`,
                };
              }
            }

            return i;
          });
          onSave(updatedItems);
          return updatedItems;
        });
        toast.success('FDIC check completed and saved');
      },
      onError: (error: Error) => {
        toast.error(error.message);
      },
    });
  };

  const handleSignNote = (id: string, role: 'creator' | 'reviewer'): void => {
    const updatedItems = items.map((item) => {
      if (item.id !== id)
        return item;

      // All verification items (note, ofac, fdic) have signatures
      const existingSignature = item.signatures.find(
        (s) => s.userId === userId && s.role === role,
      );

      if (existingSignature) {
        return {
          ...item,
          signatures: item.signatures.filter(
            (s) => !(s.userId === userId && s.role === role),
          ),
        };
      }

      return {
        ...item,
        signatures: [
          ...item.signatures,
          {
            userId: userId!,
            name: user?.fullName || 'Unknown User',
            role,
            timestamp: new Date().toISOString(),
          },
        ],
      };
    });

    setItems(updatedItems);
    onSave(updatedItems);
  };

  const handleUpdateNoteContent = (id: string, content: string): void => {
    setItems(
      items.map((item) =>
        item.id === id && item.type === 'note'
          ? { ...item, content }
          : item,
      ),
    );
  };

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 100,
        tolerance: 8,
      },
    }),
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (!over || active.id === over.id)
      return;

    setItems((items) => {
      const oldIndex = items.findIndex((item) => item.id === active.id);
      const newIndex = items.findIndex((item) => item.id === over.id);
      return arrayMove(items, oldIndex, newIndex);
    });
  };

  const handleBulkSignAsReviewer = (): void => {
    const updatedItems = items.map((item) => {
      // Skip if already signed by this user as reviewer
      if (item.signatures.some((s) => s.userId === userId && s.role === 'reviewer')) {
        return item;
      }

      // Only sign items that are either verified (for OFAC/FDIC) or are notes
      if ((item.type === 'ofac' || item.type === 'fdic') && !item.verified) {
        return item;
      }

      return {
        ...item,
        signatures: [
          ...item.signatures,
          {
            userId: userId!,
            name: user?.fullName || 'Unknown User',
            role: 'reviewer' as const,
            timestamp: new Date().toISOString(),
          },
        ],
      };
    });

    setItems(updatedItems);
    onSave(updatedItems);
    toast.success('Bulk signed all eligible items as reviewer');
  };

  return (
    <div className="flex h-full max-h-[600px] flex-col overflow-hidden bg-background">
      <div className="flex items-center justify-between border-b p-4">
        <h3 className="text-sm font-bold">Verification Document</h3>
        <div className="flex items-center gap-2">
          <Button
            variant="outline"
            onClick={handleBulkSignAsReviewer}
            className="gap-2"
          >
            <PenTool className="size-4" />
            Sign as Reviewer
          </Button>
          <Button
            variant="default"
            onClick={() => onSave(items)}
            className="gap-2"
          >
            <Save className="size-4" />
            Save
          </Button>
          <AddItemDialog onAddItem={(item) => setItems([...items, item])} />
        </div>
      </div>
      <div className="flex-1 space-y-4 overflow-y-auto p-4">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={items.map((item) => item.id)}
            strategy={verticalListSortingStrategy}
          >
            {items.map((item) => (
              <SortableItem
                key={item.id}
                id={item.id}
                item={item}
                onVerifyOFAC={handleVerifyOFAC}
                onVerifyFDIC={handleVerifyFDIC}
                onUpdateNoteContent={handleUpdateNoteContent}
                onSignNote={handleSignNote}
                onDelete={(id) => setItems(items.filter((item) => item.id !== id))}
                isSearching={isSearching}
                userId={userId}
                items={items}
                setItems={setItems}
                deal={deal}
              />
            ))}
          </SortableContext>
        </DndContext>
      </div>
    </div>
  );
}
