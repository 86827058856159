import type { Deal } from '@/hooks/use-deals';
import type { VerificationItem, OFACCheck, FdicCheck, SignableNote } from './types';

export function createDefaultItems(deal: Deal): VerificationItem[] {
  const ofacChecks: OFACCheck[] = [];
  const fdicChecks: FdicCheck[] = [];

  // Add organization OFAC checks
  if (deal.registrants.organizations?.length > 0) {
    for (const org of deal.registrants.organizations) {
      ofacChecks.push({
        id: `ofac-org-${org.name}`,
        type: 'ofac',
        entityName: org.name,
        searchResults: null,
        verified: false,
        signatures: [],
      });
    }
  }

  // Add person OFAC checks
  if (deal.registrants.people?.length > 0) {
    ofacChecks.push(
      ...deal.registrants.people.map((person): OFACCheck => ({
        id: `ofac-${person.email}`,
        type: 'ofac',
        entityName: person.name,
        searchResults: null,
        verified: false,
        signatures: [],
      })),
    );
  }

  // Add organization FDIC checks
  if (deal.registrants.organizations?.length > 0) {
    for (const org of deal.registrants.organizations) {
      const fdicLicense = org.licenses.find((license) => license.authority === 'FDIC');
      if (fdicLicense) {
        fdicChecks.push({
          id: `fdic-org-${org.name}`,
          type: 'fdic',
          entityName: org.name,
          certNumber: fdicLicense.value,
          searchResults: null,
          verified: false,
          signatures: [],
        });
      }
    }
  }

  const notes: SignableNote[] = [
    {
      id: 'org-eligibility',
      type: 'note',
      title: 'Organization Eligibility',
      content: '',
      signatures: [],
    },
    {
      id: 'email-eligibility',
      type: 'note',
      title: 'Email Eligibility',
      content: '',
      signatures: [],
    },
    {
      id: 'org-address',
      type: 'note',
      title: 'Organization Address',
      content: '',
      signatures: [],
    },
    {
      id: 'name-selection',
      type: 'note',
      title: 'Name Selection',
      content: '',
      signatures: [],
    },
    {
      id: 'employment-authority',
      type: 'note',
      title: 'Employment Authority',
      content: '',
      signatures: [],
    },
  ];

  return [...ofacChecks, ...fdicChecks, ...notes];
}
