import React, { forwardRef } from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import { X } from 'lucide-react';
import { cn } from '@/lib/utils';

const chipVariants = cva(
  'flex cursor-pointer select-none items-center gap-1 rounded-full text-sm transition-all duration-150',
  {
    variants: {
      variant: {
        default: '',
        error: 'bg-red-100 text-red-800 hover:bg-red-200 hover:text-red-900',
      },
      selected: {
        true: 'ring-2',
        false: '',
      },
    },
    compoundVariants: [
      {
        variant: 'default',
        selected: false,
        className: 'bg-blue-100 text-blue-800 hover:bg-blue-200 hover:text-blue-900',
      },
      {
        variant: 'default',
        selected: true,
        className: 'bg-blue-200 text-blue-900 ring-blue-400',
      },
      {
        variant: 'error',
        selected: true,
        className: 'bg-red-200 text-red-900 ring-red-400',
      },
    ],
    defaultVariants: {
      variant: 'default',
      selected: false,
    },
  },
);

interface EmailChipProps extends React.ComponentPropsWithoutRef<'div'>,
  VariantProps<typeof chipVariants> {
  email: string;
  onRemove: () => void;
  isSelected: boolean;
  onClick: (e: React.MouseEvent) => void;
  hasError?: boolean;
  disabled?: boolean;
}

const EmailChip = forwardRef<HTMLDivElement, EmailChipProps>((props, ref) => {
  const { email, onRemove, isSelected, onClick, hasError, disabled, className, ...rest } = props;

  return (
    <div
      ref={ref}
      onClick={disabled ? undefined : onClick}
      className={cn(
        chipVariants({
          variant: hasError ? 'error' : 'default',
          selected: isSelected,
          className,
        }),
        'px-1.5 py-0.5',
        disabled && 'opacity-50 cursor-not-allowed',
      )}
      {...rest}
    >
      <span className="px-0.5">{email}</span>
      {!disabled && (
        <button
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            onRemove();
          }}
          className={cn(
            'rounded-full p-0.5 transition-all duration-150',
            hasError
              ? 'hover:bg-red-300/80 active:bg-red-400'
              : 'hover:bg-blue-300/80 active:bg-blue-400',
            'hover:scale-110 active:scale-90',
            'outline-none focus:ring-2',
            hasError ? 'focus:ring-red-400' : 'focus:ring-blue-400',
          )}
          aria-label="Remove email"
        >
          <X size={12} />
        </button>
      )}
    </div>
  );
});

EmailChip.displayName = 'EmailChip';

export { chipVariants, type EmailChipProps };
export default EmailChip;
