import type { User } from '@clerk/backend';
import { useQuery } from '@tanstack/react-query';
import { useAxios } from './use-axios';

/** API response type for single user requests */
type ClerkUserResponse = {
  status: 'success' | 'error';
  data?: Partial<User>;
  message?: string;
};

/** API response type for multiple users requests */
type ClerkUsersResponse = {
  status: 'success' | 'error';
  data?: Partial<User>[];
  message?: string;
};

/** Query keys for React Query caching */
export const CLERK_USER_QUERY_KEY = {
  single: 'clerk-user',
  batch: 'clerk-users-batch',
  all: 'clerk-users-all',
  byEmail: 'clerk-user-by-email',
} as const;

/** API response type for user ID by email requests */
type UserIdByEmailResponse = {
  status: 'success' | 'error';
  data?: {
    userId: string;
  };
  message?: string;
};

/**
 * Hook to fetch details for a single Clerk user
 *
 * @param userId - The ID of the user to fetch. If not provided, the query will be disabled
 * @returns Query result containing the user's details
 *
 * @example
 * ```tsx
 * const { data: user, isLoading } = useClerkUser('user_123');
 *
 * if (isLoading) return <div>Loading...</div>;
 * if (user) return <div>Hello {user.firstName}!</div>;
 * ```
 */
export function useClerkUser(userId?: string) {
  const axios = useAxios();

  return useQuery({
    queryKey: [CLERK_USER_QUERY_KEY.single, userId],
    queryFn: async () => {
      if (!userId)
        return null;

      try {
        const { data } = await axios.get<ClerkUserResponse>(`/user/${userId}`);
        if (data.status === 'error' || !data.data) {
          throw new Error(data.message || 'Failed to fetch user');
        }
        return data.data;
      } catch (error) {
        if (error instanceof Error) {
          throw error;
        }
        throw new Error('Failed to fetch user');
      }
    },
    // Only run the query if we have a userId
    enabled: !!userId,
  });
}

/**
 * Hook to fetch details for multiple Clerk users in a single request
 *
 * @param userIds - Array of user IDs to fetch. If not provided or empty, the query will be disabled
 * @returns Query result containing an array of user details
 *
 * @example
 * ```tsx
 * const { data: users, isLoading } = useClerkUsers(['user_123', 'user_456']);
 *
 * if (isLoading) return <div>Loading...</div>;
 * return (
 *   <ul>
 *     {users?.map(user => (
 *       <li key={user.id}>{user.firstName} {user.lastName}</li>
 *     ))}
 *   </ul>
 * );
 * ```
 */
export function useClerkUsers(userIds?: string[]) {
  const axios = useAxios();

  return useQuery({
    queryKey: [CLERK_USER_QUERY_KEY.batch, userIds],
    queryFn: async () => {
      if (!userIds?.length)
        return [];

      try {
        const { data } = await axios.post<ClerkUsersResponse>('/user/details', {
          userIds,
        });
        if (data.status === 'error' || !data.data) {
          throw new Error(data.message || 'Failed to fetch users');
        }
        return data.data;
      } catch (error) {
        if (error instanceof Error) {
          throw error;
        }
        throw new Error('Failed to fetch users');
      }
    },
    // Only run the query if we have userIds
    enabled: !!userIds?.length,
  });
}

/**
 * Hook to get a user ID by email address
 *
 * @param email - The email address to look up. If not provided, the query will be disabled
 * @returns Query result containing the user ID if found
 *
 * @example
 * ```tsx
 * const { data, isLoading, error } = useClerkUserIdByEmail('user@example.com');
 *
 * if (isLoading) return <div>Loading...</div>;
 * if (error) return <div>Error: {error.message}</div>;
 * if (data) return <div>User ID: {data.userId}</div>;
 * ```
 */
export function useClerkUserIdByEmail(email?: string) {
  const axios = useAxios();

  return useQuery({
    queryKey: [CLERK_USER_QUERY_KEY.byEmail, email],
    queryFn: async () => {
      if (!email)
        return null;

      try {
        const { data } = await axios.get<UserIdByEmailResponse>(`/user/by-email/${encodeURIComponent(email)}`);
        if (data.status === 'error' || !data.data) {
          throw new Error(data.message || 'Failed to fetch user ID');
        }
        return data.data;
      } catch (error) {
        if (error instanceof Error) {
          throw error;
        }
        throw new Error('Failed to fetch user ID');
      }
    },
    // Only run the query if we have an email
    enabled: !!email,
  });
}

/**
 * Hook to fetch all Clerk users
 *
 * The data is cached for 1 hour before being considered stale.
 * Stale data will be returned immediately while a background refresh occurs.
 *
 * @returns Query result containing an array of all user details
 *
 * @example
 * ```tsx
 * const { data: users, isLoading } = useAllClerkUsers();
 *
 * if (isLoading) return <div>Loading...</div>;
 * return (
 *   <ul>
 *     {users?.map(user => (
 *       <li key={user.id}>{user.firstName} {user.lastName}</li>
 *     ))}
 *   </ul>
 * );
 * ```
 */
export function useAllClerkUsers() {
  const axios = useAxios();

  return useQuery({
    queryKey: [CLERK_USER_QUERY_KEY.all],
    queryFn: async () => {
      try {
        const { data } = await axios.get<ClerkUsersResponse>('/user/list/all');
        if (data.status === 'error' || !data.data) {
          throw new Error(data.message || 'Failed to fetch all users');
        }
        return data.data;
      } catch (error) {
        if (error instanceof Error) {
          throw error;
        }
        throw new Error('Failed to fetch all users');
      }
    },
    staleTime: 60 * 60 * 1000, // Consider data stale after 1 hour
    refetchOnMount: true, // Refetch when component mounts if data is stale
    refetchOnWindowFocus: true, // Refetch when window regains focus if data is stale
  });
}
