import * as React from 'react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { cn } from '@/lib/utils';
import type { EmailName } from 'nylas';

type EmailNameDisplayProps = {
  className?: string;
  emailNames: EmailName[] | undefined;
  label?: string;
};

export function EmailNameDisplay({ className, emailNames, label }: EmailNameDisplayProps) {
  if (!emailNames?.length)
    return null;

  return (
    <div className={cn('text-sm text-muted-foreground', className)}>
      {label && (
        <span className="pr-2 font-semibold">
          {label}
          :
        </span>
      )}
      <span>
        {emailNames.map((emailName, index) => (
          <React.Fragment key={emailName.email}>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger className="cursor-default">
                  {emailName.name || emailName.email}
                </TooltipTrigger>
                <TooltipContent>
                  <p>{emailName.email}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
            {index < emailNames.length - 1 && ', '}
          </React.Fragment>
        ))}
      </span>
    </div>
  );
}
