import { Sheet, SheetContent, SheetDescription, SheetTitle } from "@/components/ui/sheet";
import { CommunicationsHub } from "../communications";

type CommunicationsSheetProps = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

function CommunicationsSheet({ open, onOpenChange }: CommunicationsSheetProps) {
  return (
    <Sheet open={open} onOpenChange={onOpenChange} >
      <SheetContent className="w-full h-screen" side="bottom">
        <SheetTitle className="hidden">Communications Hub</SheetTitle>
        <SheetDescription className="hidden">Communications Hub for the selected deal</SheetDescription>
        <CommunicationsHub />
      </SheetContent>
    </Sheet>

  )
}

export default CommunicationsSheet;
