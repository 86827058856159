import { Calendar } from '@/components/ui/calendar';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import type { SchedulerTimeValue } from '@/types/scheduler';
import { formatInEST } from '@/types/scheduler';

interface SchedulerTimeSelectProps {
  field: {
    value: SchedulerTimeValue;
    onChange: (value: SchedulerTimeValue) => void;
  };
}

export function SchedulerTimeSelect({ field }: SchedulerTimeSelectProps) {
  const hours = Array.from({ length: 12 }, (_, i) => (i + 1).toString().padStart(2, '0'));
  const minutes = Array.from({ length: 60 }, (_, i) => i.toString().padStart(2, '0'));

  return (
    <div className="space-y-4">
      <Calendar
        mode="single"
        selected={field.value.date}
        onSelect={(date) => date && field.onChange({ ...field.value, date })}
        disabled={(date) => date < new Date()}
        initialFocus
      />

      <div className="flex items-center gap-2 p-3">
        <Select
          value={field.value.hours}
          onValueChange={(value) => field.onChange({ ...field.value, hours: value })}
        >
          <SelectTrigger className="w-[70px]">
            <SelectValue placeholder="HH" />
          </SelectTrigger>
          <SelectContent>
            {hours.map((hour) => (
              <SelectItem key={hour} value={hour}>
                {hour}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        <span>:</span>
        <Select
          value={field.value.minutes}
          onValueChange={(value) => field.onChange({ ...field.value, minutes: value })}
        >
          <SelectTrigger className="w-[70px]">
            <SelectValue placeholder="MM" />
          </SelectTrigger>
          <SelectContent>
            {minutes.map((minute) => (
              <SelectItem key={minute} value={minute}>
                {minute}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        <Select
          value={field.value.period}
          onValueChange={(value: 'AM' | 'PM') => field.onChange({ ...field.value, period: value })}
        >
          <SelectTrigger className="w-[70px]">
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="AM">AM</SelectItem>
            <SelectItem value="PM">PM</SelectItem>
          </SelectContent>
        </Select>
      </div>

      <div className="text-sm text-muted-foreground">
        Scheduled for:
        {' '}
        {formatInEST(field.value)}
      </div>
    </div>
  );
}
