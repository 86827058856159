import type { ColDef } from '@ag-grid-community/core';
import type { RegulatoryLink } from '@/types/regulatory-link';

function URLRenderer(props: { value: string }) {
  return (
    <a
      href={props.value}
      target="_blank"
      rel="noopener noreferrer"
      className="text-blue-600 hover:text-blue-800"
    >
      {props.value}
    </a>
  );
}

export const databaseColumnDefs: ColDef<RegulatoryLink>[] = [
  {
    field: 'countryByCountry.name',
    headerName: 'Country',
    flex: 1,
    sortable: true,
    filter: true,
  },
  {
    field: 'purpose',
    headerName: 'Purpose',
    flex: 1,
    sortable: true,
    filter: true,
  },
  {
    field: 'url',
    headerName: 'URL',
    flex: 2,
    sortable: true,
    filter: true,
    cellRenderer: URLRenderer,
  },
  {
    field: 'notes',
    headerName: 'Notes',
    flex: 2,
    sortable: true,
    filter: true,
  },
  {
    field: 'created_at',
    headerName: 'Created At',
    flex: 1,
    sortable: true,
    filter: true,
    valueFormatter: (params) => {
      return new Date(params.value).toLocaleDateString();
    },
  },
  {
    field: 'updated_at',
    headerName: 'Updated At',
    flex: 1,
    sortable: true,
    filter: true,
    valueFormatter: (params) => {
      return new Date(params.value).toLocaleDateString();
    },
  },
];
