import type { Deal } from '@/hooks/use-deals';

export function getOrganizationNames(deal: Deal): string[] {
  const names = new Set<string>();

  // Add organization names from registrants
  for (const org of deal.registrants?.organizations) {
    names.add(org.name);
  }

  // Add organization names from verification contacts
  for (const person of deal.verification_contacts?.people) {
    if (person.organization_name) {
      names.add(person.organization_name);
    }
  }

  return [...names];
}

export function getRegulatoryLicenses(deal: Deal): string[] {
  const licenses = new Set<string>();

  // Add licenses from registrant organizations
  for (const org of deal.registrants?.organizations) {
    for (const license of org.licenses) {
      // Format license to match domain's license format: "authority,value"
      licenses.add(`${license.authority},${license.value}`);
    }
  }

  return [...licenses];
}
