/**
 * Enum representing the available webhook triggers
 * Matches the Nylas WebhookTriggers enum but defined here to avoid strange crashing issue on import
 */
export enum WebhookTriggers {
  CalendarCreated = 'calendar.created',
  CalendarUpdated = 'calendar.updated',
  CalendarDeleted = 'calendar.deleted',
  EventCreated = 'event.created',
  EventUpdated = 'event.updated',
  EventDeleted = 'event.deleted',
  GrantCreated = 'grant.created',
  GrantUpdated = 'grant.updated',
  GrantDeleted = 'grant.deleted',
  GrantExpired = 'grant.expired',
  MessageCreated = 'message.created',
  MessageUpdated = 'message.updated',
  MessageSendSuccess = 'message.send_success',
  MessageSendFailed = 'message.send_failed',
  MessageBounceDetected = 'message.bounce_detected',
  MessageOpened = 'message.opened',
  MessageLinkClicked = 'message.link_clicked',
  ThreadReplied = 'thread.replied',
  MessageIntelligenceOrder = 'message.intelligence.order',
  MessageIntelligenceTracking = 'message.intelligence.tracking',
  FolderCreated = 'folder.created',
  FolderUpdated = 'folder.updated',
  FolderDeleted = 'folder.deleted',
  ContactUpdated = 'contact.updated',
  ContactDeleted = 'contact.deleted',
  BookingCreated = 'booking.created',
  BookingPending = 'booking.pending',
  BookingRescheduled = 'booking.rescheduled',
  BookingCancelled = 'booking.cancelled',
  BookingReminder = 'booking.reminder'
}
