import { useCallback, useState, useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import type { Content, Editor } from '@tiptap/react';
import { Paperclip, Smile, AtSign, Send } from 'lucide-react';
import { AiOutlineSignature } from 'react-icons/ai';
import { toast } from 'sonner';
import type { EmailChipsInputHandle } from '@/components/email-recipient-input';
import EmailRecipientInput from '@/components/email-recipient-input';
import { TiptapEditor } from '@/components/tiptap';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Toggle } from '@/components/ui/toggle';
import { useDeal } from '@/contexts/deal-context';
import { useHub } from '@/contexts/hub-context';
import { useEmailTemplates, useRenderEmailTemplate } from '@/hooks/use-email-templates';
import { useUserTemplates } from '@/hooks/use-user-templates';
import { convertToEmailHtml } from '@/lib/email';
import { VERIFY_EMAIL } from '@/lib/globals';
import { parseParticipants } from '@/lib/threads';
import { getLastItem } from '@/lib/utils';

interface MessageInputProps {
  onSend: (content: { html: string; isEmail?: boolean; to?: string[]; cc?: string[]; subject?: string }) => void;
  onUploadFiles?: (files: File[]) => void;
}

type FormValues = {
  subject?: string;
  recipients?: string[];
  ccRecipients?: string[];
  html: string;
};

export function MessageInput({ onSend, onUploadFiles }: MessageInputProps) {
  const { replyThread, replyMessage, setReplyThread, myGrantEmail, verifyUserId, sendAsGrantId, sendAsEmailAddress } = useHub();
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [isEmailMode, setIsEmailMode] = useState(true);
  const [showCc, setShowCc] = useState(true);
  const [emailSubject, setEmailSubject] = useState('');
  const { template } = useUserTemplates({
    name: 'personal-footer',
    userId: sendAsEmailAddress === VERIFY_EMAIL ? verifyUserId : undefined,
  });
  const [showTemplateDialog, setShowTemplateDialog] = useState(false);
  const [editorContent, setEditorContent] = useState<Content>('');
  const { dealId } = useDeal();
  const [selectedTemplate, setSelectedTemplate] = useState<string>();
  const editorRef = useRef<Editor | null>(null);
  const emailInputRef = useRef<EmailChipsInputHandle>(null);
  const ccInputRef = useRef<EmailChipsInputHandle>(null);

  const { data: templates } = useEmailTemplates();

  const { control, watch } = useForm<FormValues>({
    defaultValues: {
      recipients: [],
      ccRecipients: [],
    },
  });

  // Watch the recipients fields for validation
  const recipients = watch('recipients');
  const ccRecipients = watch('ccRecipients');

  const { data: renderedTemplate, isLoading: isRenderingTemplate, error: renderError } = useRenderEmailTemplate(
    selectedTemplate ?? '',
    dealId ?? '',
    recipients,
  );

  const [searchQuery, setSearchQuery] = useState('');

  const sortedAndFilteredTemplates = templates
    ?.sort((a, b) => a.name.localeCompare(b.name))
    .filter((template) =>
      template.name.toLowerCase().includes(searchQuery.toLowerCase())
      || template.short_description?.toLowerCase().includes(searchQuery.toLowerCase()),
    );

  // Set email mode and subject when reply thread changes
  useEffect(() => {
    if (replyThread || replyMessage) {
      // Force email mode
      setIsEmailMode(true);
      // Set subject with Re: prefix if not already present
      const subject = replyMessage?.content.subject || replyThread?.subject || '';
      setEmailSubject(subject.startsWith('Re:') ? subject : `Re: ${subject}`);
    }
  }, [replyThread, replyMessage]);

  // Set email addresses after email mode is enabled
  // TODO: Clean this up plz
  useEffect(() => {
    if (replyThread && isEmailMode && replyThread.participants) {
      // Add a small delay to ensure component is mounted
      const timer = setTimeout(() => {
        // Clear any existing emails first
        emailInputRef.current?.clear();
        ccInputRef.current?.clear();

        // Extract emails from the reply message if it exists
        const messageToEmailAddresses = parseParticipants([
          ...(replyMessage?.from ?? []),
          ...(replyMessage?.to ?? []),
        ])
          .map((participant) => participant.email)
          .filter((email): email is string => Boolean(email) && email !== myGrantEmail);

        const messageCCEmailAddresses = parseParticipants(replyMessage?.cc ?? [])
          .map((participant) => participant.email)
          .filter((email): email is string => Boolean(email) && email !== myGrantEmail);

        // Extract emails from thread participants
        const threadEmailAddresses = parseParticipants(replyThread.participants)
          .map((participant) => participant.email)
          // filter out myGrantEmail and empty emails
          .filter((email): email is string => Boolean(email) && email !== myGrantEmail);

        // Add message recipients to the To field
        if (emailInputRef.current) {
          if (!messageToEmailAddresses && !messageCCEmailAddresses) {
            // If no message recipients, add thread participants to the To field
            for (const email of threadEmailAddresses) {
              emailInputRef.current?.addEmail(email);
            }
          } else {
            // First add message recipients if they exist
            for (const email of messageToEmailAddresses) {
              emailInputRef.current?.addEmail(email);
            }

            // Next add CC recipients if they exist
            if (ccInputRef.current) {
              for (const email of messageCCEmailAddresses) {
                ccInputRef.current?.addEmail(email);
              }
            }
          }
        }
      }, 100); // Small delay to ensure component is ready

      return () => clearTimeout(timer);
    }
  }, [replyThread, replyMessage, isEmailMode, myGrantEmail]);

  const handleSend = useCallback(() => {
    if (!editorContent)
      return;

    // get the editor content as HTML string
    const html = typeof editorContent === 'string' ? editorContent : editorContent.toString();
    // Convert the HTML content to email-friendly HTML
    const emailHtml = convertToEmailHtml(html);
    // Prepare the content object based on mode
    const content = {
      html: emailHtml,
      isEmail: isEmailMode,
      ...(isEmailMode && {
        to: recipients,
        cc: ccRecipients,
        subject: emailSubject,
        threadGrantId: replyThread?.grant_id,
        replyToMessageId: getLastItem(replyThread?.message_ids),
        sendAsGrantId,
      }),
    };

    onSend(content);

    // Clear replyThread if this was a reply email
    if (isEmailMode && replyThread?.message_ids) {
      setReplyThread(undefined, undefined);
    }

    // Clear the editor content using both state and editor commands
    setEditorContent('');
    if (editorRef.current) {
      editorRef.current.commands.clearContent();
    }

    if (isEmailMode) {
      emailInputRef.current?.clear();
      ccInputRef.current?.clear();
      setEmailSubject('');
    }
  }, [editorContent, onSend, setReplyThread, replyThread, isEmailMode, recipients, ccRecipients, emailSubject, sendAsGrantId]);

  const handleFileUpload = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const files = [...e.target.files || []];
    if (files.length > 0 && onUploadFiles) {
      onUploadFiles(files);
      e.target.value = ''; // Reset input
    }
  }, [onUploadFiles]);

  const insertEmoji = useCallback((emoji: any) => {
    if (editorRef.current) {
      editorRef.current.commands.insertContent(emoji.native);
    }
    setShowEmojiPicker(false);
  }, []);

  const handleFileButtonClick = useCallback(() => {
    const fileInput = document.querySelector('#file-upload') as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  }, []);

  const handleTemplateSelect = useCallback((template: { name: string }) => {
    // Close dialog immediately for better UX
    setShowTemplateDialog(false);
    // Set selected template to trigger render
    setSelectedTemplate(template.name);
  }, []);

  // Update editor when template renders
  useEffect(() => {
    if (renderedTemplate && editorRef.current) {
      editorRef.current.commands.setContent(renderedTemplate.html);
      setEditorContent(renderedTemplate.html);

      // Set email-related fields if in email mode
      if (isEmailMode) {
        setEmailSubject(renderedTemplate.subject);
        if (renderedTemplate.to) {
          for (const email of renderedTemplate.to) {
            emailInputRef.current?.addEmail(email);
          }
        }
      }

      // Clear selected template to prevent re-rendering and overwriting user changes
      setSelectedTemplate(undefined);
    }
  }, [renderedTemplate, isEmailMode]);

  // Show error toast when template rendering fails
  useEffect(() => {
    if (renderError) {
      toast.error('Failed to render template', {
        description: renderError.message,
      });
      // Clear selected template to allow retrying
      setSelectedTemplate(undefined);
    }
  }, [renderError]);

  const handleSpecialCharacter = useCallback((char: string) => {
    if (editorRef.current) {
      editorRef.current.commands.insertContent(char);
    }
  }, []);

  const handleInsertSignature = useCallback((userSignature: string) => {
    if (editorRef.current) {
      editorRef.current.commands.insertContent(userSignature);
    }
  }, []);

  const handleEditorChange = useCallback((editor: Editor) => {
    editorRef.current = editor;
  }, []);

  const isSendEnabled = useCallback(() => {
    if (!editorContent)
      return false;
    if (!isEmailMode)
      return true;
    if (!emailSubject)
      return false;

    // Check if we have valid email recipients from the form
    return recipients && recipients.length > 0;
  }, [editorContent, isEmailMode, emailSubject, recipients]);

  return (
    <div className="bg-background">
      {isEmailMode && (
        <div className="mb-2 space-y-2">
          <div className="flex items-center gap-2">
            <Label htmlFor="to" className="w-16">To:</Label>
            <div className="flex-1">
              <EmailRecipientInput
                ref={emailInputRef}
                control={control}
                name="recipients"
              />
            </div>
            <Toggle
              type="button"
              variant="outline"
              size="sm"
              pressed={showCc}
              onClick={() => setShowCc(!showCc)}
              className="text-muted-foreground hover:text-foreground"
            >
              CC
            </Toggle>
          </div>
          {showCc && (
            <div className="flex items-center gap-2">
              <Label htmlFor="cc" className="w-16">CC:</Label>
              <div className="flex-1">
                <EmailRecipientInput
                  ref={ccInputRef}
                  control={control}
                  name="ccRecipients"
                  placeholder="Enter CC'd recipients"
                />
              </div>
            </div>
          )}
          <div className="flex items-center gap-2">
            <Label htmlFor="subject" className="w-16">Subject:</Label>
            <Input
              id="subject"
              value={emailSubject}
              onChange={(e) => setEmailSubject(e.target.value)}
              placeholder="Enter subject"
              className="flex-1"
            />
          </div>
        </div>
      )}

      <div className="rounded-md border bg-background">
        <TiptapEditor
          value={editorContent}
          onChange={setEditorContent}
          placeholder="Type a message..."
          className="min-h-[100px]"
          hideSections={['one', 'tasks']} // Hide unnecessary sections for message input
          disableAutolink // Prevent automatic link conversion
          editorContentClassName="prose prose-sm max-w-none focus:outline-none min-h-[40px] max-h-[300px] overflow-y-auto px-3 py-2"
          onCreate={({ editor }) => handleEditorChange(editor)}
          isEmailMode={isEmailMode}
          onEmailModeChange={(mode) => {
            setIsEmailMode(mode);
            if (!mode && replyThread) {
              setReplyThread(undefined);
            }
          }}
          onTemplateClick={() => setShowTemplateDialog(true)}
        />
        <div className="flex items-center justify-between border-t bg-muted/40 px-2 py-1">
          <div className="flex items-center gap-0.5">
            <Popover open={showEmojiPicker} onOpenChange={setShowEmojiPicker}>
              <PopoverTrigger asChild>
                <Button
                  variant="ghost"
                  size="sm"
                  className="size-8 p-0"
                >
                  <Smile className="size-4" />
                </Button>
              </PopoverTrigger>
              <PopoverContent
                className="w-auto border-none p-0"
                sideOffset={5}
                align="start"
              >
                <Picker
                  data={data}
                  onEmojiSelect={insertEmoji}
                  theme="light"
                  previewPosition="none"
                />
              </PopoverContent>
            </Popover>

            <Button
              variant="ghost"
              size="sm"
              className="size-8 p-0"
              onClick={() => handleSpecialCharacter('@')}
            >
              <AtSign className="size-4" />
            </Button>

            <Button
              variant="ghost"
              size="sm"
              className="size-8 p-0"
              onClick={() => template && handleInsertSignature(template.template)}
            >
              <AiOutlineSignature className="size-4" />
            </Button>

            <div className="mx-1 h-4 w-px bg-border" />

            <div className="relative">
              <Button
                variant="ghost"
                size="sm"
                className="size-8 p-0"
                onClick={handleFileButtonClick}
              >
                <Paperclip className="size-4" />
              </Button>
              <input
                id="file-upload"
                type="file"
                multiple
                className="hidden"
                onChange={handleFileUpload}
              />
            </div>
          </div>

          <Button
            size="sm"
            onClick={handleSend}
            disabled={!isSendEnabled()}
          >
            <Send className="mr-2 size-4" />
            Send
          </Button>
        </div>
      </div>

      <Dialog open={showTemplateDialog} onOpenChange={setShowTemplateDialog}>
        <DialogContent className="max-w-md">
          <DialogHeader>
            <DialogTitle className="text-base">Insert Template</DialogTitle>
          </DialogHeader>
          <div className="mb-2">
            <Input
              placeholder="Search templates..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full"
            />
          </div>
          <ScrollArea className="max-h-[250px]">
            <div className="space-y-0.5">
              {sortedAndFilteredTemplates?.map((template) => (
                <div
                  key={template.name}
                  className={`cursor-pointer rounded-md px-3 py-2 hover:bg-ftld-primary-50 ${isRenderingTemplate && selectedTemplate === template.name ? 'opacity-50' : ''
                    }`}
                  onClick={() => handleTemplateSelect(template)}
                >
                  <h3 className="text-sm font-medium">{template.name}</h3>
                  <p className="text-xs text-muted-foreground">{template.short_description}</p>
                  {isRenderingTemplate && selectedTemplate === template.name && (
                    <p className="mt-0.5 text-xs text-muted-foreground">Loading...</p>
                  )}
                </div>
              ))}
            </div>
          </ScrollArea>
        </DialogContent>
      </Dialog>
    </div>
  );
}
