import { format } from 'date-fns';
import { Badge } from '@/components/ui/badge';
import { Card } from '@/components/ui/card';
import type { Domain, MonitorData, GoDaddyTransaction, GoDaddyModification, RDDSData } from '@/types/domain';

export function StatusBadge({ status }: { status: string }) {
  const styles = (() => {
    switch (status.toLowerCase()) {
      case 'live': {
        return 'bg-green-100 text-green-800';
      }
      case 'redirect_out_of_zone': {
        return 'bg-yellow-100 text-yellow-800';
      }
      default: {
        return 'bg-gray-100 text-gray-800';
      }
    }
  })();

  return (
    <Badge className={styles}>
      {status}
    </Badge>
  );
}

export function MonitoringHistory({ history }: { history: MonitorData[] }) {
  return (
    <div className="space-y-4">
      <h3 className="font-medium">Monitoring History</h3>
      <div className="space-y-4">
        {[...history].reverse().map((item, index) => (
          <Card key={index} className="p-4">
            <div className="mb-4 flex items-center justify-between">
              <StatusBadge status={item.status} />
              <time className="text-sm text-muted-foreground">
                {format(new Date(item.timestamp), 'MMM d, yyyy h:mm a')}
              </time>
            </div>
            <dl className="grid gap-2 text-sm">
              <div className="grid grid-cols-2 gap-4">
                {item.fdic_number && (
                  <div>
                    <dt className="font-medium text-muted-foreground">FDIC Number</dt>
                    <dd className="mt-1">{item.fdic_number}</dd>
                  </div>
                )}
                {item.fdic_asset_size && (
                  <div>
                    <dt className="font-medium text-muted-foreground">Asset Size</dt>
                    <dd className="mt-1">
                      {item.fdic_asset_size.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        maximumFractionDigits: 0,
                      })}
                    </dd>
                  </div>
                )}
              </div>
              {item.cybersecurity_badge && (
                <div>
                  <dt className="font-medium text-muted-foreground">Cybersecurity Badge</dt>
                  <dd className="mt-1">{item.cybersecurity_badge}</dd>
                </div>
              )}
            </dl>
          </Card>
        ))}
      </div>
    </div>
  );
}

export function TransactionHistory({ transactions }: { transactions: GoDaddyTransaction[] }) {
  return (
    <div className="space-y-4">
      <h3 className="font-medium">Transaction History</h3>
      <div className="space-y-4">
        {[...transactions].reverse().map((transaction, index) => (
          <Card key={index} className="p-4">
            <div className="mb-4 flex items-center justify-between">
              <Badge variant="outline">
                {transaction.operation}
              </Badge>
              <time className="text-sm text-muted-foreground">
                {transaction.transactionDate ? format(transaction.transactionDate, 'MMM d, yyyy h:mm a') : 'N/A'}
              </time>
            </div>
            <dl className="grid gap-2 text-sm">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <dt className="font-medium text-muted-foreground">Cost</dt>
                  <dd className="mt-1">
                    {transaction.cost
                      ? transaction.cost.toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          minimumFractionDigits: 2,
                        })
                      : 'N/A'}
                  </dd>
                </div>
                <div>
                  <dt className="font-medium text-muted-foreground">Period</dt>
                  <dd className="mt-1">
                    {transaction.period}
                    {' '}
                    year(s)
                  </dd>
                </div>
              </div>
              <div>
                <dt className="font-medium text-muted-foreground">Registrar</dt>
                <dd className="mt-1">{transaction.registrar}</dd>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <dt className="font-medium text-muted-foreground">Start Date</dt>
                  <dd className="mt-1">{transaction.startDate ? format(transaction.startDate, 'MMM d, yyyy') : 'N/A'}</dd>
                </div>
                <div>
                  <dt className="font-medium text-muted-foreground">End Date</dt>
                  <dd className="mt-1">{transaction.endDate ? format(transaction.endDate, 'MMM d, yyyy') : 'N/A'}</dd>
                </div>
              </div>
            </dl>
          </Card>
        ))}
      </div>
    </div>
  );
}

export function ContactHistory({ modifications }: { modifications: GoDaddyModification[] }) {
  return (
    <div className="space-y-4">
      <h3 className="font-medium">Contact Modifications</h3>
      <div className="space-y-4">
        {[...modifications].reverse().map((mod, index) => (
          <Card key={index} className="p-4">
            <div className="mb-4 flex justify-between">
              <time className="text-sm text-muted-foreground">
                {format(new Date(mod.updatedOn), 'MMM d, yyyy h:mm a')}
              </time>
            </div>
            <dl className="grid gap-4 text-sm">
              <div>
                <dt className="font-medium text-muted-foreground">Registrant</dt>
                <dd className="mt-1">
                  <div>{mod.registrantIntName}</div>
                  <div>{mod.registrantIntOrganization}</div>
                  <div>{mod.registrantIntAddressStreet1}</div>
                  {mod.registrantIntAddressStreet2 && (
                    <div>{mod.registrantIntAddressStreet2}</div>
                  )}
                  {mod.registrantIntAddressStreet3 && (
                    <div>{mod.registrantIntAddressStreet3}</div>
                  )}
                  <div>
                    {mod.registrantIntCity}
                    ,
                    {mod.registrantIntState}
                    {' '}
                    {mod.registrantIntPostCode}
                  </div>
                  <div>{mod.registrantIntCountryCode}</div>
                </dd>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <dt className="font-medium text-muted-foreground">Phone</dt>
                  <dd className="mt-1">
                    {mod.registrantPhone}
                    {mod.registrantPhoneExt && ` ext. ${mod.registrantPhoneExt}`}
                  </dd>
                </div>
                <div>
                  <dt className="font-medium text-muted-foreground">Email</dt>
                  <dd className="mt-1">{mod.registrantEmail}</dd>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <dt className="font-medium text-muted-foreground">Domain Created</dt>
                  <dd className="mt-1">{format(new Date(mod.domainCreatedOn), 'MMM d, yyyy')}</dd>
                </div>
                <div>
                  <dt className="font-medium text-muted-foreground">Domain Expires</dt>
                  <dd className="mt-1">{format(new Date(mod.domainExpireOn), 'MMM d, yyyy')}</dd>
                </div>
              </div>
            </dl>
          </Card>
        ))}
      </div>
    </div>
  );
}

export function RDDSHistory({ history }: { history: RDDSData[] }) {
  return (
    <div className="space-y-4">
      <h3 className="font-medium">RDDS History</h3>
      <div className="space-y-4">
        {[...history].reverse().map((item, index) => (
          <Card key={index} className="p-4">
            <div className="mb-4 flex justify-between">
              <time className="text-sm text-muted-foreground">
                {format(new Date(item.timestamp), 'MMM d, yyyy h:mm a')}
              </time>
            </div>
            <dl className="grid gap-4 text-sm">
              <div>
                <dt className="font-medium text-muted-foreground">Registry Status</dt>
                <dd className="mt-1">{item.data['Registry Status']}</dd>
              </div>
              <div>
                <dt className="font-medium text-muted-foreground">EPP Statuses</dt>
                <dd className="mt-1">{item.data['EPP Statuses'].join(', ')}</dd>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <dt className="font-medium text-muted-foreground">Creation Date</dt>
                  <dd className="mt-1">{format(new Date(item.data['Creation Date']), 'MMM d, yyyy')}</dd>
                </div>
                <div>
                  <dt className="font-medium text-muted-foreground">Expiry Date</dt>
                  <dd className="mt-1">{format(new Date(item.data['Expiry Date']), 'MMM d, yyyy')}</dd>
                </div>
              </div>
              <div>
                <dt className="font-medium text-muted-foreground">Registrant</dt>
                <dd className="mt-1">
                  <div>{item.data['Registrant Contact'].Name}</div>
                  <div>{item.data['Registrant Contact'].Organisation}</div>
                  {item.data['Registrant Contact'].Address.Street.filter(Boolean).map((line, i) => (
                    <div key={i}>{line}</div>
                  ))}
                  <div>
                    {item.data['Registrant Contact'].Address.City}
                    ,
                    {item.data['Registrant Contact'].Address['State/Province']}
                    {' '}
                    {item.data['Registrant Contact'].Address['Postal Code']}
                  </div>
                  <div>{item.data['Registrant Contact'].Address.Country}</div>
                  <div className="mt-1">
                    Phone:
                    {' '}
                    {item.data['Registrant Contact'].Phone.Number}
                  </div>
                  <div>
                    Email:
                    {' '}
                    {item.data['Registrant Contact'].Email}
                  </div>
                </dd>
              </div>
              <div>
                <dt className="font-medium text-muted-foreground">Registrar Information</dt>
                <dd className="mt-1">
                  <div>
                    Sponsoring:
                    {item.data['Sponsoring Registrar']}
                    {' '}
                    (IANA ID:
                    {item.data['Sponsoring Registrar IANA ID']}
                    )
                  </div>
                  <div>
                    Creating:
                    {item.data['Creating Registrar']}
                    {' '}
                    (IANA ID:
                    {item.data['Creating Registrar IANA ID']}
                    )
                  </div>
                  <div>
                    Last Update By:
                    {item.data['Last Updating Registrar']}
                    {' '}
                    (IANA ID:
                    {item.data['Last Updating Registrar IANA ID']}
                    )
                  </div>
                </dd>
              </div>
            </dl>
          </Card>
        ))}
      </div>
    </div>
  );
}
