import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import FormInput from '@/components/form-components/form-input';
import { Button } from '@/components/ui/button';
import { Sheet, SheetContent, SheetHeader, SheetTitle } from '@/components/ui/sheet';
import { Textarea } from '@/components/ui/textarea';
import type { RegulatoryLink, CreateRegulatoryLinkInput } from '@/types/regulatory-link';

type DatabaseSheetProps = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  initialData?: RegulatoryLink;
  onSubmit: (data: CreateRegulatoryLinkInput) => Promise<void>;
};

function DatabaseSheet({ open, onOpenChange, initialData, onSubmit }: DatabaseSheetProps) {
  const form = useForm<CreateRegulatoryLinkInput>({
    defaultValues: {
      purpose: '',
      notes: '',
      url: '',
      country: '',
    },
  });

  // Update form values when initialData changes
  useEffect(() => {
    if (initialData) {
      form.reset({
        purpose: initialData.purpose,
        notes: initialData.notes,
        url: initialData.url,
        country: initialData.country,
      });
    } else {
      form.reset({
        purpose: '',
        notes: '',
        url: '',
        country: '',
      });
    }
  }, [initialData, form.reset]);

  const handleFormSubmit = async (data: CreateRegulatoryLinkInput) => {
    await onSubmit(data);
    form.reset();
    onOpenChange(false);
  };

  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent
        side="right"
        className="w-full max-w-full sm:max-w-lg"
        onPointerDownOutside={(e) => e.preventDefault()}
      >
        <SheetHeader>
          <SheetTitle>{initialData ? 'Edit Database' : 'New Database'}</SheetTitle>
        </SheetHeader>
        <form onSubmit={form.handleSubmit(handleFormSubmit)} className="h-[calc(100vh-8rem)] space-y-6 overflow-y-auto px-1 pt-8">
          <FormInput
            control={form.control}
            name="purpose"
            label="Purpose"
            required
            rules={{ required: 'Purpose is required' }}
            placeholder="e.g., Business Reg, Insurance, Bank"
          />

          <FormInput
            control={form.control}
            name="url"
            label="URL"
            required
            rules={{
              required: 'URL is required',
              pattern: {
                value: /^https?:\/\/.+/,
                message: 'Must be a valid URL starting with http:// or https://',
              },
            }}
            placeholder="https://example.gov/registration"
          />

          <div className="space-y-2">
            <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
              Notes (Optional)
            </label>
            <Textarea
              {...form.register('notes')}
              className="min-h-[100px] resize-y"
              placeholder="Additional information about this database"
            />
            {form.formState.errors.notes && (
              <p className="text-sm text-destructive">{form.formState.errors.notes.message}</p>
            )}
          </div>

          <FormInput
            control={form.control}
            name="country"
            label="Country Code"
            required
            rules={{
              required: 'Country code is required',
              maxLength: { value: 2, message: 'Must be a 2-letter country code' },
              minLength: { value: 2, message: 'Must be a 2-letter country code' },
              pattern: {
                value: /^[A-Z]{2}$/,
                message: 'Must be a 2-letter country code (e.g., US, DE, SG)',
              },
            }}
            placeholder="e.g., US, DE, SG"
          />

          <div className="pt-4">
            <Button type="submit" className="w-full" disabled={form.formState.isSubmitting}>
              {initialData ? 'Update Database' : 'Create Database'}
            </Button>
          </div>
        </form>
      </SheetContent>
    </Sheet>
  );
}

export default DatabaseSheet;
