import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import type { RegulatoryLink, CreateRegulatoryLinkInput, UpdateRegulatoryLinkInput } from '@/types/regulatory-link';
import { useAxios } from './use-axios';

const API_BASE_URL = '/regulatory-links';

export const REGULATORY_LINKS_QUERY_KEY = ['regulatory-links'];

interface ApiError {
  error?: {
    message?: string;
    status?: number;
  };
  details?: Array<{ message: string }>;
}

function getErrorMessage(error: unknown): string {
  if (isAxiosError<ApiError>(error)) {
    // Handle validation errors
    if (error.response?.data?.details) {
      return 'Please check your input and try again';
    }

    // Handle specific error codes
    if (error.response?.status === 500) {
      return 'An internal server error occurred. Please try again later';
    }

    if (error.response?.status === 401 || error.response?.status === 403) {
      return 'You do not have permission to perform this action';
    }

    if (error.response?.status === 404) {
      return 'The requested resource was not found';
    }

    // Handle network errors
    if (error.message === 'Network Error') {
      return 'Unable to connect to the server. Please check your internet connection';
    }
  }

  return 'An unexpected error occurred. Please try again';
}

// List all regulatory links
export function useRegulatoryLinks() {
  const axios = useAxios();

  return useQuery({
    queryKey: REGULATORY_LINKS_QUERY_KEY,
    queryFn: async () => {
      try {
        const { data } = await axios.get<RegulatoryLink[]>(API_BASE_URL);
        return data;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
  });
}

// Get a single regulatory link
export function useRegulatoryLink(id: number) {
  const axios = useAxios();

  return useQuery({
    queryKey: [...REGULATORY_LINKS_QUERY_KEY, id],
    queryFn: async () => {
      try {
        const { data } = await axios.get<RegulatoryLink>(`${API_BASE_URL}/${id}`);
        return data;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    enabled: !!id,
  });
}

// Create a regulatory link
export function useCreateRegulatoryLink() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (newLink: CreateRegulatoryLinkInput) => {
      try {
        const { data } = await axios.post<RegulatoryLink>(API_BASE_URL, newLink);
        return data;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: REGULATORY_LINKS_QUERY_KEY });
    },
  });
}

// Update a regulatory link
export function useUpdateRegulatoryLink() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, ...updateData }: { id: number } & UpdateRegulatoryLinkInput) => {
      try {
        const { data } = await axios.put<RegulatoryLink>(`${API_BASE_URL}/${id}`, updateData);
        return data;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: REGULATORY_LINKS_QUERY_KEY });
    },
  });
}

// Delete a regulatory link
export function useDeleteRegulatoryLink() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id: number) => {
      try {
        const { data } = await axios.delete(`${API_BASE_URL}/${id}`);
        return data;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: REGULATORY_LINKS_QUERY_KEY });
    },
  });
}

// Delete multiple regulatory links
export function useDeleteRegulatoryLinks() {
  const queryClient = useQueryClient();
  const deleteMutation = useDeleteRegulatoryLink();

  return useMutation({
    mutationFn: async (ids: number[]) => {
      try {
        await Promise.all(ids.map(async (id) => {
          await deleteMutation.mutateAsync(id);
        }));
        return ids;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: REGULATORY_LINKS_QUERY_KEY });
    },
  });
}
