import type { ColDef } from '@ag-grid-community/core';
import { format } from 'date-fns';
import type { Partner } from '@/types/partner';

export const partnerColumnDefs: ColDef<Partner>[] = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    minWidth: 200,
    sortable: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains', 'equals', 'startsWith', 'endsWith'],
      suppressAndOrCondition: true,
    },
  },
  {
    field: 'primary_contact',
    headerName: 'Primary Contact',
    flex: 1,
    minWidth: 200,
    sortable: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains', 'equals', 'startsWith', 'endsWith'],
      suppressAndOrCondition: true,
    },
  },
  {
    field: 'email',
    headerName: 'Email',
    flex: 1,
    minWidth: 200,
    sortable: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains', 'equals', 'startsWith', 'endsWith'],
      suppressAndOrCondition: true,
    },
  },
  {
    field: 'created_at',
    headerName: 'Created',
    flex: 1,
    minWidth: 150,
    sortable: true,
    filter: 'agDateColumnFilter',
    valueFormatter: (params) => {
      return params.value ? format(new Date(params.value), 'MMM d, yyyy') : '';
    },
  },
  {
    field: 'updated_at',
    headerName: 'Updated',
    flex: 1,
    minWidth: 150,
    sortable: true,
    filter: 'agDateColumnFilter',
    valueFormatter: (params) => {
      return params.value ? format(new Date(params.value), 'MMM d, yyyy') : '';
    },
  },
];
