import { ColDef } from '@ag-grid-community/core';
import { format } from 'date-fns';

// Column definitions
export const nylasColumnDefs: ColDef[] = [
  {
    field: 'email',
    headerName: 'Email Address',
    flex: 1,
  },
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
  },
  {
    field: 'provider',
    headerName: 'Provider',
    flex: 1,
  },
  {
    field: 'grantStatus',
    headerName: 'Status',
    flex: 1,
  },
  {
    field: 'createdAt',
    headerName: 'Created',
    flex: 1,
    valueFormatter: (params) => {
      return format(params.value * 1000, 'PPpp');
    },
  },
  {
    field: 'updatedAt',
    headerName: 'Updated',
    flex: 1,
    valueFormatter: (params) => {
      return format(params.value * 1000, 'PPpp');
    },
  },
];

export const webhookColumnDefs: ColDef[] = [
  {
    field: 'description',
    headerName: 'Description',
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 120,
  },
  {
    field: 'triggerTypes',
    headerName: 'Trigger Types',
    width: 200,
    valueFormatter: (params) => {
      // Return empty string if no trigger types are present
      if (!params.value) return '';

      // Format each trigger type string for better readability:
      // 1. Replace dots with spaces (e.g., 'message.created' -> 'message created')
      // 2. Capitalize each word (e.g., 'message created' -> 'Message Created')
      // 3. Join multiple trigger types with commas
      // Example: ['message.created', 'message.updated'] -> 'Message Created, Message Updated'
      return params.value
        .map((trigger: string) =>
          trigger
            .replace(/\./g, ' ') // Convert dots to spaces
            .replace(/\b\w/g, (c) => c.toUpperCase()) // Capitalize first letter of each word
        )
        .join(', '); // Join multiple triggers with commas
    },
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    width: 180,
    valueFormatter: (params) => {
      return format(params.value * 1000, 'PPpp');
    }
  },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    width: 180,
    valueFormatter: (params) => {
      return format(params.value * 1000, 'PPpp');
    }
  },
  {
    field: 'notificationEmailAddresses',
    headerName: 'Notification Email',
    width: 200,
  },
];