import type { ICellRendererParams, ColDef } from '@ag-grid-community/core';
import { Badge } from '@/components/ui/badge';
import type { Contact } from '@/hooks/use-contacts';
import { formatDateEDT } from '@/lib/date';

function DomainTypeCell({ value }: ICellRendererParams<Contact, string>) {
  if (!value)
    return null;
  return <Badge variant="outline">{value}</Badge>;
}

export const contactColumnDefs: ColDef<Contact>[] = [
  {
    field: 'legal_name',
    headerName: 'Legal Name',
    flex: 1,
    minWidth: 200,
    sortable: true,
    filter: true,
  },
  {
    field: 'organization_legal_name',
    headerName: 'Organization',
    flex: 1,
    minWidth: 200,
    sortable: true,
    filter: true,
  },
  {
    headerName: 'Domain',
    flex: 1,
    minWidth: 200,
    sortable: true,
    filter: true,
    valueGetter: (params) => {
      return params.data?.domain_contact_junctions?.[0]?.domain ?? null;
    },
  },
  {
    headerName: 'Contact Type',
    flex: 1,
    minWidth: 150,
    sortable: true,
    filter: true,
    valueGetter: (params) => {
      return params.data?.domain_contact_junctions?.[0]?.type ?? null;
    },
    cellRenderer: DomainTypeCell,
  },
  {
    field: 'email',
    headerName: 'Email',
    flex: 1,
    minWidth: 200,
    sortable: true,
    filter: true,
  },
  {
    field: 'phone_number',
    headerName: 'Company Phone',
    flex: 1,
    minWidth: 150,
    sortable: true,
    filter: true,
  },
  {
    field: 'alt_phone',
    headerName: 'Direct Phone',
    flex: 1,
    minWidth: 150,
    sortable: true,
    filter: true,
  },
  {
    field: 'city',
    headerName: 'City',
    flex: 1,
    minWidth: 150,
    sortable: true,
    filter: true,
  },
  {
    field: 'state_province',
    headerName: 'State/Province',
    flex: 1,
    minWidth: 150,
    sortable: true,
    filter: true,
  },
  {
    field: 'country',
    headerName: 'Country',
    flex: 1,
    minWidth: 150,
    sortable: true,
    filter: true,
  },
  {
    field: 'updated_at',
    headerName: 'Last Updated',
    flex: 1,
    minWidth: 150,
    sortable: true,
    filter: true,
    cellRenderer: ({ value }: ICellRendererParams<Contact, string>) => {
      if (!value)
        return null;
      return <div className="text-muted-foreground">{formatDateEDT(new Date(value))}</div>;
    },
  },
];
