import * as React from 'react';
import { MessageSquare } from 'lucide-react';
import { CardEmpty } from '@/components/card-empty';
import { ScrollArea } from '@/components/ui/scroll-area';
import type { CommunicationDisplay } from '../types/communications';
import { DateSeparator } from './date-separator';
import { InternalMessage } from './internal-message';
import { MessageListSkeleton } from './message-skeleton';
import { ThreadMessage } from './thread-message';

function showDateDivider(messageIndex: number, currentTimestamp: string | Date, previousTimestamp?: string | Date): boolean {
  const isFirstMessage = messageIndex === 0;
  if (isFirstMessage)
    return true;

  const currentDate = new Date(currentTimestamp).toDateString();
  const previousDate = new Date(previousTimestamp!).toDateString();
  return currentDate !== previousDate;
}

type MessageListProps = {
  communications: CommunicationDisplay[];
  onMessageSelect: (messageId: string) => void;
  selectedMessageId?: string;
  messageInputHeight: number;
  isLoading?: boolean;
};

export function MessageList({
  communications,
  onMessageSelect,
  selectedMessageId,
  messageInputHeight,
  isLoading,
}: MessageListProps) {
  return (
    <ScrollArea
      className="flex-1 transition-[height] duration-200 ease-out"
      style={{
        height: `calc(100vh - ${messageInputHeight}px - 8rem)`,
      }}
    >
      {isLoading
        ? (
          <MessageListSkeleton />
        )
        : (communications.length === 0
          ? (
            <div className="my-8 flex h-full items-center justify-center">
              <CardEmpty
                title="No messages yet"
                description="When messages arrive, they'll appear here."
                icon={MessageSquare}
              />
            </div>
          )
          : (
            <div className="space-y-4 p-4">
              {communications.map((communication, index) => {
                const previousMessage = index > 0 ? communications[index - 1] : undefined;

                return (
                  <div key={communication.id}>
                    {showDateDivider(index, communication.timestamp, previousMessage?.timestamp) && (
                      <DateSeparator timestamp={communication.timestamp} />
                    )}
                    {communication.isEmail && (
                      <ThreadMessage
                        message={communication}
                        isSelected={selectedMessageId === communication.id}
                        onClick={onMessageSelect}
                      />
                    )}
                    {!communication.isEmail && (
                      <InternalMessage
                        message={communication}
                        isSelected={selectedMessageId === communication.id}
                        onClick={onMessageSelect}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          ))}
    </ScrollArea>
  );
}
