import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { useAxios } from './use-axios';

const API_BASE_URL = '/scheduler';

interface ApiError {
  error?: {
    message?: string;
    status?: number;
  };
  details?: Array<{ message: string }>;
}

export type EventStatus = 'scheduled' | 'error' | 'delivered' | 'deleted';

export interface RetryConfig {
  num_retries?: number; // Default: 0
  retry_interval_seconds?: number; // Default: 10
  timeout_seconds?: number; // Default: 60
  tolerance_seconds?: number; // Default: 21600 (6 hours)
}

export interface SchedulerEvent {
  id: string;
  webhook_conf: string;
  scheduled_time: string;
  payload: {
    domainLists: string[][];
  };
  status: EventStatus;
  comment?: string;
  header_conf: Array<{
    name: string;
    value: string;
  }>;
  retry_conf: Required<RetryConfig>;
}

export interface EventInvocation {
  id: string;
  event_id: string;
  status: string;
  created_at: string;
  scheduled_time: string;
  response: {
    status: number;
    body: unknown;
  };
}

export interface ListEventsParams {
  limit?: number; // Default: 10
  offset?: number; // Default: 0
  status?: EventStatus[];
}

export interface ListEventsResponse {
  events: SchedulerEvent[];
  rows_count: number;
}

export interface ListInvocationsResponse {
  invocations: EventInvocation[];
  rows_count: number;
}

export interface CreateEventRequest {
  schedule_at: string; // ISO8601 datetime
  payload: {
    domainLists: string[][];
  };
  comment?: string;
  retry_conf?: RetryConfig;
}

export interface CreateEventResponse {
  message: string;
  event_id: string;
}

function getErrorMessage(error: unknown): string {
  if (isAxiosError<ApiError>(error)) {
    // Handle validation errors
    if (error.response?.data?.details) {
      return 'Please check your input and try again';
    }

    // Handle specific error codes
    if (error.response?.status === 500) {
      return 'An internal server error occurred. Please try again later';
    }

    if (error.response?.status === 401 || error.response?.status === 403) {
      return 'You do not have permission to perform this action';
    }

    if (error.response?.status === 404) {
      return 'The requested resource was not found';
    }

    // Handle network errors
    if (error.message === 'Network Error') {
      return 'Unable to connect to the server. Please check your internet connection';
    }
  }

  return 'An unexpected error occurred. Please try again';
}

/** React Query cache keys for scheduler operations */
const SCHEDULER_QUERY_KEYS = {
  /** Base key for all scheduler queries */
  all: ['scheduler'] as const,
  /** Key builder for listing events with filters */
  list: (params: ListEventsParams) => [...SCHEDULER_QUERY_KEYS.all, 'list', params] as const,
  /** Key builder for event invocations */
  invocations: (eventId: string) => [...SCHEDULER_QUERY_KEYS.all, 'invocations', eventId] as const,
} as const;

/**
 * Hook for listing scheduler events with pagination and filtering
 *
 * @param params - Query parameters for filtering and pagination
 * @returns Query result containing events and total count
 *
 * @example
 * ```tsx
 * const { data, isLoading } = useSchedulerEvents({
 *   limit: 10,
 *   offset: 0,
 *   status: ["scheduled", "error"]
 * });
 * ```
 */
export function useSchedulerEvents(params: ListEventsParams = {}) {
  const axios = useAxios();

  return useQuery({
    queryKey: SCHEDULER_QUERY_KEYS.list(params),
    queryFn: async () => {
      try {
        const { data } = await axios.get<ListEventsResponse>(API_BASE_URL, { params });
        return data;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
  });
}

/**
 * Hook for getting invocation history for a specific event
 *
 * @param eventId - ID of the event to get invocations for
 * @returns Query result containing invocations and total count
 *
 * @example
 * ```tsx
 * const { data, isLoading } = useEventInvocations("event-123");
 * ```
 */
export function useEventInvocations(eventId: string) {
  const axios = useAxios();

  return useQuery({
    queryKey: SCHEDULER_QUERY_KEYS.invocations(eventId),
    queryFn: async () => {
      try {
        const { data } = await axios.get<ListInvocationsResponse>(`${API_BASE_URL}/${eventId}/invocations`);
        return data;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    enabled: !!eventId,
  });
}

/**
 * Hook for creating new scheduler events
 *
 * @returns Mutation for creating events with the following parameters:
 * @param schedule_at - ISO8601 datetime when to trigger the event
 * @param payload - Event payload containing domain lists
 * @param comment - Optional description
 * @param retry_conf - Optional retry configuration
 *
 * @example
 * ```tsx
 * const { createEvent, isCreating } = useCreateSchedulerEvent();
 *
 * const handleCreate = async () => {
 *   try {
 *     const result = await createEvent({
 *       schedule_at: "2024-01-01T00:00:00Z",
 *       payload: {
 *         domainLists: [["example1.bank", "example2.bank"]]
 *       },
 *       comment: "Annual verification batch 1"
 *     });
 *     console.log("Created event:", result.event_id);
 *   } catch (error) {
 *     console.error("Failed to create event:", error);
 *   }
 * };
 * ```
 */
export function useCreateSchedulerEvent() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  const mutation = useMutation<CreateEventResponse, Error, CreateEventRequest>({
    mutationFn: async (request) => {
      try {
        const { data } = await axios.post<CreateEventResponse>(API_BASE_URL, request);
        return data;
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    onSuccess: async () => {
      // Invalidate the events list query to refetch with the new event
      await queryClient.invalidateQueries({
        queryKey: SCHEDULER_QUERY_KEYS.all,
      });
    },
  });

  return {
    createEvent: mutation.mutate,
    createEventAsync: mutation.mutateAsync,
    isCreating: mutation.isPending,
    error: mutation.error,
  };
}

/**
 * Hook for deleting scheduler events
 *
 * @returns Mutation for deleting events
 *
 * @example
 * ```tsx
 * const { deleteEvent, isDeleting } = useDeleteSchedulerEvent();
 *
 * const handleDelete = async (eventId: string) => {
 *   try {
 *     await deleteEvent(eventId);
 *     console.log("Event deleted successfully");
 *   } catch (error) {
 *     console.error("Failed to delete event:", error);
 *   }
 * };
 * ```
 */
export function useDeleteSchedulerEvent() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  const mutation = useMutation<void, Error, string>({
    mutationFn: async (eventId) => {
      try {
        await axios.delete(`${API_BASE_URL}/${eventId}`);
      } catch (error) {
        throw new Error(getErrorMessage(error));
      }
    },
    onSuccess: async () => {
      // Invalidate the events list query to refetch without the deleted event
      await queryClient.invalidateQueries({
        queryKey: SCHEDULER_QUERY_KEYS.all,
      });
    },
  });

  return {
    deleteEvent: mutation.mutate,
    deleteEventAsync: mutation.mutateAsync,
    isDeleting: mutation.isPending,
    error: mutation.error,
  };
}
