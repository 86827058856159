export const registrars = [
  { value: 'Undecided', label: 'Undecided' },
  { value: '007Names', label: '007Names, Inc.' },
  { value: '101domain', label: '101domain GRS Limited' },
  { value: 'Ascio', label: 'Ascio Technologies, Inc. Danmark - Filial af Ascio technologies, Inc. USA' },
  { value: 'Beijing', label: 'Beijing Guoxu Network Technology Co., Ltd.' },
  { value: 'BrandFocus', label: 'Brand Focus' },
  { value: 'COREhub', label: 'COREhub' },
  { value: 'CSC', label: 'CSC Corporate Domains, Inc.' },
  { value: 'ConnectReseller', label: 'ConnectReseller (OwnRegistrar, Inc.)' },
  { value: 'EnCirca', label: 'EnCirca' },
  { value: 'Entorno', label: 'Entorno Digital, S.A.' },
  { value: 'EuroDNS', label: 'EuroDNS S.A' },
  { value: 'Focus', label: 'Focus IP, Inc. dba AppDetex' },
  { value: 'Gandi', label: 'Gandi SAS' },
  { value: 'GlobalVillage', label: 'Global Village GmbH' },
  { value: 'HostingConcepts', label: 'Hosting Concepts B.V. d/b/a Registrar.eu (Openprovider)' },
  { value: 'INWX', label: 'INWX' },
  { value: 'Instra', label: 'Instra Corporation Pty, Ltd.' },
  { value: 'KeySystems', label: 'Key-Systems, LLC' },
  { value: 'LEXSYNERGY', label: 'LEXSYNERGY LIMITED' },
  { value: 'MarkMonitor', label: 'MarkMonitor Inc.' },
  { value: 'NAMESHIELD', label: 'NAMESHIELD SAS' },
  { value: 'NameWeb', label: 'NameWeb BVBA' },
  { value: 'NetChinese', label: 'Net-Chinese Co., Ltd.' },
  { value: 'NomIQ', label: 'Nom IQ Ltd (DBA Com Laude)' },
  { value: 'SafeBrands', label: 'SafeBrands SAS' },
  { value: 'Safenames', label: 'Safenames Ltd' },
  { value: 'Secura', label: 'Secura GmbH' },
  { value: 'UnitedDomains', label: 'United-Domains' },
  { value: 'Variomedia', label: 'Variomedia' },
  { value: 'WebCommerce', label: 'Web Commerce Communications Limited dba WebNic.cc (WebNIC)' },
  { value: 'WhoisCorp', label: 'Whois Corp' },
];
