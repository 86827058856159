import * as React from 'react';
import { Separator } from '@/components/ui/separator';
import type { CommunicationDisplay } from '../types/communications';
import type { Message as NylasMessage } from 'nylas';
import { MessageAttachments } from './message-attachments';
import { MessageContent } from './message-content';
import { MessageHeader } from './message-header';

type MessageContainerProps = {
  communication: CommunicationDisplay;
  content: Partial<NylasMessage> | null;
  timestamp: string | Date;
};

export function MessageContainer({
  communication,
  content,
  timestamp,
}: MessageContainerProps) {
  return (
    <div>
      <MessageHeader
        sender={communication.sender}
        timestamp={timestamp}
        isExternal={communication.isExternal}
        isEmail={communication.isEmail}
        emailTo={communication.emailTo}
        emailSubject={communication.emailSubject}
      />
      <Separator className="my-4" />
      <MessageContent content={content} />
      {communication.attachments && (
        <MessageAttachments attachments={communication.attachments} />
      )}
    </div>
  );
}
