import { useState, useMemo } from 'react';
import type { ColDef, ValueGetterParams } from '@ag-grid-community/core';
import { format } from 'date-fns';
import AGDataGrid from '@/components/ag-data-grid';
import { Card } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { useNylasGrants } from '@/hooks/use-nylas-grants';
import { useNylasThreads } from '@/hooks/use-nylas-threads';
import { getThreadTimestamp } from '@/lib/threads';
import type { NylasThread } from '@/types/thread';

function MessagesPage() {
  const [quickFilterText, setQuickFilterText] = useState('');
  const { data: grants } = useNylasGrants();
  const grantId = grants?.[0]?.id;

  const {
    threads,
    isLoading,
    nextPage,
    prevPage,
    hasNextPage,
    hasPrevPage,
    currentPage,
    totalPages,
    isFetchingNextPage,
  } = useNylasThreads({
    grantId: grantId || '',
    limit: 50,
  });

  const columnDefs = useMemo<ColDef<NylasThread>[]>(() => [
    {
      field: 'starred',
      headerName: '',
      width: 50,
      cellRenderer: (params: { value: boolean }) =>
        params.value ? '⭐' : '',
    },
    {
      field: 'subject',
      headerName: 'Subject',
      flex: 2,
      cellRenderer: (params: { data: NylasThread }) => {
        const thread = params.data;
        return (
          <div className="flex flex-col">
            <span className={thread.unread ? 'font-bold' : ''}>
              {thread.subject}
            </span>
            <span className="truncate text-sm text-gray-500">
              {thread.snippet}
            </span>
          </div>
        );
      },
    },
    {
      field: 'participants',
      headerName: 'From',
      flex: 1,
      valueGetter: (params: ValueGetterParams<NylasThread>) => {
        if (!params.data)
          return '';
        const sender = params.data.participants[0];
        return sender ? `${sender.name || sender.email}` : '';
      },
    },
    {
      field: 'hasAttachments',
      headerName: '',
      width: 50,
      cellRenderer: (params: { value: boolean }) =>
        params.value ? '📎' : '',
    },
    {
      field: 'latestMessageReceivedDate',
      headerName: 'Date',
      width: 120,
      valueGetter: (params: ValueGetterParams<NylasThread>) => {
        if (!params.data)
          return '';
        return getThreadTimestamp(params.data);
      },
      valueFormatter: (params: { value: string }) =>
        format(new Date(params.value), 'MMM d, yyyy'),
    },
  ], []);

  if (!grantId) {
    return (
      <Card className="p-4">
        <p>Please connect your email account in Settings to view messages.</p>
      </Card>
    );
  }

  return (
    <div className="space-y-4 p-4">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-bold">Messages</h1>
        <Input
          type="text"
          placeholder="Search messages..."
          className="w-64"
          value={quickFilterText}
          onChange={(e) => setQuickFilterText(e.target.value)}
        />
      </div>

      <div className="flex items-center justify-between">
        <div className="text-sm text-gray-500">
          Page
          {' '}
          {currentPage}
          {' '}
          of
          {' '}
          {totalPages}
        </div>
        <div className="space-x-2">
          <button
            type="button"
            className="rounded-md border px-3 py-1 text-sm disabled:opacity-50"
            onClick={() => prevPage()}
            disabled={!hasPrevPage || isFetchingNextPage}
          >
            Previous
          </button>
          <button
            type="button"
            className="rounded-md border px-3 py-1 text-sm disabled:opacity-50"
            onClick={() => nextPage()}
            disabled={!hasNextPage || isFetchingNextPage}
          >
            Next
          </button>
        </div>
      </div>

      <div className="ag-theme-alpine" style={{ width: '100%', height: 'calc(100vh - 250px)' }}>
        <AGDataGrid
          rowData={threads || []}
          columnDefs={columnDefs}
          defaultColDef={{
            sortable: true,
            resizable: true,
          }}
          quickFilterText={quickFilterText}
          loadingOverlayComponent={() => 'Loading messages...'}
          loading={isLoading || isFetchingNextPage}
          rowSelection={{ mode: 'singleRow' }}
          enableCellTextSelection
        />
      </div>
    </div>
  );
}

export default MessagesPage;
