import { useRef, useState } from 'react';
import type { GridApi, RowDoubleClickedEvent, ColDef } from '@ag-grid-community/core';
import { toast } from 'sonner';
import AGDataGrid from '@/components/ag-data-grid';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
  useContacts,
  useAddDomainAssociation,
  useRemoveDomainAssociation,
  useUpdateDomainAssociation,
} from '@/hooks/use-contacts';
import type { Contact } from '@/hooks/use-contacts';
import { useUpdateDomainRdds } from '@/hooks/use-domain-info';
import type { ContactFormData } from '@/pages/app/contacts/components/contacts-sheet';
import type { DomainContactJunction } from '@/types/domain';
import { ContactSheet } from './contact-sheet';

function ContactTypeCell(props: { value: string }) {
  return <Badge variant="outline">{props.value}</Badge>;
}

const contactColumnDefs: ColDef<DomainContactJunction>[] = [
  {
    field: 'contact.legal_name',
    headerName: 'Legal Name',
    flex: 1,
    sortable: true,
    filter: true,
  },
  {
    field: 'contact.organization_legal_name',
    headerName: 'Organization',
    flex: 1,
    sortable: true,
    filter: true,
  },
  {
    field: 'contact.email',
    headerName: 'Email',
    flex: 1,
    sortable: true,
    filter: true,
  },
  {
    field: 'contact.phone_number',
    headerName: 'Phone',
    flex: 1,
    sortable: true,
    filter: true,
  },
  {
    field: 'type',
    headerName: 'Contact Type',
    flex: 1,
    sortable: true,
    filter: true,
    cellRenderer: ContactTypeCell,
  },
];

interface DomainContactsProps {
  contacts: DomainContactJunction[];
  domain: string;
  onAssociationChange: () => void;
}

type PartialContact = Partial<Contact> & {
  domain_contact_junctions?: Array<{
    domain: string;
    type: 'registrant' | 'verification';
    created_at: string;
  }>;
};

export function DomainContacts({ contacts, domain, onAssociationChange }: DomainContactsProps) {
  const [selectedRows, setSelectedRows] = useState<DomainContactJunction[]>([]);
  const [editingContact, setEditingContact] = useState<PartialContact | null>(null);
  const [isSheetOpen, setIsSheetOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const gridApiRef = useRef<GridApi<DomainContactJunction> | null>(null);

  const { data: allContacts } = useContacts();
  const addDomainAssociation = useAddDomainAssociation();
  const removeDomainAssociation = useRemoveDomainAssociation();
  const updateDomainAssociation = useUpdateDomainAssociation();
  const updateRdds = useUpdateDomainRdds();

  const handleCreateSubmit = async (data: ContactFormData) => {
    try {
      const association = data.domain_associations?.[0];
      if (!association) {
        throw new Error('No domain association provided');
      }
      await addDomainAssociation.mutateAsync({
        contactId: editingContact?.id || '',
        domain: association.domain,
        type: association.type,
      });
      toast.success('Contact associated successfully');
      setIsSheetOpen(false);
      onAssociationChange();
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      }
      throw error;
    }
  };

  const handleUpdateSubmit = async (data: ContactFormData) => {
    if (!editingContact?.id)
      return;

    try {
      const association = data.domain_associations?.[0];
      if (!association) {
        throw new Error('No domain association provided');
      }
      await updateDomainAssociation.mutateAsync({
        contactId: editingContact.id,
        domain: association.domain,
        type: association.type,
      });
      toast.success('Contact updated successfully');
      setEditingContact(null);
      setIsSheetOpen(false);
      onAssociationChange();
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      }
      throw error;
    }
  };

  const handleRowDoubleClick = (event: RowDoubleClickedEvent<DomainContactJunction>) => {
    if (!event.data)
      return;

    const contact = event.data.contact;
    if (!contact)
      return;

    // Create a deep copy of the contact to avoid mutation
    const editingData = {
      ...contact,
      domain_contact_junctions: [{
        domain,
        type: event.data.type,
        created_at: event.data.created_at,
      }],
    };
    setEditingContact(editingData);
    setIsSheetOpen(true);
  };

  const handleSelectionChanged = (event: { api: { getSelectedRows: () => DomainContactJunction[] } }) => {
    setSelectedRows(event.api.getSelectedRows());
  };

  const handleSheetOpenChange = (open: boolean) => {
    setIsSheetOpen(open);
    if (!open) {
      setEditingContact(null);
      // Deselect rows when closing the sheet
      if (gridApiRef.current) {
        gridApiRef.current.deselectAll();
      }
    }
  };

  const handleGridReady = (params: { api: GridApi<DomainContactJunction> }) => {
    gridApiRef.current = params.api;
  };

  // Get available contacts that aren't already associated
  const availableContacts = allContacts?.filter(
    (contact) => !contacts.some((junction) => junction.contact.id === contact.id),
  ) ?? [];

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h3 className="text-lg font-medium">Associated Contacts</h3>
        <div className="flex items-center gap-3">
          {selectedRows.length > 0 && (
            <Button
              variant="destructive"
              onClick={() => setIsDeleteDialogOpen(true)}
            >
              Remove Selected
            </Button>
          )}
          <Button
            variant="outline"
            onClick={async () => {
              try {
                await updateRdds.mutateAsync(domain);
                toast.success('RDDS data refreshed successfully');
                onAssociationChange();
              } catch (error) {
                if (error instanceof Error) {
                  toast.error(error.message);
                }
              }
            }}
          >
            Refresh RDDS
          </Button>
          <Button onClick={() => {
            const newContact: PartialContact = {
              domain_contact_junctions: [{
                domain,
                type: 'registrant',
                created_at: new Date().toISOString(),
              }],
            };
            setEditingContact(newContact);
            setIsSheetOpen(true);
          }}
          >
            Add Contact
          </Button>
        </div>
      </div>

      <div>
        <AGDataGrid
          columnDefs={contactColumnDefs}
          rowData={contacts}
          rowSelection={{ mode: 'multiRow' }}
          onRowDoubleClicked={handleRowDoubleClick}
          onSelectionChanged={handleSelectionChanged}
          onGridReady={handleGridReady}
          overlayNoRowsTemplate="No contacts found"
          domLayout="autoHeight"
        />
      </div>

      <ContactSheet
        open={isSheetOpen}
        onOpenChange={handleSheetOpenChange}
        initialData={editingContact as Contact | undefined}
        onSubmit={editingContact?.id ? handleUpdateSubmit : handleCreateSubmit}
        availableDomains={[domain]}
      />

      <AlertDialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This will remove the selected contacts from this domain. The contacts themselves will not be deleted.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={async () => {
              try {
                await Promise.all(selectedRows.map(async (row) =>
                  removeDomainAssociation.mutateAsync({
                    contactId: row.contact.id,
                    domain,
                  }),
                ));
                toast.success('Contacts removed successfully');
                setSelectedRows([]);
                setIsDeleteDialogOpen(false);
                onAssociationChange();
              } catch (error) {
                if (error instanceof Error) {
                  toast.error(error.message);
                }
              }
            }}
            >
              Remove
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
}
