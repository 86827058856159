import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Navigate,
} from 'react-router-dom';
import AppLayout from '@/layouts/app';
import PublicLayout from '@/layouts/public';
import HomePage from '@/pages/home';
import PublicForm from '@/pages/public/form';
import ProtectedRoute from './auth/protected-route';
import ErrorBoundary from './error-boundary';
import ContactsPage from './pages/app/contacts';
import Dashboard from './pages/app/dashboard';
import DatabasesPage from './pages/app/databases';
import DealsPage from './pages/app/deals';
import DealDetailsPage from './pages/app/deals/[id]';
import DomainsPage from './pages/app/domains';
import DomainDetailsPage from './pages/app/domains/[name]';
import MessagesPage from './pages/app/messages';
import PartnersPage from './pages/app/partners';
import SchedulerPage from './pages/app/scheduler';
import SchedulePage from './pages/app/scheduler/schedule';
import SettingsPage from './pages/app/settings';
import TasksPage from './pages/app/tasks';
import TemplatesPage from './pages/app/templates';
import LoginPage from './pages/login';
import Logout from './pages/logout';
import NotFound from './pages/not-found';
import SignupPage from './pages/signup';

const routeObject = createRoutesFromElements(
  <Route>
    <Route path="/" element={<PublicLayout />} errorElement={<ErrorBoundary />}>
      <Route index element={<HomePage />} />
      <Route path="/login/*" element={<LoginPage />} />
      <Route path="/signup/*" element={<SignupPage />} />
      <Route path="*" element={<NotFound />} />
    </Route>

    {/* Standalone form route without layout */}
    <Route path="/form" element={<PublicForm />} errorElement={<ErrorBoundary />} />

    <Route path="/app" element={<ProtectedRoute component={AppLayout} />} errorElement={<ErrorBoundary />}>
      <Route index element={<Navigate to="/app/dashboard" replace />} />
      <Route path="dashboard" element={<Dashboard />} />
      <Route path="deals" element={<DealsPage />} />
      <Route path="deals/:dealId" element={<DealDetailsPage />} />
      <Route path="domains" element={<DomainsPage />} />
      <Route path="domains/:name" element={<DomainDetailsPage />} />
      <Route path="databases" element={<DatabasesPage />} />
      <Route path="partners" element={<PartnersPage />} />
      <Route path="tasks" element={<TasksPage />} />
      <Route path="templates" element={<TemplatesPage />} />
      <Route path="contacts" element={<ContactsPage />} />
      <Route path="messages" element={<MessagesPage />} />
      <Route path="scheduler" element={<SchedulerPage />} />
      <Route path="scheduler/schedule" element={<SchedulePage />} />
      <Route path="settings" element={<SettingsPage />} />
      <Route path="*" element={<NotFound />} />
    </Route>

    <Route path="/logout" element={<Logout />} errorElement={<ErrorBoundary />} />
  </Route>,
);

const router = createBrowserRouter(routeObject);

export default router;
